import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { fonts } from 'folio-common-components';
import { colors } from 'folio-design-tokens';

type LabelColor = 'black' | 'white' | 'red';

interface ColoredLabelProps {
  color: LabelColor;
}

const colorMap: Record<LabelColor, { background: string; text: string }> = {
  black: { background: colors.black, text: colors.white },
  white: { background: colors.white, text: colors.blue },
  red: { background: colors.red, text: colors.white },
};

export const NumberBadge = styled('span', {
  shouldForwardProp: prop => isPropValid(prop) && prop !== 'color',
})<ColoredLabelProps>`
  ${fonts.font200medium};
  padding: 0 4px;
  background-color: ${props => colorMap[props.color].background};
  color: ${props => colorMap[props.color].text};
  border-radius: 4px;
  min-width: 22px;
  text-align: center;
  display: inline-block;
`;
