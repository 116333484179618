import { css } from '@emotion/react';

export const touchFeedback = css`
  -webkit-tap-highlight-color: transparent;

  @media (pointer: coarse) {
    // Commented out since this triggers a bug in Safari where text and SVGs are
    // shifted slightly
    // transition: opacity 0.1s;

    :active {
      opacity: 0.5;
    }
  }
`;
