import { mod11, mod11Check, weightedSum } from './mod11';
import { removeWhitespace } from './utils';

export const orgNumberRegexp = /\d{9}|SUS\d{6}/;

const pathOrgNumRegExp =
  /(org|bli-kunde|stiftelse|regnskap|oppdater-info)\/(\d{9}|SUS\d{6})(\/|$)/;

export function parseOrgNum(path: string) {
  const { pathname } = new URL(path, 'http://example.com');

  const match = pathname.match(pathOrgNumRegExp);
  return match ? match[2] : undefined;
}

export function replaceOrgInPath(path: string, replacement: string) {
  return path.replace(pathOrgNumRegExp, `$1/${replacement}$3`);
}

const weights = [3, 2, 7, 6, 5, 4, 3, 2];
const orgNumRe = /^\d{9}$/;

export function isValidOrgNumber(value: string): boolean {
  const normalized = removeWhitespace(value);
  if (!orgNumRe.test(normalized)) {
    return false;
  }

  const numbers = Array.from(normalized).map(Number);
  return (
    mod11(normalized) &&
    mod11Check(weightedSum(numbers.slice(0, 8), weights), numbers[8])
  );
}
