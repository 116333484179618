import * as React from 'react';

export const useInteractionIntent = (callback: () => void) => {
  const timeoutId = React.useRef<number>();

  const set = React.useCallback(() => {
    timeoutId.current = window.setTimeout(() => callback(), 100);
  }, [callback]);

  const clear = React.useCallback(() => {
    clearTimeout(timeoutId.current);
  }, []);

  return {
    onPointerEnter: set,
    onPointerLeave: clear,
    onFocus: set,
    onBlur: clear,
  };
};
