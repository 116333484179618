import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { groupItemsByDate, useExpandedEvents } from 'bank-common-client';
import { VisuallyHidden, space } from 'folio-common-components';
import * as React from 'react';
import { SkeletonLine } from '../../components/SkeletonLine';
import { cardStyle } from '../../components/card-style';
import { stickyHeader } from '../../styles/sticky-header';
import { SkeletonEventSummaryRow } from './EventSummaryRow';
import { SalaryPaymentTransactionRow, TransactionRow } from './TransactionRow';
import type {
  EventItemOrSalaryPaymentItem,
  EventItemOrSalaryPaymentList,
  SalaryPaymentItem,
} from './types';

export const Item = styled.div`
  ${cardStyle};
  overflow: visible;
`;

export const StickyHeader = styled.h3`
  ${stickyHeader};
`;

const GroupedTransactions = styled.div`
  padding-bottom: 10px;
`;

function isSalaryPayment(
  event: EventItemOrSalaryPaymentItem,
): event is SalaryPaymentItem {
  return event.__typename === 'SalaryPayment';
}

export const TransactionList: React.FC<{
  events: EventItemOrSalaryPaymentList;
}> = ({ events }) => {
  const eventsByDate = React.useMemo(() => {
    return groupItemsByDate(events, 'dateTime');
  }, [events]);

  const statusText = `${events.length} bevegelser`;
  const expandedEvents = useExpandedEvents();

  return (
    <>
      <VisuallyHidden aria-live="polite" aria-atomic="true">
        {statusText}
      </VisuallyHidden>
      {Array.from(eventsByDate.entries()).map(([date, events]) => (
        <GroupedTransactions key={date}>
          <StickyHeader>{date}</StickyHeader>
          {events.map(event => (
            <Item key={event.fid} data-testid="Item">
              {isSalaryPayment(event) ? (
                <SalaryPaymentTransactionRow
                  bulkEvent={event}
                  expanded={expandedEvents.has(event.fid)}
                />
              ) : (
                <TransactionRow
                  event={event}
                  expanded={expandedEvents.has(event.fid)}
                />
              )}
            </Item>
          ))}
        </GroupedTransactions>
      ))}
    </>
  );
};

export const LoadingTransactionList: React.FC<{
  count: number;
}> = props => (
  <GroupedTransactions data-testid="loading-transaction-list">
    <StickyHeader>
      <SkeletonLine width="150px" maxWidth="100%" />
    </StickyHeader>
    {Array.from({ length: Math.min(20, props.count) }).map((_, index) => (
      <Item key={index}>
        <div
          css={css`
            ${space([16], 'margin-bottom')};
          `}
        >
          <SkeletonEventSummaryRow index={index} />
        </div>
      </Item>
    ))}
  </GroupedTransactions>
);
