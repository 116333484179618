import { invariantValue } from 'folio-common-utils';
import type {
  EventItemOrSalaryPaymentItem,
  EventItemOrSalaryPaymentList,
} from './types';

export interface Filters {
  text: string;
  category: CategoryFilterConfiguration;
  person: PersonFilterConfiguration;
  status: StatusFilterConfiguration;
}

export type CategoryFilterConfiguration = 'all' | any;
export type StatusFilterConfiguration = 'all' | 'done' | 'incomplete';
export type PersonFilterConfiguration = 'all' | string;

// TODO this can't be typed properly if we don't have all categories enumerated
type EventsByCategory = Record<string, EventItemOrSalaryPaymentList>;

type EventsByStatus = Record<
  StatusFilterConfiguration,
  EventItemOrSalaryPaymentList
>;

export type EventsByFilterConfiguration = {
  category: EventsByCategory;
  status: EventsByStatus;
};

export function groupByCategory(
  events: EventItemOrSalaryPaymentList,
  categories: string[],
): EventsByCategory {
  const initial: Record<string, EventItemOrSalaryPaymentItem[]> = {
    all: Array.from(events),
  };

  // set up empty arrays for all categories that are in use
  categories.forEach(category => {
    initial[category] = [];
  });

  return events.reduce((eventsByCategory, event) => {
    const { category } = event.ledgerCategoryInfo;
    const { title } = category || { title: 'Ukjent' };

    invariantValue(eventsByCategory[title]).push(event);

    return eventsByCategory;
  }, initial);
}

export function groupByStatus(
  events: EventItemOrSalaryPaymentList,
): EventsByStatus {
  const eventsByStatus = {
    all: events,
    incomplete: [] as EventItemOrSalaryPaymentItem[],
    done: [] as EventItemOrSalaryPaymentItem[],
  };

  for (const event of events) {
    // bulkpayments are always done
    if (event.__typename === 'SalaryPayment') {
      eventsByStatus.done.push(event);
      // payments from accountingsystems are always done
    } else if (event.payment?.accountingSystemInformation != null) {
      eventsByStatus.done.push(event);
    } else {
      const status =
        event.requirements.allRequirementsMet ||
        event.requirements.requirementsOverridden.overridden
          ? 'done'
          : 'incomplete';
      eventsByStatus[status].push(event);
    }
  }

  return eventsByStatus;
}

export function amountMatchesSearchTerm(amount: number, searchTerm: string) {
  const normalizedForAmountComparison = searchTerm
    .replace(/\s/g, '')
    .replace(',', '.')
    .replace('−', '-'); // Minus to hyphen-minus

  if (normalizedForAmountComparison.startsWith('-')) {
    return (
      amount <= 0 && String(amount).startsWith(normalizedForAmountComparison)
    );
  } else if (normalizedForAmountComparison.startsWith('+')) {
    return (
      amount >= 0 &&
      String(amount).startsWith(normalizedForAmountComparison.slice(1))
    );
  } else if (
    String(Math.abs(amount)).startsWith(normalizedForAmountComparison)
  ) {
    return true;
  }

  return false;
}
