import * as React from 'react';
import type { PreloadableComponent } from 'react-lazy-with-preload';

export const PreloaderComponent: React.FC<{
  component: PreloadableComponent<React.FC>;
  props: any;
  preloads: PreloadableComponent<React.FC>[];
}> = ({ component: Component, props, preloads }) => {
  React.useEffect(() => {
    preloads.forEach(pageLoader => pageLoader.preload());
  }, [preloads]);

  return <Component {...props} />;
};
