import type { EventItemFragment } from '../common-client-queries.generated';
import type { CompleteLabelStatus } from '../components/shared/bookkeeping';
import type { EventRequirement } from '../gqltypes';

type EventRequirementInfo = {
  needsPurpose: boolean;
  needsParticipants: boolean;
  needsReceipt: boolean;
  unmetRequirements: readonly EventRequirement[];
  status: CompleteLabelStatus;
};

export function getRequirementInfo(
  event: EventItemFragment,
): EventRequirementInfo {
  const {
    requirements: {
      allRequirementsMet,
      unmetRequirements,
      requirementsOverridden: { overridden },
    },
  } = event;

  const needsReceipt = unmetRequirements.includes('Receipt');
  const needsPurpose = unmetRequirements.includes('Purpose');
  const needsParticipants = unmetRequirements.includes('Participants');

  const status: CompleteLabelStatus = overridden
    ? 'overridden'
    : allRequirementsMet
    ? 'complete'
    : 'incomplete';

  return {
    needsParticipants,
    needsPurpose,
    needsReceipt,
    unmetRequirements,
    status,
  };
}
