import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from 'folio-design-tokens';
import * as React from 'react';

export interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'children'> {
  name: string;
  error?: boolean;
  children: (
    files: readonly File[],
    overDropZone: boolean,
  ) => React.ReactElement | string;
  styledContainer?: React.ComponentType<any>;
}

export interface State {
  files: File[];
  overDropZone: boolean;
}

export type ContainerProps = {
  overDropZone: boolean;
  fileChosen: boolean;
  disabled: boolean;
  error?: boolean;
};

const dynamicStyles = ({
  overDropZone,
  fileChosen,
  disabled,
  error,
}: ContainerProps) => {
  const styleColors = {
    borderColor: colors.yellow,
    color: colors.black,
    backgroundColor: colors.yellowLight,
  };

  if (error) {
    styleColors.borderColor = colors.wcagRed;
    styleColors.color = colors.wcagRed;
    styleColors.backgroundColor = colors.redLight;
  } else if (disabled) {
    styleColors.borderColor = colors.grayMedium;
    styleColors.color = colors.wcagGray;
    styleColors.backgroundColor = colors.background;
  } else if (fileChosen) {
    styleColors.color = colors.black;
    styleColors.backgroundColor = colors.yellowLight;
  } else if (overDropZone) {
    styleColors.backgroundColor = colors.yellowLight;
  }

  return css(styleColors);
};

const containerBaseStyle = css`
  position: relative;
  cursor: pointer;

  &:focus-within {
    border-color: ${colors.blue};
  }
`;

const DefaultStyledContainer = styled.div`
  border: 2px dashed ${colors.yellow};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  ${dynamicStyles};
`;

const Input = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;

  &:enabled {
    cursor: pointer;
  }

  &:enabled ::-webkit-file-upload-button {
    cursor: pointer;
  }
`;

const BaseFileUploadArea: React.ForwardRefRenderFunction<
  { openFilePicker: () => void },
  Props
> = (props, ref) => {
  const [files, setFiles] = React.useState<readonly File[]>([]);
  const [overDropZone, setOverDropZone] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useImperativeHandle(
    ref,
    () => ({
      openFilePicker: () => inputRef.current?.click(),
    }),
    [],
  );

  const {
    children,
    disabled = false,
    onChange,
    styledContainer,
    className,
    error,
    title,
    ...rest
  } = props;
  const StyledContainer = styledContainer || DefaultStyledContainer;

  return (
    <StyledContainer
      overDropZone={!disabled && overDropZone}
      fileChosen={files.length !== 0}
      disabled={disabled}
      error={error}
      css={containerBaseStyle}
      className={className}
    >
      {children(files, overDropZone)}
      <Input
        {...rest}
        ref={inputRef}
        disabled={disabled}
        title={title}
        aria-label={title}
        type="file"
        onDragEnter={() => setOverDropZone(true)}
        onDragLeave={() => setOverDropZone(false)}
        onDrop={() => setOverDropZone(false)}
        onChange={event => {
          const files = Array.from(event.target.files || []);
          setFiles(files);
          if (onChange) {
            onChange(event);
          }
        }}
      />
    </StyledContainer>
  );
};

export const FileUploadArea = React.forwardRef(BaseFileUploadArea);

export function isValidContentType(accept: string, contentType: string) {
  const [type, subType] = contentType.split('/');
  const validContentTypes = accept
    .split(',')
    .map(contentType => contentType.trim());
  return validContentTypes.some(candidate => {
    const [candidateType, candidateSubType] = candidate.split('/');
    if (candidateSubType === '*') {
      return type === candidateType;
    }
    return type === candidateType && subType === candidateSubType;
  });
}
