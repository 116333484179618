import { css } from '@emotion/react';
import {
  extraLarge as extraLargeMq,
  large as largeMq,
  medium as mediumMq,
} from './media-queries';
import type { SpacingLength } from './spacing-length';

type BreakpointDeclaration =
  | [SpacingLength]
  | [SpacingLength, SpacingLength]
  | [SpacingLength, SpacingLength, SpacingLength]
  | [SpacingLength, SpacingLength, SpacingLength, SpacingLength];

type AllowedProperty =
  | 'margin'
  | 'margin-top'
  | 'margin-right'
  | 'margin-bottom'
  | 'margin-left'
  | 'margin-horizontal'
  | 'margin-vertical'
  | 'padding'
  | 'padding-top'
  | 'padding-right'
  | 'padding-bottom'
  | 'padding-left'
  | 'padding-horizontal'
  | 'padding-vertical'
  | 'gap'
  | 'row-gap'
  | 'column-gap';

export function space(
  [small, medium, large, extraLarge]: BreakpointDeclaration,
  ...props: readonly AllowedProperty[]
): ReturnType<typeof css> {
  return css`
    ${createDeclarations(props, small)};

    ${medium !== undefined
      ? css`
          @media ${mediumMq} {
            ${createDeclarations(props, medium)};
          }
        `
      : null}

    ${large !== undefined
      ? css`
          @media ${largeMq} {
            ${createDeclarations(props, large)};
          }
        `
      : null}

    ${extraLarge !== undefined
      ? css`
          @media ${extraLargeMq} {
            ${createDeclarations(props, extraLarge)};
          }
        `
      : null}
  `;
}

function createDeclarations(
  props: readonly AllowedProperty[],
  length: SpacingLength,
) {
  return props.map(prop => createDeclaration(prop, length));
}

function createDeclaration(prop: AllowedProperty, length: SpacingLength) {
  if (prop === 'margin-horizontal') {
    return css`
      margin-left: ${length}px;
      margin-right: ${length}px;
    `;
  } else if (prop === 'margin-vertical') {
    return css`
      margin-top: ${length}px;
      margin-bottom: ${length}px;
    `;
  } else if (prop === 'padding-horizontal') {
    return css`
      padding-left: ${length}px;
      padding-right: ${length}px;
    `;
  } else if (prop === 'padding-vertical') {
    return css`
      padding-top: ${length}px;
      padding-bottom: ${length}px;
    `;
  } else if (prop === 'gap') {
    return css`
      grid-gap: ${length}px;
      gap: ${length}px;
    `;
  } else if (prop === 'row-gap') {
    return css`
      grid-row-gap: ${length}px;
      row-gap: ${length}px;
    `;
  } else if (prop === 'column-gap') {
    return css`
      grid-column-gap: ${length}px;
      column-gap: ${length}px;
    `;
  }
  return css`
    ${prop}: ${length}px;
  `;
}
