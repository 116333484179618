import type { MovementType } from '../gqltypes';

const internalTransferTypes = new Set<MovementType>([
  'Transfer',
  'TransferToSavings',
  'TransferFromSavings',
  'TransferToCard',
  'TransferToCardAutofill',
  'TransferFromCard',
  'TransferToTaxAccount',
]);

export function isInternalTransfer(type: MovementType): boolean {
  return internalTransferTypes.has(type);
}
