import * as React from 'react';
import { createContainer } from 'unstated-next';
import { useQueryArg } from './hooks/use-query-arg';
import type { Filters } from './routes/transactions/filter-utils';

export const noPersonFilterId = 'all';

export function useSelectedPersonFromUrl(): Filters['person'] {
  return useQueryArg('person') || noPersonFilterId;
}

const defaultFilters: Filters = {
  text: '',
  category: 'all',
  person: noPersonFilterId,
  status: 'all',
};

function usesDefaultFilters(filters: Filters) {
  return (Object.keys(filters) as Array<keyof Filters>).every(
    key => filters[key] === defaultFilters[key],
  );
}

function usesDefaultFiltersIgnoringStatus(filters: Filters) {
  return usesDefaultFilters({ ...filters, status: defaultFilters.status });
}

function useFilters() {
  const [activeFilters, setActiveFilters] = React.useState<Filters>({
    ...defaultFilters,
    person: useSelectedPersonFromUrl(),
  });

  const setEventFilters = React.useCallback((filters: Partial<Filters>) => {
    setActiveFilters(currentFilters => ({ ...currentFilters, ...filters }));
  }, []);

  return React.useMemo(
    () => ({
      eventFilters: activeFilters,
      setEventFilters,
      usesDefaultFilters: usesDefaultFilters(activeFilters),
      usesDefaultFiltersIgnoringStatus:
        usesDefaultFiltersIgnoringStatus(activeFilters),
    }),
    [activeFilters, setEventFilters],
  );
}

const { Provider, useContainer } = createContainer(useFilters);
export { Provider as EventFiltersProvider, useContainer as useEventFilters };
