import { getHostname } from './get-hostname';

export function isProd() {
  const hostname = getHostname();

  return (
    !hostname.startsWith('dev.') &&
    !hostname.endsWith('.test') &&
    hostname !== 'localhost'
  );
}
