export function getLocalStorageItemSafely(key: string) {
  try {
    return window.localStorage.getItem(key);
  } catch {
    return null;
  }
}

export function setLocalStorageItemSafely(key: string, value: string) {
  try {
    window.localStorage.setItem(key, value);
  } catch {
    // ignore
  }
}

export function removeLocalStorageItemSafely(key: string) {
  try {
    window.localStorage.removeItem(key);
  } catch {
    // ignore
  }
}
