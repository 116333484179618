import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PlainButton } from 'folio-common-components';
import { colors } from 'folio-design-tokens';

export const noUnderlineOnHoverStyle = css`
  text-decoration: underline;

  @media (hover) {
    &:hover {
      text-decoration: none;
    }
  }
`;
export const underlineOnHoverStyle = css`
  text-decoration: none;

  @media (hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const LinkButton = styled(PlainButton)`
  color: ${colors.blue};
  ${noUnderlineOnHoverStyle};
`;
