import { mod11Check, weightedSum } from './mod11';
import { normalizeAccountNumber } from './normalizers';
import { removeWhitespace } from './utils';

const weights = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
const accountNumberRe = /^\d{11}$/;

export function isValidAccountNumber(accountNumber: string): boolean {
  const normalized = normalizeAccountNumber(accountNumber);
  if (!accountNumberRe.test(normalized)) {
    return false;
  }

  const numbers = Array.from(normalized).map(Number);
  return mod11Check(weightedSum(numbers.slice(0, 10), weights), numbers[10]);
}
/**
 * Check if the string looks like an account number (11 digits with spaces and
 * dots). Does not perform any validation.
 * Allows weird formatting, e.g. '123.456 78900'
 */
export function isAccountNumberLike(str: string) {
  return /^\d{11}$/.test(normalizeAccountNumber(str));
}
/**
 * Check if the string looks like an IBAN. The shortest one is Norway with
 * country code + check digits + 11 digits
 */
export function isIbanLike(str: string) {
  return /^[a-z]{2}\d{2}[a-z\d]{11,30}$/i.test(removeWhitespace(str));
}
