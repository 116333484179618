import * as React from 'react';

export function useThemeColor(color: string) {
  React.useLayoutEffect(() => {
    const themeColorEle = document.querySelector<HTMLMetaElement>(
      'meta[name=theme-color]',
    );
    if (themeColorEle) {
      themeColorEle.content = color;
    }
  }, [color]);
}
