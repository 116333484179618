import { css } from '@emotion/react';
import { pages } from 'bank-common';
import { fonts } from 'folio-common-components';
import { invariant } from 'folio-common-utils';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { useEventFilters } from '../../event-filter-context';
import { useInteractionIntent } from '../../hooks/use-interaction-intent';
import { useLocationMatch } from '../../hooks/use-location-match';
import type { MenuItem } from '../../hooks/use-menu-items';
import { OrgLink } from '../org-navigation';

interface TopLevelMenuSmallProps {
  menuItems: readonly MenuItem[];
  showProfileAndSettings: boolean;
}

const linkActiveStyle = css`
  background-color: ${colors.blueLight};
  color: ${colors.blue};

  @media (hover) {
    :hover {
      border-color: transparent;
    }
  }

  @media (prefers-contrast: more) {
    border-color: ${colors.blue};

    @media (hover) {
      :hover {
        border-color: ${colors.blue};
      }
    }
  }
`;

const largeLinkStyle = css`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  ${fonts.font200medium};
  text-decoration: none;
  color: ${colors.black};
  border: 2px solid transparent;
  position: relative;
  margin-bottom: 8px;
  -webkit-tap-highlight-color: transparent;

  /* This makes sure there are no uncklickable areas between menu items, which looks
  better when moving the cursor between items since it avoids flashing the cursor. */
  ::before {
    content: '';
    position: absolute;
    /* 6 is: 8 (space between menu items) / 2 + 2 (border) */
    top: -6px;
    bottom: -6px;
    left: 0;
    right: 0;
  }

  @media (hover) {
    :hover {
      border-color: ${colors.blueLight};

      @media (prefers-contrast: more) {
        border-color: ${colors.blue};
      }
    }
  }

  &:active {
    ${linkActiveStyle};
  }
`;

const StyledLargeLink: React.FC<{
  menuItem: MenuItem;
}> = ({ menuItem }) => {
  const [isPressed, setIsPressed] = React.useState(false);
  const { icon, labelComponent: MenuLabelComponent, page } = menuItem;
  const { eventFilters } = useEventFilters();
  const path =
    page.mountPoint === pages.transactions
      ? page.getUrl({ agentFid: eventFilters.person })
      : page.getUrl();
  const eventListeners = useInteractionIntent(() =>
    page.loadableComponent.preload(),
  );
  let { isPartialMatch } = useLocationMatch(path);
  const isTransactionsMatch = useLocationMatch('bevegelser').isPartialMatch;

  if (!isPartialMatch && path === '') {
    isPartialMatch = isTransactionsMatch;
  }

  invariant(MenuLabelComponent);

  return (
    <OrgLink
      to={path}
      state={{ navigatedViaMenu: true }}
      css={[largeLinkStyle, isPartialMatch && linkActiveStyle]}
      preloadQueries={page.preloadQueries}
      onPointerDown={event => {
        if (event.button === 0) {
          setIsPressed(true);
        }
      }}
      onPointerUp={() => setIsPressed(false)}
      {...eventListeners}
    >
      {icon}{' '}
      <span
        css={css`
          margin-left: 8px;
        `}
      >
        <MenuLabelComponent isActive={isPressed || isPartialMatch} />
      </span>
    </OrgLink>
  );
};

export const TopLevelMenuLarge: React.FC<TopLevelMenuSmallProps> = props => (
  <nav aria-label="Hovednavigasjon">
    {props.menuItems.map(menuItem => (
      <StyledLargeLink key={menuItem.page.getUrl()} menuItem={menuItem} />
    ))}
  </nav>
);
