import * as React from 'react';
import { useLocation } from 'react-router-dom';

export function useSearchParams(): URLSearchParams {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function useQueryArg(name: string): string | undefined {
  const param = useSearchParams().get(name);
  // param may be the empty string. We don't treat that as falsy here.
  return param === null ? undefined : param;
}

export function useHasQueryArg(name: string): boolean {
  return useSearchParams().has(name);
}
