import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import { ProgressSpinner, TextButton } from 'folio-common-components';
import * as React from 'react';
import type { SigningMethod } from '../../gqltypes';
import { setBankIdMethod } from '../../utils/bankid-method';

export const BankIdSign: React.FC<{
  url: string | undefined; // undefined means loading
  signingMethod: SigningMethod;
  onSwitchSigningMethod: (method: SigningMethod) => void;
  onSuccess: (code: string, state: string) => void;
  onError: (error: string) => void;
  isUsingWorkaround?: boolean;
}> = props => {
  const {
    url,
    signingMethod,
    onSwitchSigningMethod,
    onSuccess,
    onError,
    isUsingWorkaround = false,
  } = props;
  const handleBankIdCallback = React.useCallback(
    (url: string) => {
      const status = getParamsFromUrl(url);

      if (status.error !== null) {
        onError(`BankID: An error was returned: ${status.error}`);
      } else if (status.code === null || status.state === null) {
        onError('BankID: Either "code" or "state" was missing.');
      } else {
        onSuccess(status.code, status.state);
      }
    },
    [onSuccess, onError],
  );

  React.useLayoutEffect(() => {
    if (isUsingWorkaround && url) {
      window.location.assign(url);
    }
  }, [isUsingWorkaround, url]);

  React.useEffect(() => {
    if (url) {
      const callback = (event: MessageEvent) => {
        // TODO: check origin here
        if (!url) {
          return;
        }
        const signingUrlParams = getParamsFromUrl(url);
        const callbackUrlParams = getParamsFromUrl(event.data);
        Sentry.addBreadcrumb({
          message: 'Received callback from bankid',
          data: { url: event.data },
        });
        if (
          signingUrlParams.state !== null &&
          callbackUrlParams.state !== null &&
          signingUrlParams.state === callbackUrlParams.state
        ) {
          Sentry.addBreadcrumb({
            message: 'Received callback from bankid with correct state',
            data: { state: callbackUrlParams.state },
          });
          handleBankIdCallback(event.data);
          window.removeEventListener('message', callback);
        } else if (callbackUrlParams.error) {
          handleBankIdCallback(event.data);
        }
      };
      window.addEventListener('message', callback);
      return () => window.removeEventListener('message', callback);
    }
    return;
  }, [url, handleBankIdCallback]);

  return (
    <>
      <div
        css={css`
          height: 320px;
          width: 100%;
          position: relative;
        `}
      >
        {!url || isUsingWorkaround ? (
          <div
            css={css`
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <ProgressSpinner size={48} />
          </div>
        ) : (
          <BankIdIframe src={url} title="Godkjenn betaling med BankID" />
        )}
      </div>
      <p
        css={css`
          text-align: center;
          margin-bottom: 0;
        `}
      >
        <TextButton
          onClick={() => {
            if (url == null) {
              return;
            }

            const method =
              signingMethod === 'BankId' ? 'BankIdMobil' : 'BankId';
            onSwitchSigningMethod(method);
            setBankIdMethod(method);
          }}
        >
          {signingMethod === 'BankId'
            ? 'Bruk BankID på mobil'
            : 'Bruk BankID med kode eller app'}
        </TextButton>
      </p>
    </>
  );
};

function getParamsFromUrl(rawUrl: string) {
  try {
    const url = new URL(rawUrl);
    return {
      code: url.searchParams.get('code'),
      state: url.searchParams.get('state'),
      error: url.searchParams.get('error'),
    };
  } catch {
    return { code: null, state: null, error: null };
  }
}

const BankIdIframe = styled.iframe`
  border: 0;
  width: 100%;
  height: 100%;
`;
