import type { DocumentNode } from '@apollo/client';
import { type PageId, pages as commonPages } from 'bank-common';
import {
  LazyRouteFallback,
  LazyRouteFallbackWithTopMargin,
} from 'folio-common-components';
import * as React from 'react';
import lazyWithPreload, {
  type PreloadableComponent,
} from 'react-lazy-with-preload';
import { PreloaderComponent } from './components/preloader-component';
import { noPersonFilterId } from './event-filter-context';
import {
  AllEmployeesDocument,
  ExportPageDataDocument,
} from './routes/accounting/queries.generated';
import { GetEmployeesWithCardsDocument } from './routes/employees/queries.generated';

export interface PageDefinition {
  mountPoint: string;
  loadableComponent: PreloadableComponent<React.FC>;
  preloadQueries: DocumentNode[];
  suspenseFallback: React.ReactElement | null;
  component: React.ComponentType;
  getUrl(...args: unknown[]): string;
}

function createPage({
  mountPoint,
  loadableComponent,
  suspenseFallback,
  preloads = [],
  preloadQueries = [],
  getUrl,
}: {
  mountPoint: string;
  loadableComponent: PreloadableComponent<React.FC>;
  suspenseFallback: React.ReactElement;
  preloads?: PageId[];
  preloadQueries?: DocumentNode[];
  getUrl?: PageDefinition['getUrl'];
}): PageDefinition {
  return {
    mountPoint,
    loadableComponent,
    preloadQueries,
    suspenseFallback,
    getUrl(args) {
      return getUrl ? getUrl(args) : mountPoint;
    },
    component(props) {
      return (
        <PreloaderComponent
          component={loadableComponent}
          props={props}
          preloads={preloads.map(pageId => pages[pageId].loadableComponent)}
        />
      );
    },
  };
}

const suspenseFallback = <LazyRouteFallback />;
const suspenseFallbackWithMargin = <LazyRouteFallbackWithTopMargin />;

export const pages: Record<PageId, PageDefinition> = {
  bankId: createPage({
    mountPoint: commonPages.bankId,
    loadableComponent: lazyWithPreload(() => import('./routes/login/direct')),
    suspenseFallback: suspenseFallbackWithMargin,
    preloads: ['pickOrg', 'transactions'],
  }),
  accountantLogin: createPage({
    mountPoint: commonPages.accountantLogin,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accountant-login'),
    ),
    suspenseFallback,
  }),
  pickOrg: createPage({
    mountPoint: commonPages.pickOrg,
    loadableComponent: lazyWithPreload(() => import('./routes/select-org')),
    suspenseFallback: suspenseFallbackWithMargin,
    preloads: ['transactions'],
    getUrl: () => 'velg-organisasjon',
  }),
  employees: createPage({
    mountPoint: commonPages.employees,
    loadableComponent: lazyWithPreload(() => import('./routes/employees')),
    suspenseFallback,
    preloads: ['addEmployee', 'newEmployee', 'singleEmployee'],
    preloadQueries: [GetEmployeesWithCardsDocument],
  }),
  addEmployee: createPage({
    mountPoint: commonPages.addEmployee,
    loadableComponent: lazyWithPreload(() => import('./routes/add-user')),
    suspenseFallback,
    preloads: ['employees', 'newEmployee', 'addAccountant'],
  }),
  confirmInvitation: createPage({
    mountPoint: commonPages.confirmInvitation,
    loadableComponent: lazyWithPreload(
      () => import('./routes/confirm-invitation'),
    ),
    suspenseFallback,
    preloads: ['employees'],
    getUrl: fid => `brukere/${fid}/godkjenn`,
  }),
  invitationDone: createPage({
    mountPoint: commonPages.invitationDone,
    loadableComponent: lazyWithPreload(
      () => import('./routes/confirm-invitation/done'),
    ),
    suspenseFallback,
    preloads: ['employees'],
    getUrl: fid => `brukere/${fid}/ferdig`,
  }),
  invitationRejected: createPage({
    mountPoint: commonPages.invitationRejected,
    loadableComponent: lazyWithPreload(
      () => import('./routes/confirm-invitation/rejected'),
    ),
    suspenseFallback,
    preloads: ['employees'],
  }),
  newEmployee: createPage({
    mountPoint: commonPages.newEmployee,
    loadableComponent: lazyWithPreload(() => import('./routes/newEmployee')),
    suspenseFallback,
    preloads: ['employees', 'employeeCreated'],
  }),
  employeeCreated: createPage({
    mountPoint: commonPages.employeeCreated,
    loadableComponent: lazyWithPreload(
      () => import('./routes/employeeCreated'),
    ),
    suspenseFallback,
    preloads: ['employees'],
  }),
  addCoFounder: createPage({
    mountPoint: commonPages.addCoFounder,
    loadableComponent: lazyWithPreload(() => import('./routes/add-co-founder')),
    suspenseFallback,
    preloads: ['employees', 'employeeCreated'],
    getUrl: fid => `brukere/ny/${fid}`,
  }),
  addAccountant: createPage({
    mountPoint: commonPages.addAccountant,
    loadableComponent: lazyWithPreload(() => import('./routes/add-accountant')),
    suspenseFallback,
  }),
  singleEmployee: createPage({
    mountPoint: commonPages.singleEmployee,
    loadableComponent: lazyWithPreload(
      () => import('./routes/single-employee'),
    ),
    suspenseFallback,
    preloads: ['employees'],
    getUrl: fid => `brukere/${fid}`,
  }),
  singleEmployeeModified: createPage({
    mountPoint: commonPages.singleEmployeeModified,
    loadableComponent: lazyWithPreload(
      () => import('./routes/modified-single-employee'),
    ),
    suspenseFallback,
    preloads: ['employees'],
    getUrl: fid => `brukere/${fid}/endret`,
  }),
  accounting: createPage({
    mountPoint: commonPages.accounting,
    loadableComponent: lazyWithPreload(() => import('./routes/accounting')),
    suspenseFallback,
    preloads: ['accountingArchive', 'accountingSettings'],
    preloadQueries: [ExportPageDataDocument, AllEmployeesDocument],
  }),
  accountingArchive: createPage({
    mountPoint: commonPages.accountingArchive,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/archive'),
    ),
    suspenseFallback,
  }),
  accountingSettings: createPage({
    mountPoint: commonPages.accountingSettings,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/settings'),
    ),
    suspenseFallback,
    preloads: ['accountingSystemSelector', 'addAccountant'],
  }),
  accountingFinalizePeriod: createPage({
    mountPoint: commonPages.accountingFinalizePeriod,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/finalize'),
    ),
    suspenseFallback,
    getUrl: (date: string) => {
      return `regnskap/bli-ferdig?dato=${date}`;
    },
  }),
  tripletex: createPage({
    mountPoint: commonPages.tripletex,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/tripletex'),
    ),
    suspenseFallback,
    preloads: ['tripletexSetup', 'tripletexManual'],
  }),
  tripletexSetup: createPage({
    mountPoint: commonPages.tripletexSetup,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/tripletex/setup'),
    ),
    suspenseFallback,
  }),
  tripletexManual: createPage({
    mountPoint: commonPages.tripletexManual,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/tripletex/manual'),
    ),
    suspenseFallback,
  }),
  fiken: createPage({
    mountPoint: commonPages.fiken,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/fiken'),
    ),
    suspenseFallback,
    preloads: ['fikenSetup', 'fikenManual'],
  }),
  fikenSetup: createPage({
    mountPoint: commonPages.fikenSetup,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/fiken/setup'),
    ),
    suspenseFallback,
  }),
  fikenManual: createPage({
    mountPoint: commonPages.fikenManual,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/fiken/manual'),
    ),
    suspenseFallback,
  }),
  fikenDirect: createPage({
    mountPoint: commonPages.fikenDirect,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/fiken-direct'),
    ),
    suspenseFallback,
    preloads: ['fikenSetup', 'fikenManual'],
  }),
  fikenDirectDisconnect: createPage({
    mountPoint: commonPages.fikenDirectDisconnect,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/fiken-direct/disconnect'),
    ),
    suspenseFallback,
  }),
  powerOfficeGo: createPage({
    mountPoint: commonPages.powerOfficeGo,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/poweroffice-go'),
    ),
    suspenseFallback,
    preloads: ['powerOfficeGoSetup', 'powerOfficeGoManual'],
  }),
  powerOfficeGoSetup: createPage({
    mountPoint: commonPages.powerOfficeGoSetup,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/poweroffice-go/setup'),
    ),
    suspenseFallback,
  }),
  powerOfficeGoManual: createPage({
    mountPoint: commonPages.powerOfficeGoManual,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/poweroffice-go/manual'),
    ),
    suspenseFallback,
  }),
  unimicro: createPage({
    mountPoint: commonPages.unimicro,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/unimicro'),
    ),
    suspenseFallback,
    preloads: ['unimicroSetup', 'unimicroManual'],
  }),
  unimicroSetup: createPage({
    mountPoint: commonPages.unimicroSetup,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/unimicro/setup'),
    ),
    suspenseFallback,
  }),
  unimicroManual: createPage({
    mountPoint: commonPages.unimicroManual,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/unimicro/manual'),
    ),
    suspenseFallback,
  }),
  vismaEAccounting: createPage({
    mountPoint: commonPages.vismaEAccounting,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/visma-eaccounting'),
    ),
    suspenseFallback,
    preloads: ['vismaEAccountingSetup', 'vismaEAccountingManual'],
  }),
  vismaEAccountingSetup: createPage({
    mountPoint: commonPages.vismaEAccountingSetup,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/visma-eaccounting/setup'),
    ),
    suspenseFallback,
  }),
  vismaEAccountingManual: createPage({
    mountPoint: commonPages.vismaEAccountingManual,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/visma-eaccounting/manual'),
    ),
    suspenseFallback,
  }),
  tfso: createPage({
    mountPoint: commonPages.tfso,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/tfso'),
    ),
    suspenseFallback,
    preloads: ['tfsoSetup', 'tfsoManual'],
  }),
  tfsoSetup: createPage({
    mountPoint: commonPages.tfsoSetup,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/tfso/setup'),
    ),
    suspenseFallback,
  }),
  tfsoManual: createPage({
    mountPoint: commonPages.tfsoManual,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/tfso/manual'),
    ),
    suspenseFallback,
  }),
  accountingSystemSelector: createPage({
    mountPoint: commonPages.accountingSystemSelector,
    loadableComponent: lazyWithPreload(
      () => import('./routes/accounting/accounting-system-selector'),
    ),
    suspenseFallback,
    preloads: [
      'tripletex',
      'fiken',
      'fikenDirect',
      'powerOfficeGo',
      'unimicro',
      'vismaEAccounting',
      'tfso',
    ],
  }),
  profile: createPage({
    mountPoint: commonPages.profile,
    loadableComponent: lazyWithPreload(() => import('./routes/profile')),
    suspenseFallback,
  }),
  notFound: createPage({
    mountPoint: commonPages.notFound,
    loadableComponent: lazyWithPreload(() => import('./routes/not-found')),
    suspenseFallback,
  }),
  payments: createPage({
    mountPoint: commonPages.payments,
    loadableComponent: lazyWithPreload(
      () => import('./routes/payment/new-payments-list'),
    ),
    suspenseFallback,
    preloads: [
      'approvedPaymentsList',
      'chooseCreditor',
      'paymentDetails',
      'salaryPaymentDetails',
    ],
  }),
  approvedPaymentsList: createPage({
    mountPoint: commonPages.approvedPaymentsList,
    loadableComponent: lazyWithPreload(
      () => import('./routes/payment/approved-payments-list'),
    ),
    suspenseFallback,
    preloads: [
      'payments',
      'chooseCreditor',
      'paymentDetails',
      'salaryPaymentDetails',
    ],
  }),
  chooseCreditor: createPage({
    mountPoint: commonPages.chooseCreditor,
    loadableComponent: lazyWithPreload(
      () => import('./routes/payment/choose-creditor'),
    ),
    suspenseFallback,
    preloads: ['newPayment', 'internationalPayments'],
  }),
  internationalPayments: createPage({
    mountPoint: commonPages.internationalPayments,
    loadableComponent: lazyWithPreload(
      () => import('./routes/payment/new-international-payment'),
    ),
    getUrl: fid =>
      fid
        ? `${commonPages.internationalPayments}?mottaker=${fid}`
        : commonPages.internationalPayments,
    suspenseFallback,
  }),
  salary: createPage({
    mountPoint: commonPages.salary,
    loadableComponent: lazyWithPreload(() => import('./routes/payment/salary')),
    suspenseFallback,
  }),
  newPayment: createPage({
    mountPoint: commonPages.newPayment,
    loadableComponent: lazyWithPreload(
      () => import('./routes/payment/new-payment'),
    ),
    suspenseFallback,
    getUrl: fid => (fid ? `betaling/ny?mottaker=${fid}` : 'betaling/ny'),
    preloads: ['payments'],
  }),
  paymentDetails: createPage({
    mountPoint: commonPages.paymentDetails,
    loadableComponent: lazyWithPreload(
      () => import('./routes/payment/payment-details'),
    ),
    suspenseFallback,
    getUrl: fid => `betaling/${fid}`,
    preloads: ['payments', 'editPayment'],
  }),
  salaryPaymentDetails: createPage({
    mountPoint: commonPages.salaryPaymentDetails,
    loadableComponent: lazyWithPreload(
      () => import('./routes/payment/payment-details/salary'),
    ),
    suspenseFallback,
    getUrl: fid => `betaling/lonn/${fid}`,
    // preloads: ['payments', 'editPayment'],
  }),
  editPayment: createPage({
    mountPoint: commonPages.editPayment,
    loadableComponent: lazyWithPreload(
      () => import('./routes/payment/edit-payment'),
    ),
    suspenseFallback,
    getUrl: fid => `betaling/${fid}/endre`,
    preloads: ['paymentDetails'],
  }),
  transferToTaxAccount: createPage({
    mountPoint: commonPages.transferToTaxAccount,
    loadableComponent: lazyWithPreload(
      () => import('./routes/payment/transfer-to-tax-account'),
    ),
    suspenseFallback,
  }),
  singleTransaction: createPage({
    mountPoint: commonPages.singleTransaction,
    loadableComponent: lazyWithPreload(
      () => import('./routes/singleTransaction'),
    ),
    suspenseFallback,
    getUrl: fid => `bevegelser/${fid}`,
  }),
  transactions: createPage({
    mountPoint: commonPages.transactions,
    loadableComponent: lazyWithPreload(() => import('./routes/transactions')),
    suspenseFallback,
    getUrl: ({
      agentFid,
      fromDate,
      toDate,
    }: { agentFid?: string; fromDate?: string; toDate?: string } = {}) => {
      const params = new URLSearchParams();

      if (agentFid && agentFid !== noPersonFilterId) {
        params.set('person', agentFid);
      }

      if (fromDate) {
        params.set('fraDato', fromDate);
      }

      if (toDate) {
        params.set('tilDato', toDate);
      }

      const paramsString = params.toString();

      return paramsString ? `?${paramsString}` : '';
    },
  }),
  orgSettings: createPage({
    mountPoint: commonPages.orgSettings,
    loadableComponent: lazyWithPreload(() => import('./routes/org-settings')),
    suspenseFallback,
    preloads: ['orgSubscriptionInfo'],
  }),
  orgSubscriptionInfo: createPage({
    mountPoint: commonPages.orgSubscriptionInfo,
    loadableComponent: lazyWithPreload(
      () => import('./routes/org-settings/subscription-info'),
    ),
    suspenseFallback,
    preloads: [
      'orgSettings',
      'orgSubscriptionPreliminaryInvoice',
      'orgSubscriptionInvoice',
    ],
  }),
  orgSubscriptionPreliminaryInvoice: createPage({
    mountPoint: commonPages.orgSubscriptionPreliminaryInvoice,
    loadableComponent: lazyWithPreload(
      () =>
        import(
          './routes/org-settings/subscription-info/invoice-info/preliminary-invoice'
        ),
    ),
    suspenseFallback,
    preloads: ['orgSubscriptionInfo'],
  }),
  orgSubscriptionInvoice: createPage({
    mountPoint: commonPages.orgSubscriptionInvoice,
    getUrl: (fid: string) =>
      commonPages.orgSubscriptionInvoice.replace(':fid', fid),
    loadableComponent: lazyWithPreload(
      () =>
        import('./routes/org-settings/subscription-info/invoice-info/invoice'),
    ),
    suspenseFallback,
    preloads: ['orgSubscriptionInfo'],
  }),
  customerService: createPage({
    mountPoint: commonPages.customerService,
    loadableComponent: lazyWithPreload(
      () => import('./routes/customer-service'),
    ),
    suspenseFallback,
  }),
  orderTaxAccount: createPage({
    mountPoint: commonPages.orderTaxAccount,
    loadableComponent: lazyWithPreload(
      () => import('./routes/order-tax-account'),
    ),
    suspenseFallback,
    preloads: ['taxAccountReady'],
  }),
  taxAccountReady: createPage({
    mountPoint: commonPages.taxAccountReady,
    loadableComponent: lazyWithPreload(
      () => import('./routes/tax-account-ready'),
    ),
    suspenseFallback,
  }),
  orderSavingsAccount: createPage({
    mountPoint: commonPages.orderSavingsAccount,
    loadableComponent: lazyWithPreload(
      () => import('./routes/order-savings-account'),
    ),
    suspenseFallback,
    preloads: ['savingsAccountReady'],
  }),
  savingsAccountReady: createPage({
    mountPoint: commonPages.savingsAccountReady,
    loadableComponent: lazyWithPreload(
      () => import('./routes/savings-account-ready'),
    ),
    suspenseFallback,
  }),

  oauthConsent: createPage({
    mountPoint: commonPages.oauthConsent,
    loadableComponent: lazyWithPreload(() => import('./routes/oauth/consent')),
    suspenseFallback,
  }),

  oauthError: createPage({
    mountPoint: commonPages.oauthError,
    loadableComponent: lazyWithPreload(() => import('./routes/oauth/error')),
    suspenseFallback,
  }),

  moveMoneyFromSavingsAccount: createPage({
    mountPoint: commonPages.moveMoneyFromSavingsAccount,
    loadableComponent: lazyWithPreload(
      () => import('./routes/savings-account/move-money-from-savings-account'),
    ),
    suspenseFallback,
  }),
  moveMoneyToSavingsAccount: createPage({
    mountPoint: commonPages.moveMoneyToSavingsAccount,
    loadableComponent: lazyWithPreload(
      () => import('./routes/savings-account/move-money-to-savings-account'),
    ),
    suspenseFallback,
  }),
  earmarks: createPage({
    mountPoint: commonPages.earmarks,
    loadableComponent: lazyWithPreload(
      () => import('./routes/savings-account'),
    ),
    suspenseFallback,
    preloads: ['transferFromEarmark', 'transferToEarmark'],
  }),
  transferFromEarmark: createPage({
    mountPoint: commonPages.transferFromEarmark,
    getUrl: (fid: string) =>
      commonPages.transferFromEarmark.replace(':fid', fid),
    loadableComponent: lazyWithPreload(
      () => import('./routes/savings-account/transfer-from-earmark'),
    ),
    suspenseFallback,
  }),
  transferToEarmark: createPage({
    mountPoint: commonPages.transferToEarmark,
    getUrl: (fid: string) => commonPages.transferToEarmark.replace(':fid', fid),
    loadableComponent: lazyWithPreload(
      () => import('./routes/savings-account/transfer-to-earmark'),
    ),
    suspenseFallback,
  }),
};

export const SuspendedPage: React.FC<{ page: PageDefinition }> = ({ page }) => (
  <React.Suspense fallback={page.suspenseFallback}>
    <page.component />
  </React.Suspense>
);
