import { format, formatRelative, parseISO } from 'date-fns';
import { dateFnConfig } from './date-fn-config';
import { isPartiallyFormattedTelephoneNumber } from './helpers';
import { printFormat } from './iban';

type Formatters = ReturnType<typeof initFormatters>;

let formatters: Formatters;

// Fall back to Swedish. Chrome 92 doesn't handle 'no' or 'nb' for some reason
const numberFormatLocales = ['no', 'nb', 'sv'];

function initFormatters() {
  const numberFormatter = new Intl.NumberFormat(numberFormatLocales, {
    maximumFractionDigits: 3,
  });

  const amountWithoutFractionFormatter = new Intl.NumberFormat(
    numberFormatLocales,
    {
      maximumFractionDigits: 0,
    },
  );

  const amountWithFractionFormatter = new Intl.NumberFormat(
    numberFormatLocales,
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  );

  const percentageFormatter = new Intl.NumberFormat(numberFormatLocales, {
    style: 'percent',
    maximumFractionDigits: 3,
  });

  const percentageWithoutFractionFormatter = new Intl.NumberFormat(
    numberFormatLocales,
    {
      style: 'percent',
      maximumFractionDigits: 0,
    },
  );

  const currencyFormatters = new Map<string, Intl.NumberFormat>();

  return {
    numberFormatter,
    amountWithoutFractionFormatter,
    amountWithFractionFormatter,
    percentageFormatter,
    percentageWithoutFractionFormatter,
    currencyFormatters,
  };
}

function getFormatters(): Formatters {
  if (!formatters) {
    formatters = initFormatters();
  }
  return formatters;
}

// It's useful to support numbers here, due to trimming and the fact that
// `Number('') === 0`, which is not the same as empty
export function formatNumber(num: string | number) {
  const { numberFormatter } = getFormatters();
  if (typeof num === 'string') {
    num = num.trim();

    // Only format if the string consists of only digits
    if (num === '' || !/\d*/.test(num)) {
      return num;
    }

    num = Number(num);
  }

  return numberFormatter.format(num);
}

type FormatAmountConfig = {
  currency?: string;
  showPositiveSign?: boolean;
  withoutFraction?: boolean;
};

export function formatAmount(
  amount: number,
  {
    currency,
    showPositiveSign = false,
    withoutFraction = true,
  }: FormatAmountConfig = {},
) {
  const { amountWithFractionFormatter, amountWithoutFractionFormatter } =
    getFormatters();

  let formatted: string = withoutFraction
    ? amountWithoutFractionFormatter.format(Math.trunc(amount))
    : amountWithFractionFormatter.format(amount);

  if (currency !== undefined) {
    formatted = `${formatted}\xA0${currency}`;
  }

  if (showPositiveSign && amount > 0) {
    return `+${formatted}`;
  }

  return formatted;
}

// code is e.g. NOK or EUR
export function formatCurrency(
  code: string,
  amount: number,
  options: { useGrouping?: boolean } = { useGrouping: true },
): string | null {
  const { currencyFormatters } = getFormatters();
  const cacheKey = `${code}_${options.useGrouping ? 'grouped' : 'ungrouped'}`;

  let formatter = currencyFormatters.get(cacheKey);

  if (!formatter) {
    // Throws on invalid currency codes
    try {
      formatter = new Intl.NumberFormat(numberFormatLocales, {
        style: 'currency',
        currency: code,
        useGrouping: options.useGrouping,
        currencyDisplay: code === 'NOK' ? 'symbol' : 'code', // Always display "kr" instead of "NOK"
      });
      currencyFormatters.set(cacheKey, formatter);
    } catch {
      return null;
    }
  }

  return formatter.format(amount);
}

export function formatPercentage(
  percentage: number,
  withoutFractions?: boolean,
) {
  const { percentageFormatter, percentageWithoutFractionFormatter } =
    getFormatters();

  if (withoutFractions) {
    return percentageWithoutFractionFormatter.format(percentage / 100);
  }
  return percentageFormatter.format(percentage / 100);
}

export function formatBirthNumber(str: string) {
  return str.replace(
    /(\d{0,6})([\s])?(\d{0,5})/,
    (_, birthDate: string, space: string, personalNumber: string) => {
      if (space === undefined) {
        space = personalNumber === '' ? '' : ' ';
      }

      return `${birthDate}${space}${personalNumber}`;
    },
  );
}

export function formatOrgNumber(str: string) {
  return str.replace(
    /(\d{0,3})(\s)?(\d{0,3})(\s)?(\d{0,3})/,
    (
      _,
      group1: string,
      space1: string,
      group2: string,
      space2: string,
      group3: string,
    ) => {
      if (space1 === undefined || space1 === ' ') {
        space1 = group2 === '' ? '' : '\xA0';
      }

      if (space2 === undefined || space2 === ' ') {
        space2 = group3 === '' ? '' : '\xA0';
      }

      return `${group1}${space1}${group2}${space2}${group3}`;
    },
  );
}

export function isPartiallyFormattedAccountNumber(str: string) {
  // `|$` is a trick to match a partial string
  return /^(\d|$){4}([\s.])?(\d|$){2}([\s.])?(\d|$){5}$/.test(str);
}

/**
 * Format an account number, including a partial account number, in the
 * following format: 0000 00 00000
 *
 * Examples:
 *
 *   12341212345 -> 1234 12 12345
 *   1234.12.12345 -> 1234.12.12345
 *   12341 -> 1234 1
 *
 * If `accountNumber` contains anything other than digits, spaces, or dots,
 * it's returned untouched. Spaces and dots can only appear in the 5th and 8th
 * position. If it appears in any other position, the string is returned
 * untouched. This is to allow the user to use their preferred format. It also
 * avoids re-formatting the whole number if the user needs to edit e.g. the
 * second digit.
 */
export function formatAccountNumber(str: string) {
  if (!isPartiallyFormattedAccountNumber(str)) {
    // Keep the user's own formatting.
    return str;
  }

  return str.replace(
    /(\d{0,4})([\s.])?(\d{0,2})([\s.])?(\d{0,5})/,
    (
      _,
      group1: string, // first 4 digits
      sep1: string, // possible separator
      group2: string, // middle 2 digits
      sep2: string, // possible separator
      group3: string, // last 5 digits
    ) => {
      if (sep1 === undefined) {
        sep1 = group2 === '' ? '' : '\xA0';
      }

      if (sep2 === undefined) {
        sep2 = group3 === '' ? '' : '\xA0';
      }

      return `${group1}${sep1}${group2}${sep2}${group3}`;
    },
  );
}

/**
 * Formats an IBAN
 */
export function formatIban(str: string) {
  return printFormat(str);
}

type AcceptableInputDate = Date | number | string;

function parseDate(date: AcceptableInputDate): Date | number {
  if (typeof date === 'string') {
    return parseISO(date);
  }

  return date;
}

export function formatDate(date: AcceptableInputDate, formatting: string) {
  date = parseDate(date);

  return format(date, formatting, dateFnConfig);
}

export function formatRelativeDate(
  date: AcceptableInputDate,
  baseDate: AcceptableInputDate = new Date(),
) {
  date = parseDate(date);
  baseDate = parseDate(baseDate);

  return formatRelative(date, baseDate, dateFnConfig);
}

export function capitalize<T extends string>(input: T): Capitalize<T> {
  return (input.charAt(0).toLocaleUpperCase() + input.slice(1)) as any;
}

export function joinRunningTextList(items: readonly string[]) {
  if (items.length === 0) {
    return '';
  }

  if (items.length === 1) {
    return items[0];
  }

  const allButOne = items.slice(0, items.length - 1);
  const last = items[items.length - 1];

  return `${allButOne.join(', ')} og ${last}`;
}

export function formatTelephoneNumber(str: string) {
  if (!isPartiallyFormattedTelephoneNumber(str)) {
    // Keep the user's own formatting.
    return str;
  }

  return str.replace(
    /(\d{0,2})(\s)?(\d{0,2})(\s)?(\d{0,2})(\s)?(\d{0,2})/,
    (
      _,
      group1: string, // first 3 digits
      sep1 = '', // possible separator
      group2: string, // middle 2 digits
      sep2 = '', // possible separator
      group3: string, // last 3 digits
      sep3 = '', // possible separator
      group4: string, // last 3 digits
    ) => {
      if (group2 !== '') {
        sep1 = '\u{A0}';
      }

      if (group3 !== '') {
        sep2 = '\u{A0}';
      }

      if (group4 !== '') {
        sep3 = '\u{A0}';
      }

      return `${group1}${sep1}${group2}${sep2}${group3}${sep3}${group4}`;
    },
  );
}
