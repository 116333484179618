import * as Sentry from '@sentry/react';
import * as React from 'react';
import { GenericErrorPage } from './components/error-pages';

export class ErrorBoundary extends React.PureComponent<
  { children: React.ReactNode },
  { eventId?: string; hasError: boolean }
> {
  state = { eventId: undefined, hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: React.ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras({ componentStack: errorInfo.componentStack });
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      return <GenericErrorPage />;
    }

    // when there's not an error, render children untouched
    return this.props.children;
  }
}
