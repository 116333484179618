import type { EventItem } from './types';

export type SortableEvent = Pick<EventItem, 'fid' | 'dateTime'> & {
  description?: string;
};

// On "Mangler noe", we keep newly completed events and insert them in the view
// to avoid them disappearing immediately. If we have two events [A,B], and `A`
// becomes complete, we insert it last and the order becomes [B,A] This sorts by
// dateTime with fallback to description and lastly fid to make the sorting
// stable.
export function eventsSorter(a: SortableEvent, b: SortableEvent) {
  const dateTimeCompare = b.dateTime.localeCompare(a.dateTime);
  if (dateTimeCompare !== 0) {
    return dateTimeCompare;
  }

  if (a.description && b.description) {
    const descriptionCompare = a.description.localeCompare(b.description);
    if (descriptionCompare !== 0) {
      return descriptionCompare;
    }
  }

  return a.fid.localeCompare(b.fid);
}
