import { css } from '@emotion/react';
import { updateInfoPrefix } from 'bank-common';
import { ButtonLink, mq, space } from 'folio-common-components';
import { colors } from 'folio-design-tokens';
import { useFirstPaintData } from '../../hooks/use-first-paint-data';
import { MicroPapersIllustration } from '../../illustrations';

export const NeedsKycBanner: React.FC = () => {
  const { data } = useFirstPaintData();
  if (data?.organization.kycStatus !== 'Needed') {
    return null;
  }

  return (
    <div
      css={css`
        display: grid;
        overflow: hidden;
        grid-template-columns: max-content 1fr;
        grid-template-areas:
          'icon   text'
          'button button';
        background: ${colors.blueLight};
        border-radius: 8px;
        align-items: center;

        ${space([16], 'padding', 'gap')};
        ${space([24], 'margin-bottom')};

        @media ${mq.medium} {
          grid-template-areas: 'icon text button';
          ${space([48], 'margin-bottom')};
          ${space([24], 'gap')};
        }
      `}
    >
      <MicroPapersIllustration
        css={css`
          grid-area: icon;
        `}
      />
      <div
        css={css`
          grid-area: text;
        `}
      >
        Vi trenger oppdatert informasjon om {data?.organization.name}
      </div>
      <ButtonLink
        css={css`
          grid-area: button;
        `}
        fullWidth
        size="medium"
        level="secondary"
        href={`${updateInfoPrefix}/${data?.organization.orgNum}`}
      >
        Oppdater info
      </ButtonLink>
    </div>
  );
};
