import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import { OverlayContainer } from '@react-aria/overlays';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { autofillConstants } from 'bank-common';
import {
  Button,
  ProgressSpinner,
  Switch,
  space,
} from 'folio-common-components';
import { formatters } from 'folio-common-utils';
import * as React from 'react';
import {
  StartAutofillDocument,
  StopAutofillDocument,
} from '../../common-client-queries.generated';
import type { AutofillDataFragment } from '../../common-queries.generated';
import { ModalDialog, dialogHeadingStyle } from '../../components/modal-dialog';
import { SectionHeading } from '../../components/section-heading';
import { Divider } from '../../components/user-profile';
import { useFirstPaintData } from '../../hooks/use-first-paint-data';
import { useFragmentId } from '../../hooks/use-fragment-id';
import { useTransactionAndBalanceRefetchConfig } from '../../hooks/use-transaction-and-balance-refetch-config';

const {
  dailyLimit,
  desiredAmount,
  desiredAmountAsNumber,
  lowLimit,
  lowLimitAsNumber,
} = autofillConstants;

type AutofillData = NonNullable<AutofillDataFragment['autofill']>;

type AmountInfoData = NonNullable<AutofillDataFragment['balanceNok']>;

export interface AutofillAccount
  extends Pick<AutofillDataFragment, 'accountNumber'> {
  autofill: Omit<AutofillData, '__typename'> | null;
  balanceNok: Omit<AmountInfoData, '__typename'> | null;
}

const MoneyTransferMessage: React.FC<{
  operationalAccountBalance: number;
  currentBalance: number;
}> = ({ currentBalance, operationalAccountBalance }) => {
  const fullAmountToTransfer = desiredAmountAsNumber - currentBalance;

  const willEmptyOperationalAccount =
    fullAmountToTransfer > operationalAccountBalance;

  const amountToTransfer = willEmptyOperationalAccount
    ? operationalAccountBalance
    : fullAmountToTransfer;

  const introText = willEmptyOperationalAccount
    ? 'Vi flytter alle pengene fra driftskonto over på kortet.'
    : `${formatters.formatAmount(amountToTransfer, {
        currency: 'kr',
      })} overføres fra driftskonto.`;

  return (
    <p>
      {introText} Ny kortsaldo blir{' '}
      {formatters.formatAmount(amountToTransfer + currentBalance)}.
    </p>
  );
};

export const CardAutofillSwitch: React.FC<
  { account: AutofillAccount } & (
    | { children: React.ReactNode; ['aria-labelledby']?: never }
    | { children?: never; ['aria-labelledby']: string }
  )
> = ({ account, ...props }) => {
  const { data: firstPaintData } = useFirstPaintData();
  const transactionAndBalanceRefetchConfig =
    useTransactionAndBalanceRefetchConfig();
  const [startAutofillMutation, { loading: startLoading }] = useMutation(
    StartAutofillDocument,
    {
      // TODO: should we only refetch if any transfers actually happened?
      ...transactionAndBalanceRefetchConfig,
      variables: {
        accountNumber: account.accountNumber,
        desiredAmount,
        lowLimit,
        dailyLimit,
      },
    },
  );
  const [stopAutofillMutation, { loading: stopLoading }] = useMutation(
    StopAutofillDocument,
    { variables: { accountNumber: account.accountNumber } },
  );
  // Used to force the selected state of the switch
  const [forcedSelected, setForcedSelected] = React.useState(false);
  const confirmOverlayState = useOverlayTriggerState({
    onOpenChange(isOpen) {
      setForcedSelected(isOpen);
    },
  });
  const dialogTitleRef = React.useRef<HTMLHeadingElement>(null);

  if (!firstPaintData) {
    return null;
  }

  const operationalAccountBalance =
    firstPaintData.organization.operationalAccount?.balanceNok?.asNumber ?? 0;

  const loading = startLoading || stopLoading;
  const { autofill, balanceNok } = account;
  const balance = balanceNok?.asNumber ?? 0;
  const isSelected = forcedSelected || (autofill ? !stopLoading : startLoading);

  return (
    <>
      <Switch
        isLoading={loading && !forcedSelected}
        isSelected={isSelected}
        onChange={() => {
          if (loading) {
            return;
          }

          if (autofill) {
            stopAutofillMutation();
          } else if (
            operationalAccountBalance > 0 &&
            balance < lowLimitAsNumber
          ) {
            confirmOverlayState.open();
          } else {
            startAutofillMutation();
          }
        }}
        {...props}
      />
      {confirmOverlayState.isOpen && (
        <OverlayContainer>
          <ModalDialog
            title="Fyller på kortet nå"
            isOpen
            onClose={confirmOverlayState.close}
            css={css`
              max-width: 320px;
            `}
          >
            <div tabIndex={-1} ref={dialogTitleRef} css={dialogHeadingStyle}>
              Fyller på kortet nå
            </div>
            <div
              css={css`
                position: relative;
                visibility: ${startLoading ? 'hidden' : undefined};
              `}
            >
              {startLoading && (
                <div
                  css={css`
                    visibility: visible;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  <ProgressSpinner size={48} />
                </div>
              )}
              <MoneyTransferMessage
                currentBalance={balance}
                operationalAccountBalance={operationalAccountBalance}
              />
              <div
                css={css`
                  display: grid;
                  grid-auto-flow: column;
                  ${space([16], 'gap')};
                `}
              >
                <Button onClick={confirmOverlayState.close} level="secondary">
                  Avbryt
                </Button>{' '}
                <Button
                  onClick={async () => {
                    // Set focus since the buttons are hidden
                    // when the spinner is shown
                    dialogTitleRef.current?.focus();
                    await startAutofillMutation();
                    confirmOverlayState.close();
                  }}
                >
                  Kjør på
                </Button>
              </div>
            </div>
          </ModalDialog>
        </OverlayContainer>
      )}
    </>
  );
};

export const CardAutofillAdmin: React.FC<{
  account: AutofillAccount;
}> = ({ account }) => {
  const { autofill } = account;
  const scrollToId = useFragmentId();

  return (
    <div id="automatisk-påfyll" ref={scrollToId}>
      <Divider />
      <SectionHeading>Automatisk påfyll av kort</SectionHeading>
      <p>
        Fyller opp til{' '}
        {formatters.formatAmount(
          Number(autofill?.desiredAmount ?? desiredAmount),
        )}{' '}
        når det er under{' '}
        {formatters.formatAmount(Number(autofill?.lowLimit ?? lowLimit))} på
        kortet. Automatiske overføringer fra driftskonto har en daglig grense på{' '}
        {formatters.formatAmount(Number(autofill?.dailyLimit ?? dailyLimit), {
          currency: 'kr',
        })}{' '}
        per kort.
      </p>
      <CardAutofillSwitch account={account}>
        Automatisk påfyll
      </CardAutofillSwitch>
    </div>
  );
};
