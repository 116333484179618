import styled from '@emotion/styled';
import { colors } from 'folio-design-tokens';
import * as React from 'react';

const DividerElement = styled.hr`
  border: 0;
  height: 1px;
  background: ${colors.grayMedium};

  @media (prefers-contrast: more) {
    background: ${colors.black};
  }
`;

export const Divider: React.FC<React.HTMLAttributes<HTMLHRElement>> = props => (
  <DividerElement {...props} role="presentation" />
);
