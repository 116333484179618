import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useBatchedQuery } from 'bank-common-client';
import {
  Label,
  PlainButton,
  Select,
  type SelectOptionType,
  TextButton,
  TextInput,
  space,
} from 'folio-common-components';
import { formatters } from 'folio-common-utils';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { useMediaLayout } from 'use-media';
import { NumberBadge } from '../../components/number-badge';
import {
  tabActiveStyle,
  tabBarStyle,
  tabStyle,
} from '../../components/tab-nav';
import { useHasCapability } from '../../hooks/use-capability-check';
import { MagnifyingGlassIcon } from '../../icons';
import type {
  CategoryFilterConfiguration,
  EventsByFilterConfiguration,
  Filters,
  PersonFilterConfiguration,
  StatusFilterConfiguration,
} from './filter-utils';
import { EmployeesListDocument } from './queries.generated';

type OnChangeParams =
  | { filter: 'text'; value: string }
  | { filter: 'category'; value: CategoryFilterConfiguration }
  | { filter: 'person'; value: PersonFilterConfiguration }
  | { filter: 'status'; value: StatusFilterConfiguration };

export interface CategoryFilterProps {
  filters: Filters;
  onChange: (args: OnChangeParams) => void;
  usedSubcategories: string[];
  eventsByFilterConfiguration: EventsByFilterConfiguration;
}

interface TransactionFilterProps extends CategoryFilterProps {
  hidden: boolean;
  toggleFilterVisibility: () => void;
  loading: boolean;
}

interface CategoryOptionType extends SelectOptionType {
  value: CategoryFilterConfiguration;
}

interface PersonOptionType extends SelectOptionType {
  value: PersonFilterConfiguration;
}

const TwoColumnLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  ${space([16], 'gap')};
`;

const CategoryFilter: React.FC<CategoryFilterProps> = ({
  filters,
  eventsByFilterConfiguration,
  onChange,
  usedSubcategories,
}) => {
  const allOption: CategoryOptionType = {
    value: 'all',
    label: `Alle (${formatters.formatNumber(
      eventsByFilterConfiguration.category.all?.length ?? 0,
    )})`,
  };

  const selectedSubcategory = usedSubcategories.find(
    category => category === filters.category,
  );

  const selectedOption =
    filters.category === 'all' || selectedSubcategory == null
      ? allOption
      : { value: selectedSubcategory, label: selectedSubcategory };

  return (
    <div>
      <Label for="filter-category">Kategori</Label>
      <Select<CategoryOptionType>
        id="filter-category"
        options={[
          allOption,
          ...usedSubcategories.map(subcategory => {
            const availableEvents =
              eventsByFilterConfiguration.category[subcategory] ?? [];

            return {
              value: subcategory,
              label: `${subcategory} (${formatters.formatNumber(
                availableEvents.length,
              )})`,
              disabled: availableEvents.length === 0,
            };
          }),
        ]}
        css={css`
          width: 100%;
        `}
        selectedOption={selectedOption}
        onChange={selectedOption =>
          onChange({
            filter: 'category',
            value: selectedOption.value,
          })
        }
      />
    </div>
  );
};

const PersonFilter: React.FC<
  Pick<CategoryFilterProps, 'filters' | 'onChange'>
> = ({ filters, onChange }) => {
  const { data: employeesData } = useBatchedQuery(EmployeesListDocument);

  const allOption: PersonOptionType = {
    value: 'all',
    label: 'Alle',
  };
  const cardHolders = employeesData?.organization.employeesWithCards ?? [];

  const cardAccounts = cardHolders
    .filter(cardHolder => cardHolder.accounts.length > 0)
    .map(cardHolder => ({
      name: cardHolder.agent.structuredName.full,
      fid: cardHolder.agent.fid,
    }))
    .map(opt => ({ label: opt.name, value: opt.fid }))
    .sort((l, r) => l.label.localeCompare(r.label));

  const selectedPerson =
    filters.person === 'all'
      ? null
      : cardAccounts.find(cardAcc => cardAcc.value === filters.person);

  const selectedOption = selectedPerson == null ? allOption : selectedPerson;

  return (
    <div>
      <Label for="filter-person">Person</Label>
      <Select<PersonOptionType>
        id="filter-person"
        options={[allOption, ...cardAccounts]}
        css={css`
          width: 100%;
        `}
        selectedOption={selectedOption}
        onChange={selectedOption =>
          onChange({
            filter: 'person',
            value: selectedOption.value,
          })
        }
      />
    </div>
  );
};

const TextSearch: React.FC<
  Pick<CategoryFilterProps, 'filters' | 'onChange'>
> = ({ filters, onChange }) => (
  <TextInput
    label="Søk"
    value={filters.text}
    css={css`
      width: 100%;
    `}
    onChange={value => onChange({ filter: 'text', value })}
  />
);

const EmployeeFilter: React.FC<CategoryFilterProps> = ({
  onChange,
  filters,
  eventsByFilterConfiguration,
  usedSubcategories,
}) => (
  <TwoColumnLayout>
    <div>
      <TextSearch onChange={onChange} filters={filters} />
    </div>
    <CategoryFilter
      onChange={onChange}
      eventsByFilterConfiguration={eventsByFilterConfiguration}
      filters={filters}
      usedSubcategories={usedSubcategories}
    />
  </TwoColumnLayout>
);

const NonEmployeeFilter: React.FC<CategoryFilterProps> = ({
  onChange,
  filters,
  eventsByFilterConfiguration,
  usedSubcategories,
}) => (
  <>
    <div>
      <TextSearch onChange={onChange} filters={filters} />
    </div>
    <div
      css={css`
        ${space([16], 'margin-top')};
      `}
    >
      <TwoColumnLayout>
        <PersonFilter onChange={onChange} filters={filters} />
        <CategoryFilter
          onChange={onChange}
          eventsByFilterConfiguration={eventsByFilterConfiguration}
          filters={filters}
          usedSubcategories={usedSubcategories}
        />
      </TwoColumnLayout>
    </div>
  </>
);

const FilterContent: React.FC<CategoryFilterProps> = props => {
  const canListOrgEvents = useHasCapability('CanListOrgEvents');

  if (canListOrgEvents == null) {
    return null;
  }

  return canListOrgEvents ? (
    <NonEmployeeFilter {...props} />
  ) : (
    <EmployeeFilter {...props} />
  );
};

export const TransactionFilter: React.FC<TransactionFilterProps> = ({
  hidden,
  filters,
  eventsByFilterConfiguration,
  onChange,
  usedSubcategories,
  toggleFilterVisibility,
  loading,
}) => {
  const isWide = useMediaLayout('(min-width: 420px)');
  const incompleteEventsLength =
    eventsByFilterConfiguration.status.incomplete.length;
  return (
    <div
      role="search"
      css={css`
        ${space([32], 'margin-bottom')};
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          margin-bottom: 8px;
          flex-wrap: wrap;
          ${tabBarStyle};
        `}
      >
        <div>
          <PlainButton
            css={[tabStyle, filters.status === 'all' && tabActiveStyle]}
            onClick={() => onChange({ filter: 'status', value: 'all' })}
            aria-pressed={filters.status === 'all'}
            aria-label="Siste bevegelser"
          >
            {isWide ? 'Siste bevegelser' : 'Siste'}
          </PlainButton>
          <PlainButton
            css={[tabStyle, filters.status === 'incomplete' && tabActiveStyle]}
            onClick={() => onChange({ filter: 'status', value: 'incomplete' })}
            aria-pressed={filters.status === 'incomplete'}
          >
            Mangler noe
            {!loading && (
              <NumberBadge
                aria-label={`(${incompleteEventsLength})`}
                color="black"
                css={css`
                  margin-left: 8px;
                `}
              >
                {incompleteEventsLength < 100 ? incompleteEventsLength : '99+'}
              </NumberBadge>
            )}
          </PlainButton>
        </div>
        <div>
          <TextButton
            onClick={toggleFilterVisibility}
            icon={
              <MagnifyingGlassIcon
                css={css`
                  margin-top: -3px;
                `}
              />
            }
            aria-expanded={!hidden}
            aria-controls="all-filters"
            size="large"
          >
            Søk
          </TextButton>
        </div>
      </div>
      <div
        id="all-filters"
        hidden={hidden}
        css={css`
          border-radius: 8px;
          ${space([16], 'padding')};
          background-color: ${colors.grayLight};
        `}
      >
        <FilterContent
          eventsByFilterConfiguration={eventsByFilterConfiguration}
          filters={filters}
          onChange={onChange}
          usedSubcategories={usedSubcategories}
        />
      </div>
    </div>
  );
};
