import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { fonts } from 'folio-common-components';
import { colors } from 'folio-design-tokens';

export type LabelColor =
  | 'red'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'black'
  | 'purple';

interface ColoredLabelProps {
  color: LabelColor;
}

const colorMap: Record<LabelColor, { background: string; text: string }> = {
  red: { background: colors.redLight, text: colors.wcagRed },
  green: { background: colors.greenLight, text: colors.greenTextLabel },
  yellow: {
    background: colors.yellowLight,
    text: colors.yellowTextLabel,
  },
  blue: { background: colors.blueLight, text: colors.blue },
  black: { background: colors.black, text: colors.white },
  purple: {
    background: colors.purpleLight,
    text: colors.purpleTextLabel,
  },
};

export const ColoredLabel = styled('span', {
  shouldForwardProp: prop => isPropValid(prop) && prop !== 'color',
})<ColoredLabelProps>`
  ${fonts.font100medium};
  padding: 1px 4px;
  background-color: ${props => colorMap[props.color].background};
  color: ${props => colorMap[props.color].text};
  border-radius: 4px;
  min-width: 22px;
  text-align: center;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
`;
