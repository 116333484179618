// Ported from https://github.com/folio-as/checksum/blob/master/mod11.go

export function mod11Check(sum: number, checksum: number): boolean {
  sum %= 11;
  if (sum === 0) {
    sum = 11;
  }
  return checksum === 11 - sum;
}

function mod11Sum(values: number[]): number {
  let sum = 0;
  const end = values.length - 1;
  for (const [i, value] of values.entries()) {
    sum += value * (((end - i) % 6) + 2);
  }
  return sum;
}

export function mod11(value: string): boolean {
  const end = value.length - 1;
  if (end < 0) {
    return false;
  }

  let checksum = 10;
  const v = value[end];
  if (v >= '0' && v <= '9') {
    checksum = Number(v);
  } else if (v !== '-') {
    return false;
  }

  const values = Array.from(value.slice(0, end), Number);
  return mod11Check(mod11Sum(values), checksum);
}

export function weightedSum(values: number[], weights: number[]) {
  return values
    .map((num, index) => num * weights[index])
    .reduce((p, c) => p + c);
}
