// Ported from https://github.com/folio-as/checksum/blob/master/ssn.go

import { mod11, mod11Check, weightedSum } from './mod11';
import { removeWhitespace } from './utils';

const weights = [3, 7, 6, 1, 8, 9, 4, 5, 2];

// Fødselsnummer starts with 0-3
// D-nummer starts with 4-7
const pNumRe = /^[0-7]\d{10}$/;
const dNumRe = /^[4-7]\d{10}$/;

/**
 * Validates a Norwegian personal number, either fødselsnummer or d-nummer
 */
export function isValidPnum(value: string): boolean {
  const normalized = removeWhitespace(value);
  if (!pNumRe.test(normalized)) {
    return false;
  }

  const numbers = Array.from(normalized).map(Number);
  return (
    mod11(normalized) &&
    mod11Check(weightedSum(numbers.slice(0, 9), weights), numbers[9])
  );
}

/**
 * Validates a Norwegian d-nummer
 */
export function isValidDnum(value: string) {
  return dNumRe.test(removeWhitespace(value)) && isValidPnum(value);
}
