import type { EventItemFragment } from '../common-client-queries.generated';

const specialCategories = new Set([
  'j8BTFaMJOxS5gkB5SXY7D7', // aga
  'Ue2QvIr9cu0xUyiKrO6XpH', // skattetrekk
  'UQZcjlaeN0Wz5PmvhFoQMV', // merverdiavgift
]);

type LedgerCategoryInfo = EventItemFragment['ledgerCategoryInfo'];

export function getCustomLedgerTitle({
  kind,
  category,
}: LedgerCategoryInfo): string | null {
  if (kind !== 'Selected' || category == null) {
    return null;
  }

  if (specialCategories.has(category.fid)) {
    return category.title;
  }

  return null;
}

export const taxAdministrationAccountNumber = '63450624804';
