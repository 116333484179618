import React from 'react';
import lazyWithPreload from 'react-lazy-with-preload';
import {
  ArrowsRightLeftIcon,
  HomeIcon,
  PaperFoldedIcon,
  PersonIcon,
} from '../icons';
import { type PageDefinition, pages } from '../pages';
import { useHasCapability } from './use-capability-check';
import { useFirstPaintData } from './use-first-paint-data';

export interface MenuItem {
  page: PageDefinition;
  icon: React.ReactElement;
  labelComponent: React.ComponentType<LabelComponentProps>;
}

export type LabelComponentProps = { isActive: boolean };

const PaymentsLabelComponent = lazyWithPreload(
  () => import('../routes/payment/payments-label'),
);

const PaymentsLabel: React.FC<LabelComponentProps> = props => (
  <React.Suspense fallback="Betaling">
    <PaymentsLabelComponent {...props} />
  </React.Suspense>
);

export function useMenuItems(): readonly MenuItem[] {
  const { data: firstPaintData } = useFirstPaintData();
  const canInspectAnyOrg = useHasCapability('CanInspectAnyOrg');
  const canListOrgCardAccounts = useHasCapability('CanListOrgCardAccounts');
  const canListPayments = useHasCapability('CanListPayments');
  const canExportTransactions = useHasCapability('CanExportTransactions');

  if (!firstPaintData) {
    return [];
  }

  if (!canInspectAnyOrg) {
    const orgState = firstPaintData.organization.state;

    if (orgState === 'Archived') {
      return [
        {
          page: pages.accounting,
          icon: <PaperFoldedIcon />,
          labelComponent: () => <>Regnskap</>,
        },
      ];
    }

    if (orgState !== 'Active') {
      return [
        {
          page: pages.transactions,
          icon: <HomeIcon />,
          labelComponent: () => <>Oversikt</>,
        },
      ];
    }
  }

  const menuItems: MenuItem[] = [
    {
      page: pages.transactions,
      icon: <HomeIcon />,
      labelComponent: () => <>Oversikt</>,
    },
  ];

  if (canListOrgCardAccounts) {
    menuItems.push({
      page: pages.employees,
      icon: <PersonIcon />,
      labelComponent: () => <>Brukere</>,
    });
  }

  if (canListPayments) {
    menuItems.push({
      page: pages.payments,
      icon: <ArrowsRightLeftIcon />,
      labelComponent: PaymentsLabel,
    });
  }

  if (canExportTransactions) {
    menuItems.push({
      page: pages.accounting,
      icon: <PaperFoldedIcon />,
      labelComponent: () => <>Regnskap</>,
    });
  }

  return menuItems;
}
