import { css } from '@emotion/react';

export const skipLinkId = 'innhold';

export const SkipLink: React.FC = () => {
  return (
    <a
      href={`#${skipLinkId}`}
      onClick={event => {
        event.preventDefault();
        document.getElementById(skipLinkId)?.focus();
      }}
      css={css`
        background: #fff;
        position: absolute;
        top: 8px;
        left: 16px;
        padding: 12px;
        opacity: 0;
        pointer-events: none;

        :focus {
          opacity: 1;
          pointer-events: initial;
        }
      `}
    >
      Hopp til innhold
    </a>
  );
};
