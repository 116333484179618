// Heavily modified version of https://github.com/arhs/iban.js

import { countryFormats } from './country-formats';

const nonAlphaNumPattern = /[^a-zA-Z0-9]/g;
const everyFourCharsPattern = /(.{4})/g;
const everyThreeCharsPattern = /(.{3})/g;

const A = 'A'.charCodeAt(0);
const Z = 'Z'.charCodeAt(0);

// Step 6.2 in ISO 13616-1
function validate(iban: string): boolean {
  // 6.2.1
  const normalized = electronicFormat(iban);

  // 6.2.2
  const value = [...normalized.slice(4), ...normalized.slice(0, 4)]
    .map(char => {
      // 6.2.3
      const code = char.charCodeAt(0);
      if (code >= A && code <= Z) {
        // A = 10, B = 11, ... Z = 35
        return code - A + 10;
      }
      return char;
    })
    .join('');

  // 6.2.4, 6.2.5
  return mod97_10(value) === 1;
}

/**
 * Calculates MOD 97-10 (ISO 7064)
 */
function mod97_10(value: string): number {
  let remainder = value;

  while (remainder.length > 2) {
    const block = remainder.slice(0, 9);
    remainder = (Number(block) % 97) + remainder.slice(block.length);
  }

  return Number(remainder) % 97;
}

/**
 * Parse the BBAN structure and return a regexp. The structure is groups of
 * three characters, each group consisting of one letter followed by a
 * two-digit number. The letter represents a character group and the number
 * represents how many times that group repeat.
 */
function parseStructure(structure: string) {
  const match = structure.match(everyThreeCharsPattern);
  if (!match) {
    throw new Error('No match');
  }

  const groups = match.map(block => {
    const pattern = block[0];
    const repeats = block.slice(1);

    switch (pattern) {
      case 'A':
        return formatPattern('0-9A-Za-z', repeats);
      case 'B':
        return formatPattern('0-9A-Z', repeats);
      case 'C':
        return formatPattern('A-Za-z', repeats);
      case 'F':
        return formatPattern('0-9', repeats);
      case 'L':
        return formatPattern('a-z', repeats);
      case 'U':
        return formatPattern('A-Z', repeats);
      case 'W':
        return formatPattern('0-9a-z', repeats);
    }

    throw new Error(`Unrecognized pattern '${pattern}'`);
  });

  return new RegExp(`^${groups.join('')}$`);
}

function formatPattern(format: string, repeats: string) {
  return `([${format}]{${repeats}})`;
}

function electronicFormat(iban: string): string {
  return iban.replace(nonAlphaNumPattern, '').toUpperCase();
}

export function printFormat(iban: string): string {
  return electronicFormat(iban).replace(everyFourCharsPattern, '$1 ').trim();
}

export function validateIban(iban: string):
  | { status: 'valid' }
  | { status: 'invalid' }
  | {
      status: 'wrong-length';
      requiredLength: number;
    }
  | { status: 'non-existing-country-code' } {
  iban = electronicFormat(iban);
  const ibanFormat = countriesToFormat.get(iban.slice(0, 2));
  if (!ibanFormat) {
    return { status: 'non-existing-country-code' };
  }

  if (iban.length !== ibanFormat.length) {
    return {
      status: 'wrong-length',
      requiredLength: ibanFormat.length,
    };
  }

  if (!ibanFormat.isValid(iban)) {
    return { status: 'invalid' };
  }

  return {
    status: 'valid',
  };
}

class IbanFormat {
  length;
  private countryCode;
  private structure;
  private cachedBbanPattern: RegExp | undefined;

  constructor(countryCode: string, length: number, structure: string) {
    this.countryCode = countryCode;
    this.length = length;
    this.structure = structure;
  }

  private get bbanPattern() {
    if (!this.cachedBbanPattern) {
      this.cachedBbanPattern = parseStructure(this.structure);
    }
    return this.cachedBbanPattern;
  }

  isValid(iban: string) {
    return (
      this.length === iban.length &&
      this.countryCode === iban.slice(0, 2) &&
      this.bbanPattern.test(iban.slice(4)) &&
      validate(iban)
    );
  }
}

const countriesToFormat = new Map<string, IbanFormat>();
countryFormats.forEach(([countryCode, length, bbanStructure]) =>
  countriesToFormat.set(
    countryCode,
    new IbanFormat(countryCode, length, bbanStructure),
  ),
);
