import type { Card, Cards } from '../components/CardTopUp';

export function accountHasBlockedCard(cards: Cards): Card | undefined {
  const anyActiveOrInactive = cards.some(
    card => card.state === 'Active' || card.state === 'NotActivated',
  );

  // if there is any active or non-activated cards, don't say it's blocked
  if (anyActiveOrInactive) {
    return undefined;
  }

  return cards.find(
    card => card.state === 'Blocked' || card.state === 'FraudBlocked',
  );
}
