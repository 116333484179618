import { css } from '@emotion/react';
import styled from '@emotion/styled';

// https://github.com/twbs/bootstrap/blob/v4-dev/scss/mixins/_screen-reader.scss
export const visuallyHiddenStyles = css`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

export const VisuallyHidden = styled.div`
  ${visuallyHiddenStyles}
`;
