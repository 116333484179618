import { type DocumentNode, useApolloClient } from '@apollo/client';
import { css } from '@emotion/react';
import * as Sentry from '@sentry/react';
import { type ButtonLinkProps, DummyButton } from 'folio-common-components';
import { parseOrgNum } from 'folio-common-utils';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import {
  Link,
  type LinkProps,
  Navigate,
  type NavigateOptions,
  type NavigateProps,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useInteractionIntent } from '../hooks/use-interaction-intent';

const focusRestoreHref = 'focusRestoreEleHref';

function getPathAbsoluteUrl({
  pathname,
  search,
  hash,
}: Pick<Location, 'pathname' | 'search' | 'hash'>) {
  return `${pathname}${search}${hash}`;
}

/**
 * Returns a function that turns `path` into `/org/<orgNum>/<path>`
 */
export function useOrgPathAbsoluteUrl(): (path?: string) => string {
  const { pathname } = useLocation();
  const orgNumber = parseOrgNum(pathname);
  return React.useCallback(
    (path = '') => {
      // If this is already a path-absolute URL, return it
      if (path.startsWith('/')) {
        return path;
      }

      const isOrgRoot = path === '';
      const url = new URL(
        isOrgRoot ? `/org/${orgNumber}` : `/org/${orgNumber}/${path}`,
        'https://example.com',
      );

      // If this happens, it's used incorrectly on a non-org URL so log it
      if (!orgNumber) {
        Sentry.captureMessage(
          `useOrgPathAbsoluteUrl used for ${path} on non-org URL`,
        );
        return getPathAbsoluteUrl({ ...url, pathname: path });
      }

      return getPathAbsoluteUrl(url);
    },
    [orgNumber],
  );
}

export function useOrgNavigate() {
  const navigate = useNavigate();
  const urlWithOrgPath = useOrgPathAbsoluteUrl();
  const location = useLocation();
  const referrer = getPathAbsoluteUrl(location);
  return React.useCallback(
    (to: string, options?: NavigateOptions) => {
      const navigateOptions = {
        ...options,
        state: { referrer, ...options?.state },
      };
      navigate(urlWithOrgPath(to), navigateOptions);
    },
    [urlWithOrgPath, navigate, referrer],
  );
}

export function useRestoreFocus() {
  React.useLayoutEffect(() => {
    function restoreFocus(event: PopStateEvent) {
      const href = event.state?.[focusRestoreHref];
      if (typeof href === 'string') {
        const ele = document.querySelector<HTMLElement>(
          `[href='${CSS.escape(href)}']`,
        );
        if (ele) {
          ele.focus({ preventScroll: true });
        }
      }
    }

    window.addEventListener('popstate', restoreFocus);

    return () => window.removeEventListener('popstate', restoreFocus);
  }, []);
}

export interface OrgLinkProps extends Omit<LinkProps, 'to'> {
  to: string;
  preloadQueries?: DocumentNode[];
  /**
   * Using this prop does two things:
   * - it overrides the label of the backlink on the page that is navigated to
   * - it overrides the href of said backlink, by pointing back to the page
   *   from where this link is used (i.e. it's using the referrer).
   */
  backlink?: string;
}

export const OrgLink: React.FC<OrgLinkProps> = ({
  to,
  state,
  backlink,
  onClick,
  onPointerEnter,
  onPointerLeave,
  onFocus,
  onBlur,
  preloadQueries,
  ...rest
}) => {
  const urlWithOrgPath = useOrgPathAbsoluteUrl();
  const path = urlWithOrgPath(to);
  const location = useLocation();
  const referrer = getPathAbsoluteUrl(location);

  const client = useApolloClient();
  const eventListeners = useInteractionIntent(() => {
    if (preloadQueries) {
      for (const query of preloadQueries) {
        client.query({ query });
      }
    }
  });

  return (
    <Link
      to={path}
      state={{ referrer, backlink, ...state }}
      onClick={event => {
        onClick?.(event);
        if (!event.defaultPrevented) {
          history.replaceState(
            { ...history.state, [focusRestoreHref]: path },
            '',
          );
        }
      }}
      onPointerEnter={event => {
        onPointerEnter?.(event);
        eventListeners.onPointerEnter();
      }}
      onPointerLeave={event => {
        onPointerLeave?.(event);
        eventListeners.onPointerLeave();
      }}
      onFocus={event => {
        onFocus?.(event);
        eventListeners.onFocus();
      }}
      onBlur={event => {
        onBlur?.(event);
        eventListeners.onBlur();
      }}
      {...rest}
    />
  );
};

type OrgButtonLinkProps = OrgLinkProps & ButtonLinkProps;

export const OrgButtonLink: React.FC<OrgButtonLinkProps> = ({
  fullWidth,
  size,
  level,
  icon,
  iconRight,
  children,
  ...rest
}) => (
  <OrgLink
    css={css`
      display: inline-block;
      border-radius: 8px;
      position: relative;
      -webkit-tap-highlight-color: transparent;
      width: ${fullWidth ? '100%' : null};

      &.focus-visible {
        box-shadow: none;
      }

      &.focus-visible::after {
        content: '';
        position: absolute;
        top: -4px;
        right: -4px;
        bottom: -4px;
        left: -4px;
        border: 2px solid ${colors.blue};
        border-radius: 10px;
        pointer-events: none;
      }
    `}
    {...rest}
  >
    <DummyButton
      fullWidth={fullWidth}
      size={size}
      level={level}
      icon={icon}
      iconRight={iconRight}
    >
      {children}
    </DummyButton>
  </OrgLink>
);

interface OrgNavigateProps extends Omit<NavigateProps, 'to'> {
  to: string;
}

export const OrgNavigate: React.FC<OrgNavigateProps> = ({ to, ...rest }) => {
  const urlWithOrgPath = useOrgPathAbsoluteUrl();
  const path = urlWithOrgPath(to);

  return <Navigate to={path} {...rest} />;
};
