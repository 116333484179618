import * as React from 'react';
import { lazyWithPreload } from 'react-lazy-with-preload';

const IntercomComponent = lazyWithPreload(() => import('./component'));

export const Intercom: React.FC = () => (
  <React.Suspense fallback={null}>
    <IntercomComponent />
  </React.Suspense>
);
