import styled from '@emotion/styled';
import { fonts } from 'folio-common-components';
import { invariant } from 'folio-common-utils';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import {
  ArigatouIllustration,
  BabySealOfApprovalIllustration,
  FishyIllustration,
  SuperbossIllustration,
} from '../../illustrations';

const NoTaskContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${colors.grayMedium};
  padding: 64px;
  border-radius: 8px;
`;

const NoTaskHeading = styled.div`
  text-align: center;
  margin-top: 16px;
  ${fonts.font400bold};
`;

const NoTaskText = styled.div`
  text-align: center;
  margin-top: 8px;
`;

const NoTasksArigatou = () => (
  <NoTaskContainer>
    <ArigatouIllustration />
    <NoTaskHeading>Oppdrag utført!</NoTaskHeading>
    <NoTaskText>Du er virkelig et prakteksemplar!</NoTaskText>
  </NoTaskContainer>
);

const NoTasksHappyDays = () => (
  <NoTaskContainer>
    <BabySealOfApprovalIllustration />
    <NoTaskHeading>Tomt!</NoTaskHeading>
    <NoTaskText>
      Her er det ingenting å gjøre.
      <br />
      For en luksus!
    </NoTaskText>
  </NoTaskContainer>
);

const NoTasksHappySplash = () => (
  <NoTaskContainer>
    <FishyIllustration />
    <NoTaskHeading>Halleluja!</NoTaskHeading>
    <NoTaskText>Du har gjort det du trenger å gjøre.</NoTaskText>
  </NoTaskContainer>
);

const NoTasksLikeABoss = () => (
  <NoTaskContainer>
    <SuperbossIllustration />
    <NoTaskHeading>Frihet!</NoTaskHeading>
    <NoTaskText>
      Sleng beina på bordet.
      <br />
      Jobben er gjort!
    </NoTaskText>
  </NoTaskContainer>
);

const illustrations = [
  NoTasksArigatou,
  NoTasksHappyDays,
  NoTasksHappySplash,
  NoTasksLikeABoss,
];

const numberOfAvailableIllustrations = illustrations.length;

export const InboxZero: React.FC = () => {
  const [illustrationNumber] = React.useState(() =>
    Math.floor(Math.random() * numberOfAvailableIllustrations),
  );
  const Illustration = illustrations[illustrationNumber];

  invariant(Illustration);

  return <Illustration />;
};
