import * as React from 'react';

export const ProgressCircle: React.FC<{
  size: number;
  progress: number;
  color: string;
  accessibilityLabel?: string;
}> = props => {
  const { accessibilityLabel, color, size } = props;
  const padding = 2.5;

  const radius = size / 2 - padding;
  const progress = Math.max(0, Math.min(props.progress, 1));
  const circumference = Math.PI * (radius * 2);
  const dashLength = circumference / 32;
  const isDone = progress === 1;

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      role={accessibilityLabel ? 'img' : undefined}
      aria-label={accessibilityLabel}
    >
      <circle
        r={radius}
        cx={radius + padding}
        cy={radius + padding}
        strokeWidth={3}
        stroke={color}
        fill={isDone ? color : 'none'}
        strokeDasharray={isDone ? undefined : dashLength}
        strokeDashoffset={isDone ? undefined : dashLength / 2} // Turn the dash back to 12 o'clock
      />
    </svg>
  );
};
