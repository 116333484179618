export function toSafePrecision(n: number): string {
  // Prevent rounding errors by limiting the number to 15 digits
  // (max stored in 64 bit float)
  const precise = n.toPrecision(15);

  return precise.replace(/\.?0+$/, '');
}

export function parseNumber(str: string): number {
  const cleaned = str.replace(/\s/g, '').replace(',', '.');
  // Disallow E notation ('1e3')
  if (!/^[+-\d.]*$/.test(cleaned)) {
    return Number.NaN;
  }
  return Number(cleaned);
}
