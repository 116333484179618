import { css } from '@emotion/react';
import { useBatchedQuery } from 'bank-common-client';
import { Button, VisuallyHidden, fonts, space } from 'folio-common-components';
import { formatters } from 'folio-common-utils';
import * as React from 'react';
import { LedgerCategoriesDocument } from '../../components/shared/queries.generated';
import { useEventFilters } from '../../event-filter-context';
import { InboxZero } from './InboxZero';
import { PagingControls } from './PagingControls';
import { TransactionFilter } from './TransactionFilter';
import { LoadingTransactionList, TransactionList } from './TransactionList';
import {
  type EventsByFilterConfiguration,
  amountMatchesSearchTerm,
  groupByCategory,
  groupByStatus,
} from './filter-utils';
import type { TransactionPageDataQuery } from './queries.generated';
import { eventsSorter } from './sorter';
import type { EventItemOrSalaryPaymentList } from './types';

type Props = {
  data: TransactionPageDataQuery | undefined;
  loading: boolean;
  selectedPerson: string;
  pageSize: number;
  onSelectPerson: (account: string) => void;
  onSelectPageSize: (pageSize: number) => void;
  onSelectPreviousPage: () => void;
  onSelectNextPage: () => void;
  onSelectLoadMore: () => void;
  usesDateFilter: boolean;
};

export const TransactionsView: React.FC<Props> = ({
  data,
  selectedPerson,
  onSelectPerson,
  pageSize,
  onSelectLoadMore,
  usesDateFilter,
}) => {
  const {
    eventFilters: filters,
    setEventFilters,
    usesDefaultFilters,
    usesDefaultFiltersIgnoringStatus,
  } = useEventFilters();

  const [filterVisible, setFilterVisible] = React.useState(
    !usesDefaultFiltersIgnoringStatus,
  );

  // Prefetch categories
  useBatchedQuery(LedgerCategoriesDocument);

  React.useEffect(() => {
    if (filters.person !== selectedPerson) {
      onSelectPerson(filters.person);
    }
  }, [onSelectPerson, selectedPerson, filters.person]);

  const items = data?.events.items;
  const salaryItems = data?.events.salaryItems;

  const events = React.useMemo<EventItemOrSalaryPaymentList>(() => {
    if (!items || !salaryItems) {
      return [];
    }

    return [...items, ...salaryItems].sort(eventsSorter);
  }, [items, salaryItems]);

  // TODO: Pass the filters to the GraphQL call instead of just filtering in
  // the current list, and make sure to debounce the text inputs by like 2 seconds
  const lowerTextFilter = filters.text.toLowerCase();
  const eventsFilteredByText =
    lowerTextFilter === ''
      ? events
      : events.filter(event => {
          if (
            event.amount &&
            amountMatchesSearchTerm(Number(event.amount), lowerTextFilter)
          ) {
            return true;
          }

          if (event.__typename === 'SalaryPayment') {
            // should we do more?
            return (
              lowerTextFilter.includes('lønn') ||
              lowerTextFilter.includes('lonn')
            );
          }

          return (
            event.description.toLowerCase().includes(lowerTextFilter) ||
            event.note?.text.toLowerCase().includes(lowerTextFilter) ||
            event.purpose?.text.toLowerCase().includes(lowerTextFilter) ||
            event.participants?.text.toLowerCase().includes(lowerTextFilter) ||
            event.ledgerCategoryInfo.category?.title
              .toLowerCase()
              .includes(lowerTextFilter)
          );
        });
  const usedSubcategories = Array.from(
    new Set(
      events
        .map(event => event.ledgerCategoryInfo.category)
        // TODO should the logic for defaulting to "Ukjent" be here?
        .map(category => (category == null ? 'Ukjent' : category.title)),
    ),
  ).sort();
  const eventsByStatus = groupByStatus(eventsFilteredByText);
  const eventsByCategory = groupByCategory(
    eventsFilteredByText,
    usedSubcategories,
  );
  const filteredEventsByStatus = groupByStatus(
    data ? eventsByCategory[filters.category] || [] : [],
  );
  const filteredEventsByCategory = groupByCategory(
    eventsByStatus[filters.status],
    usedSubcategories,
  );
  const eventsByFilterConfiguration: EventsByFilterConfiguration = {
    category: filteredEventsByCategory,
    status: filteredEventsByStatus,
  };
  // Both filters applied
  const filteredEvents = filteredEventsByStatus[filters.status];

  const showingIncomplete = filters.status === 'incomplete';

  // Save a list of incomplete events, so that we can keep showing them when
  // their status changes to "done". Without this, they would just disappear
  // from view, which is confusing.
  const [initialIncompleteFids, setInitialIncompleteFids] = React.useState<
    readonly string[] | null
  >(null);
  React.useEffect(() => {
    if (!data) {
      return;
    }

    if (showingIncomplete && initialIncompleteFids == null) {
      const fids = eventsByStatus.incomplete.map(event => event.fid);
      setInitialIncompleteFids(fids);
    } else if (!showingIncomplete && initialIncompleteFids != null) {
      setInitialIncompleteFids(null);
    }
  }, [eventsByStatus, initialIncompleteFids, data, showingIncomplete]);

  let eventsToShow = Array.from(filteredEvents);
  // Re-add the events that have recently been completed.
  if (initialIncompleteFids) {
    const filteredEventsByCategory = eventsByCategory[filters.category];

    if (filteredEventsByCategory) {
      let pushed = false;
      for (const fid of initialIncompleteFids) {
        const isInFilteredEvents = filteredEventsByCategory.some(
          event => fid === event.fid,
        );
        if (isInFilteredEvents) {
          const exists = eventsToShow.some(event => fid === event.fid);
          if (!exists) {
            const event = events.find(event => event.fid === fid);
            if (event) {
              pushed = true;
              eventsToShow.push(event);
            }
          }
        }
      }

      if (pushed) {
        eventsToShow = eventsToShow.sort(eventsSorter);
      }
    }
  }

  const showInboxZero =
    usesDefaultFiltersIgnoringStatus &&
    !usesDateFilter &&
    showingIncomplete &&
    filteredEvents.length === 0 &&
    (initialIncompleteFids?.length ?? 0) === 0;
  const totalCount = data?.events.totalCount ?? 0;

  return (
    <div
      css={css`
        /* Create a stacking context */
        position: relative;
        z-index: 0;
        ${space([32, 48], 'margin-top')};
      `}
    >
      <VisuallyHidden>
        <h2>Bevegelser</h2>
      </VisuallyHidden>
      <TransactionFilter
        toggleFilterVisibility={() =>
          setFilterVisible(filterVisible => !filterVisible)
        }
        loading={!data}
        hidden={!filterVisible}
        usedSubcategories={usedSubcategories}
        filters={filters}
        eventsByFilterConfiguration={eventsByFilterConfiguration}
        onChange={({ filter, value }) => setEventFilters({ [filter]: value })}
      />
      {!data ? (
        <LoadingTransactionList count={pageSize} />
      ) : (
        <>
          {usesDefaultFilters && !usesDateFilter && totalCount === 0 ? (
            <p
              css={css`
                ${space([48, 48, 104], 'margin-top')};
                text-align: center;
                color: var(--muted-color);
              `}
            >
              Her vil bevegelser på kort og konto vises. Sett inn penger for å
              begynne!
            </p>
          ) : showInboxZero ? (
            <InboxZero />
          ) : (
            <TransactionList events={eventsToShow} />
          )}

          {usesDefaultFilters && !usesDateFilter ? (
            <PagingControls
              hasNext={data.events.pageInfo.hasNextPage}
              onLoadMoreClick={onSelectLoadMore}
            />
          ) : showInboxZero ? null : (
            <FilterStatus
              eventsLength={events.length}
              hasSearchHits={eventsToShow.length > 0}
              hasNext={data.events.pageInfo.hasNextPage}
              onLoadMoreClick={onSelectLoadMore}
              // should use `events.at(-1)` at some point
              lastEventDateTime={events[events.length - 1]?.dateTime}
            />
          )}
        </>
      )}
    </div>
  );
};

const FilterStatus: React.FC<{
  eventsLength: number;
  hasSearchHits: boolean;
  hasNext: boolean;
  onLoadMoreClick: () => void;
  lastEventDateTime: string | undefined;
}> = ({
  eventsLength,
  hasSearchHits,
  hasNext,
  onLoadMoreClick,
  lastEventDateTime,
}) => {
  if (hasSearchHits) {
    if (hasNext) {
      return (
        <div
          data-testid="filter-status"
          css={css`
            text-align: center;
          `}
        >
          <p>
            Obs! Vi søkte kun gjennom dine{' '}
            {formatters.formatAmount(eventsLength)} siste transaksjoner
            {lastEventDateTime
              ? `, tilbake til ${formatters.formatDate(
                  lastEventDateTime,
                  'do MMMM yyyy',
                )}`
              : ''}
            .
          </p>
          <Button onClick={onLoadMoreClick}>Let lenger tilbake</Button>
        </div>
      );
    }
  } else {
    return (
      <div
        data-testid="filter-status"
        css={css`
          ${space([48, 48, 104], 'margin-top')};
          text-align: center;
        `}
      >
        {hasNext ? (
          <>
            <h3
              css={css`
                ${fonts.font300demi};
                margin: 0;
              `}
            >
              Fant ingenting av nyere dato
            </h3>
            <p>
              Vi har søkt gjennom dine {formatters.formatAmount(eventsLength)}{' '}
              siste transaksjoner
              {lastEventDateTime
                ? `, tilbake til ${formatters.formatDate(
                    lastEventDateTime,
                    'do MMMM yyyy',
                  )}`
                : ''}
              .
            </p>
            <Button onClick={onLoadMoreClick}>Let lenger tilbake</Button>
          </>
        ) : (
          <>
            <h3
              css={css`
                ${fonts.font300demi};
                margin: 0;
              `}
            >
              Finnes ikke
            </h3>
            <p>Ingen bevegelser passer med søket ditt.</p>
          </>
        )}
      </div>
    );
  }

  return null;
};
