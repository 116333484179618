import * as Sentry from '@sentry/react';
import { useBatchedQuery } from 'bank-common-client';
import * as React from 'react';
import { createContainer } from 'unstated-next';
import { GetIntercomConfigDocument } from './components/intercom/queries.generated';

function useIntercomControls() {
  const [isBooted, setIsBooted] = React.useState(false);
  const chatIFrameRef = React.useRef<HTMLIFrameElement>();
  const { data } = useBatchedQuery(GetIntercomConfigDocument);

  React.useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      Sentry.addBreadcrumb({
        message: 'Received message event',
        data: { event },
      });

      let message;
      try {
        message = JSON.parse(event.data);
      } catch {
        // Probably couldn't parse JSON because the event is
        // from a different source; ignore it.
        return;
      }

      Sentry.addBreadcrumb({
        message: 'Received message',
        data: { message },
      });

      if (message.source !== 'folio-chat') {
        return;
      }

      switch (message.kind) {
        case 'ready': {
          setIsBooted(true);
          return;
        }

        case 'received-unsupported-message': {
          Sentry.captureMessage(
            'Folio chat received unsupported message',
            'warning',
          );
          return;
        }
      }
    };

    window.addEventListener('message', onMessage);

    return () => window.removeEventListener('message', onMessage);
  }, []);

  const setIFrameRef = React.useCallback((iFrame: HTMLIFrameElement) => {
    chatIFrameRef.current = iFrame;
  }, []);

  const isReady = data != null && isBooted && chatIFrameRef.current != null;

  Sentry.addBreadcrumb({
    message: 'Sentry readiness',
    data: { isBooted, isReady },
  });

  const show = React.useCallback(() => {
    if (!isReady) {
      return;
    }

    chatIFrameRef.current?.contentWindow?.postMessage(
      JSON.stringify({ source: 'folio', kind: 'show' }),
      data.intercomConfig.intercomUrl,
    );
  }, [data, isReady]);

  const shutdown = React.useCallback(() => {
    if (!isReady) {
      return;
    }

    chatIFrameRef.current?.contentWindow?.postMessage(
      JSON.stringify({ source: 'folio', kind: 'shutdown' }),
      data.intercomConfig.intercomUrl,
    );
  }, [data, isReady]);

  return React.useMemo(
    () => ({
      isReady,
      setIFrameRef,
      show,
      shutdown,
    }),
    [isReady, setIFrameRef, show, shutdown],
  );
}

const { Provider, useContainer } = createContainer(useIntercomControls);
export {
  Provider as IntercomControlsProvider,
  useContainer as useIntercomControls,
};
