import { continueUrlParam } from 'bank-common';
import type { FirstPaintNoOrgQuery } from '../common-queries.generated';

type GqlOrg = FirstPaintNoOrgQuery['organizations']['availableOrgs'][number];

export type Org = Omit<GqlOrg, '__typename'>;
export type Orgs = readonly Org[];

function createLoginUrl(org: Org, path: string) {
  const { name, state } = org;
  const continueUrl = new URL(path, window.location.origin).href;
  const search = new URLSearchParams({
    [continueUrlParam]: continueUrl,
    orgNavn: name,
  }).toString();

  const basePath = state === 'Onboarding' ? '/bli-kunde/bankid' : '/logg-inn';

  return `${basePath}?${search}`;
}

export function useOrgSwitchUrl(org: Org, deeplinkPath?: string) {
  const { orgNum, state, isInSession } = org;

  const path =
    state === 'Onboarding'
      ? `/bli-kunde/${orgNum}`
      : state === 'NewFounding'
      ? `/stiftelse/${orgNum}`
      : state === 'Archived'
      ? `/org/${orgNum}/regnskap`
      : `/org/${orgNum}${deeplinkPath ?? ''}`;

  if (isInSession) {
    return path;
  }

  return createLoginUrl(org, path);
}
