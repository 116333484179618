import {
  ApolloClient,
  ApolloLink,
  type DocumentNode,
  split,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { type HttpOptions, createHttpLink } from '@apollo/client/link/http';
import { trace } from '@opentelemetry/api';
import * as Sentry from '@sentry/react';
import { createUploadLink } from 'apollo-upload-client';
import {
  batchHttpLinkBatchKey,
  createInMemoryCache,
  fetchWithOperationNames,
  setGlobalError,
} from 'bank-common-client';
import {
  folioReleaseHeader,
  notEmpty,
  parseOrgNum,
  traceIdHeader,
} from 'folio-common-utils';
import { idGenerator } from 'folio-tracing-web';
import { type GraphQLError, print } from 'graphql';
import {
  FirstPaintDocument,
  FirstPaintNoOrgDocument,
  FirstPaintNoSessionDocument,
} from './common-queries.generated';

const release: string | undefined =
  // @ts-expect-error: COMMIT_SHA is injected by webpack
  'NO_COMMIT' !== COMMIT_SHA ? COMMIT_SHA : undefined;

function hasUnauthenticatedError(errors?: readonly GraphQLError[]) {
  return (errors || [])
    .map(e => e.extensions)
    .filter(notEmpty)
    .some(e => e.code === 'UNAUTHENTICATED');
}

const httpLinkOptions: HttpOptions = {
  uri: '/graphql',
  credentials: 'same-origin',
  headers: {
    Accept: 'application/json',
    ...(release ? { [folioReleaseHeader]: release } : {}),
  },
  fetch: fetchWithOperationNames,
};

const tracingLink = setContext((req, { headers }) => {
  const span = trace.getActiveSpan();
  const traceId = span?.spanContext().traceId ?? idGenerator.generateTraceId();

  Sentry.configureScope(scope => {
    scope.setTag('trace-id', traceId);
  });

  const { operationName, query, variables } = req;

  const printedQuery = print(query);

  if (span) {
    span.setAttribute('query', printedQuery);
    if (operationName) {
      span.setAttribute('operationName', operationName);
    }
  }

  Sentry.addBreadcrumb({
    category: 'graphql-request',
    type: 'http',
    data: { query: printedQuery, operationName, variables },
  });

  return { headers: { ...headers, [traceIdHeader]: traceId } };
});

const orgNumberLink = setContext((req, { headers, backofficeCallForOrg }) => {
  if (backofficeCallForOrg) {
    Sentry.addBreadcrumb({
      category: 'graphql-request',
      type: 'http',
      message: 'Org number set from backoffice context',
    });

    return {
      headers: { ...headers, 'Folio-Org-Number': backofficeCallForOrg },
    };
  }

  const orgNumber = parseOrgNum(window.location.href);

  if (!orgNumber) {
    return headers;
  }

  Sentry.addBreadcrumb({
    category: 'graphql-request',
    type: 'http',
    message: 'Org number set from url',
  });

  return { headers: { ...headers, 'Folio-Org-Number': orgNumber } };
});

export function createClient() {
  const cache = createInMemoryCache();

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([
      tracingLink,
      orgNumberLink,
      onError(({ operation, graphQLErrors, networkError }) => {
        Sentry.withScope(scope => {
          scope.setLevel('error');
          scope.addBreadcrumb({
            message: 'Got gql error',
            type: 'http',
            data: { operationName: operation.operationName },
          });

          if (graphQLErrors) {
            scope.setExtra('error-type', 'gql error');
            graphQLErrors.forEach(graphQLError => {
              Sentry.captureException(graphQLError);
            });
          }

          if (networkError) {
            scope.setExtra('error-type', 'network error');
            Sentry.captureException(networkError);
          }

          if (hasUnauthenticatedError(graphQLErrors)) {
            Sentry.captureMessage('User is not authenticated or authorized');
            setGlobalError('NotAuthenticated');
          }
        });
      }),
      split(
        operation => {
          const context = operation.getContext();

          return context.noBatch === true || context.isUpload === true;
        },
        split(
          operation => {
            const context = operation.getContext();

            return context.isUpload === true;
          },
          createUploadLink({
            ...httpLinkOptions,
            headers: {
              ...httpLinkOptions.headers,
              'Apollo-Require-Preflight': 'true',
            },
          }),
          createHttpLink(httpLinkOptions),
        ),
        new BatchHttpLink({
          ...httpLinkOptions,
          batchKey: batchHttpLinkBatchKey,
        }),
      ),
    ]),
    assumeImmutableResults: true,
    cache,
  });

  function writeDefaultCacheData() {
    Sentry.addBreadcrumb({
      message: 'Attempting to write first paint data to apollo cache',
    });
    writeFirstPaintDataIntoCache(apolloClient, FirstPaintDocument, 'with-org');
    writeFirstPaintDataIntoCache(
      apolloClient,
      FirstPaintNoOrgDocument,
      'without-org',
    );
    writeFirstPaintDataIntoCache(
      apolloClient,
      FirstPaintNoSessionDocument,
      'unauthenticated',
    );
  }

  writeDefaultCacheData();

  // eslint-disable-next-line require-await
  apolloClient.onResetStore(async () => writeDefaultCacheData());

  return apolloClient;
}

function writeFirstPaintDataIntoCache(
  client: ApolloClient<unknown>,
  query: DocumentNode,
  dataTag: string,
) {
  const firstPaintDataEle = document.querySelector(
    `[data-first-paint-${dataTag}-graphql-cache]`,
  );
  if (firstPaintDataEle?.textContent) {
    Sentry.addBreadcrumb({
      message: 'First paint data found',
      data: { dataTag },
    });
    try {
      const cacheData = JSON.parse(firstPaintDataEle.textContent);

      client.writeQuery({ query, ...cacheData });
      // read after writing to ensure it was successful
      client.readQuery({ query });

      Sentry.addBreadcrumb({
        message: 'First paint data successfully',
        data: { dataTag },
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}
