import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { getMovementIconColors } from 'bank-common-client';
import { fonts, space } from 'folio-common-components';
import { formatters } from 'folio-common-utils';
import * as React from 'react';
import { ProgressCircle } from '../../components/ProgressCircle';
import {
  SkeletonLine,
  skeletonAnimation,
  skeletonBackground,
} from '../../components/SkeletonLine';
import { getLedgerIcon } from '../../components/ledger-icon';
import {
  type CompleteLabelStatus,
  bookkeepingLabels,
} from '../../components/shared/bookkeeping';
import type { MovementType } from '../../gqltypes';
import {
  ArrowsRightLeftIcon,
  CardIcon,
  DotsMoreIcon,
  MoneyBagCleanIcon,
  PercentIcon,
  UnknownCleanIcon,
} from '../../icons';
import { textOverflow } from '../../styles/text-overflow';
import type { EventItem } from './types';

const Row = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  ${space([16], 'padding', 'gap')};
`;

const DescriptionLine = styled.div<{ expanded?: boolean }>`
  margin: 0;
  ${({ expanded }) => (expanded ? null : textOverflow)};
  ${fonts.font200medium};
`;

const InfoLine = styled.div<{ expanded?: boolean }>`
  margin: 0;
  ${({ expanded }) => (expanded ? null : textOverflow)};
  color: var(--muted-color);
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  amount: number;
  currencyAmount?: number | null;
  currencyCode?: string | null;
  paymentFee?: string | null;
  expanded: boolean;
  description?: string;
  name?: string | null;
  movementType?: MovementType;
  category: EventItem['ledgerCategoryInfo']['category'] | null;
  progressStatus: CompleteLabelStatus;
  accountingSystemInformation: NonNullable<
    EventItem['payment']
  >['accountingSystemInformation'];
} & React.HTMLAttributes<HTMLDivElement>;

const movementTypeIcons: Partial<Record<MovementType, React.ReactNode>> = {
  Transfer: <ArrowsRightLeftIcon />,
  TransferToSavings: <MoneyBagCleanIcon />,
  TransferFromSavings: <MoneyBagCleanIcon />,
  TransferToCard: <CardIcon />,
  TransferToCardAutofill: <CardIcon />,
  TransferFromCard: <CardIcon />,
  TransferToTaxAccount: <PercentIcon />,
};

function getIcon(
  movementType: Props['movementType'],
  category: Props['category'],
  hasAccountingInfo: boolean,
) {
  if (movementType) {
    const byMovementType = movementTypeIcons[movementType];

    if (byMovementType) {
      return byMovementType;
    }
  }

  if (!category) {
    if (hasAccountingInfo) {
      return <DotsMoreIcon />;
    }
    return <UnknownCleanIcon />;
  }

  if (category.icon) {
    return getLedgerIcon(category.icon);
  }

  return undefined;
}

// TODO: just pass in the transaction?
export const EventSummaryRow: React.FC<Props> = ({
  amount,
  expanded,
  description,
  movementType,
  category,
  progressStatus,
  currencyAmount,
  currencyCode,
  paymentFee,
  name,
  accountingSystemInformation,
}) => {
  const color = getMovementIconColors(movementType);

  let groupedFormattedCurrencyAmount; // e.g. -USD 100 000,10
  let formattedCurrencyAmount; // e.g. -USD 100000,10
  if (
    currencyAmount &&
    Number(currencyAmount) !== 0 &&
    currencyCode !== 'NOK' &&
    currencyCode != null
  ) {
    groupedFormattedCurrencyAmount = formatters.formatCurrency(
      currencyCode,
      Number(currencyAmount),
    );
    formattedCurrencyAmount = formatters.formatCurrency(
      currencyCode,
      Number(currencyAmount),
      { useGrouping: false },
    );
    if (paymentFee) {
      formattedCurrencyAmount += ' + kostnad';
      groupedFormattedCurrencyAmount += ' + kostnad';
    }
  }

  const hasAccountingInfo = accountingSystemInformation != null;

  const icon = getIcon(movementType, category, hasAccountingInfo);

  const isComplete =
    progressStatus === 'complete' ||
    progressStatus === 'overridden' ||
    hasAccountingInfo;
  const label = bookkeepingLabels[progressStatus];

  return (
    <SummaryRowTemplate
      icon={
        <CategoryIcon
          accessibilityLabel={label}
          icon={icon}
          progress={isComplete ? 1 : 0}
          color={isComplete ? color.complete : color.incomplete}
        />
      }
      line1={description}
      line2={name}
      amount={
        <>
          <div
            css={css`
              ${fonts.font200medium}
            `}
            aria-label={
              formatters.formatCurrency('NOK', Number(amount), {
                useGrouping: false,
              }) || undefined
            }
          >
            {formatters.formatAmount(amount, {
              showPositiveSign:
                movementType !== 'Transfer' &&
                movementType !== 'TransferFromSavings' &&
                movementType !== 'TransferToSavings' &&
                movementType !== 'TransferToCard' &&
                movementType !== 'TransferToCardAutofill' &&
                movementType !== 'TransferToTaxAccount' &&
                movementType !== 'TransferFromCard',
              withoutFraction:
                (amount === 0 || amount >= 1 || amount <= -1) && !expanded,
            })}
          </div>
          {expanded && groupedFormattedCurrencyAmount && (
            <div
              css={css`
                color: var(--muted-color);
              `}
              aria-label={formattedCurrencyAmount ?? undefined}
            >
              {groupedFormattedCurrencyAmount}
            </div>
          )}
        </>
      }
      expanded={expanded}
    />
  );
};

const iconSize = 48;

export const SkeletonEventSummaryRow: React.FC<{
  index: number;
}> = props => {
  const animationDelay = css`
    animation-delay: ${(props.index % 10) / 10}s;
  `;

  return (
    <SummaryRowTemplate
      icon={
        <div
          css={css`
            /* The progress circle SVG has padding, so compensate with 1px on each side */
            width: ${iconSize - 2}px;
            height: ${iconSize - 2}px;
            margin: 1px;
            border-radius: 50%;
            ${skeletonBackground};
            ${skeletonAnimation};
            ${animationDelay};
          `}
        />
      }
      line1={<SkeletonLine width="50%" maxWidth="200px" css={animationDelay} />}
      line2={<SkeletonLine width="30%" maxWidth="150px" css={animationDelay} />}
      amount={
        <SkeletonLine width="40px" maxWidth="100%" css={animationDelay} />
      }
      expanded={false}
    />
  );
};

export const CategoryIcon: React.FC<{
  icon: React.ReactNode;
  color: string;
  progress: number;
  accessibilityLabel?: string;
}> = props => {
  return (
    <div
      css={css`
        width: ${iconSize}px;
        height: ${iconSize}px;
        position: relative;
      `}
    >
      <ProgressCircle
        size={iconSize}
        progress={props.progress}
        color={props.color}
        accessibilityLabel={props.accessibilityLabel}
      />
      <IconWrapper>{props.icon}</IconWrapper>
    </div>
  );
};

const SummaryRowTemplate: React.FC<{
  icon: React.ReactNode;
  line1: React.ReactNode;
  line2: React.ReactNode;
  amount: React.ReactNode;
  expanded: boolean;
}> = props => {
  const { icon, line1, line2, amount, expanded } = props;
  return (
    <Row>
      <div
        css={css`
          overflow: hidden;
        `}
      >
        <DescriptionLine expanded={expanded}>{line1}</DescriptionLine>
        <InfoLine expanded={expanded}>{line2}</InfoLine>
      </div>
      <div
        css={css`
          text-align: right;
        `}
      >
        {amount}
      </div>
      <div
        css={css`
          order: -1;
        `}
      >
        {icon}
      </div>
    </Row>
  );
};
