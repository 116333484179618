import * as React from 'react';
import { createContainer } from 'unstated-next';

function useDragAndDrop() {
  const [currentlyUploadingNumber, setCurrentlyUploadingNumber] =
    React.useState(0);

  return React.useMemo(() => {
    return { currentlyUploadingNumber, setCurrentlyUploadingNumber };
  }, [currentlyUploadingNumber]);
}

const { Provider, useContainer } = createContainer(useDragAndDrop);

export { Provider as DragAndDropProvider, useContainer as useDragAndDrop };
