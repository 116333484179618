import { css } from '@emotion/react';
import { colors } from 'folio-design-tokens';

export const dropTargetStyle = css`
  &::after {
    content: '';
    pointer-events: none;
    border-radius: 10px; /* 8 + 2 because of negative inset */
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
  }
`;

export const dropTargetOverStyle = css`
  ${dropTargetStyle};

  &::after {
    border: 2px dashed ${colors.blue};
  }
`;
