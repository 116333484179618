import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PlainButton, space } from 'folio-common-components';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import type { AvatarId } from '../../gqltypes';
import { LockupHorisontallyLogo } from '../../logos';
import { pages } from '../../pages';
import { touchFeedback } from '../../styles/touch-feedback';
import { HeaderBar } from '../MinimalHeaderBar/header-bar';
import { OverlayMenu, type RenderFun, zIndex } from '../OverlayMenu';
import { Page } from '../Page';
import { NavAvatarBox } from '../nav-avatar-box';
import { OrgLink } from '../org-navigation';
import { SkipLink } from '../skip-link';

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
  ${space([4], 'padding-vertical')};
`;

interface Props {
  name: string;
  avatarId: AvatarId;
  orgName?: string;
  menuContent: RenderFun;
}

export const LargeHeaderBar: React.FC<Props> = props => {
  const [isOpen, setIsOpen] = React.useState(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const { name, orgName, avatarId, menuContent } = props;

  const closeMenu = React.useCallback(() => setIsOpen(false), []);

  return (
    <HeaderBar data-testid="large-header-bar">
      <SkipLink />
      <Page>
        <Grid>
          <OrgLink
            to={pages.transactions.getUrl()}
            aria-label="Folio"
            css={css`
              /* Align logo and menu */
              ${space([0, 16], 'margin-left')};
              ${touchFeedback};
            `}
          >
            <LockupHorisontallyLogo
              css={css`
                display: block;
              `}
            />
          </OrgLink>
          <div
            css={css`
              position: relative;
            `}
          >
            <PlainButton
              data-testid="nav-menu-button"
              onClick={() => setIsOpen(!isOpen)}
              ref={buttonRef}
              aria-label="Meny"
              aria-expanded={isOpen}
              css={css`
                display: block;
                border-radius: 8px;
                ${space([4], 'padding-vertical', 'padding-right')};
                ${space([16], 'padding-left')};
                position: relative;
                z-index: ${zIndex + 1};

                @media (hover) {
                  :hover {
                    background-color: ${colors.grayAiry};
                  }
                }

                ${touchFeedback};

                @media (prefers-contrast: more) {
                  box-shadow: 0 0 0 1px ${colors.black};
                }
              `}
            >
              <NavAvatarBox name={name} avatarId={avatarId} orgName={orgName} />
            </PlainButton>
            <OverlayMenu
              position="right"
              data-testid="nav-menu-body"
              isOpen={isOpen}
              openButton={buttonRef}
              onClose={closeMenu}
              showCloseButton={false}
              css={css`
                top: auto;
                margin-top: 12px;
                margin-bottom: 16px;
                min-width: 425px; /* magic size from figma */
                padding: 0;
              `}
              menuContent={menuContent}
            />
          </div>
        </Grid>
      </Page>
    </HeaderBar>
  );
};
