// Currently founding IDs are "<prefix>-{10 chars}". Leaving some room in both directions
export const fikenFoundingRegex = /^f-[a-zA-Z0-9]{8,}$/;
export const fikenDseFoundingRegex = /^fd-[a-zA-Z0-9]{8,}$/;
export const betaFoundingRegex = /^b-[a-zA-Z0-9]{8,}$/;
export const tripletexFoundingRegex = /^t-[a-zA-Z0-9]{8,}$/;
export const ecitDseFoundingRegex = /^ed-[a-zA-Z0-9]{8,}$/;

export function isFikenFounding(val: string): boolean {
  return fikenFoundingRegex.test(val) || fikenDseFoundingRegex.test(val);
}

export function isBetaFounding(val: string): boolean {
  return betaFoundingRegex.test(val);
}

export function isTripletexFounding(val: string): boolean {
  return tripletexFoundingRegex.test(val);
}

function isEcitDseFounding(val: string): boolean {
  return ecitDseFoundingRegex.test(val);
}

export function isDseFounding(foundingId: string): boolean {
  return (
    fikenDseFoundingRegex.test(foundingId) ||
    isBetaFounding(foundingId) ||
    isTripletexFounding(foundingId) ||
    isEcitDseFounding(foundingId)
  );
}
