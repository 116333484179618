import { css } from '@emotion/react';
import { shadows } from 'folio-common-components';
import { colors } from 'folio-design-tokens';

export const cardStyle = css`
  box-shadow: ${shadows.smallShadow};
  background: #fff;
  border-radius: 8px;
  overflow: hidden;

  @media (prefers-contrast: more) {
    box-shadow: 0 0 0 1px ${colors.black};
  }
`;
