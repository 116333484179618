import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { invariantValue } from 'folio-common-utils';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { Label } from '../Label';
import {
  type MessageKinds,
  type Size,
  dynamicStyles,
  textLikeInput,
  textLikeInputError,
  useTextfieldMessage,
} from '../text-field';

export interface SelectOptionType {
  value: string | number | undefined; // TODO: Add string[] when supporting multiple select
  label: string;
  disabled?: boolean;
}

type NativeSelectProps = Pick<
  JSX.IntrinsicElements['select'],
  'id' | 'tabIndex' | 'placeholder' | 'autoComplete' | 'className' | 'disabled'
>;

export interface SelectProps<ExtendedOptionType = SelectOptionType>
  extends NativeSelectProps {
  options: readonly ExtendedOptionType[];
  selectedOption: ExtendedOptionType;
  isOptionDisabled?: (option: ExtendedOptionType) => boolean;
  onChange: (selectedOption: ExtendedOptionType) => void;
  icon?: React.ReactNode;
  size?: Size;
  label?: string;
  message?: MessageKinds;
}

interface OptionProps extends SelectOptionType {
  disabled: boolean;
}

const Option: React.FC<OptionProps> = ({ value, label, disabled }) => (
  <option
    // Windows styles the menu with the background color of the select, which is pink on focus
    css={css`
      background-color: white;
    `}
    value={value}
    disabled={disabled}
  >
    {label}
  </option>
);

const Wrapper = styled.span<{ inputDisabled: boolean }>`
  position: relative;
  display: inline-block;
  color: ${({ inputDisabled }) => (inputDisabled ? colors.wcagGray : null)};
`;

const iconStyles = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  pointer-events: none;
`;

const IconWrapper = styled.div<{ selectSize: Size }>`
  ${iconStyles};
  top: 0;
  height: 100%;
  width: 28px;
  left: ${({ selectSize: widgetSize }) =>
    widgetSize === 'medium' ? '16px' : '24px'};
`;

const ArrowWrapper = styled.span<{ selectSize: Size; selectDisabled: boolean }>`
  ${iconStyles};
  top: 2px;
  right: 2px;
  bottom: 2px;
  padding-left: ${({ selectSize: widgetSize }) =>
    widgetSize === 'medium' ? '16px' : '24px'};
  padding-right: ${({ selectSize: widgetSize }) =>
    widgetSize === 'medium' ? '16px' : '24px'};
  border-radius: 6px;
`;

const SelectInput = styled.select<{ hasIcon: boolean; widgetSize: Size }>`
  ${textLikeInput};
  ${({ widgetSize, hasIcon }) => dynamicStyles(widgetSize, hasIcon)};

  width: 100%; /* Fill up container to stretch correctly */
  letter-spacing: 0.5px;
  padding-right: ${({ widgetSize }) =>
    widgetSize === 'large' ? '61px' : '45px'};

  /* Remove the dotted outline around the value in Firefox */
  color: ${colors.black};

  /* Remove the arrow indicator in Firefox */
  appearance: none;
`;

export function Select<ExtendedOptionType extends SelectOptionType>(
  props: SelectProps<ExtendedOptionType>,
) {
  const {
    options,
    selectedOption,
    isOptionDisabled,
    disabled,
    onChange,
    icon,
    className,
    label,
    size = 'medium',
    ...rest
  } = props;

  let id = React.useId();
  if (rest.id) {
    id = rest.id;
  }

  const { hasError, inputProps, messageEle } = useTextfieldMessage(props);

  return (
    <>
      {label ? <Label htmlFor={id}>{label}</Label> : null}
      <Wrapper className={className} inputDisabled={disabled || false}>
        <SelectInput
          {...rest}
          id={id}
          disabled={disabled}
          value={selectedOption.value}
          hasIcon={icon != null}
          css={hasError && textLikeInputError}
          onChange={e => {
            const selectedValue = e.target.value;
            const selectedOption = options.find(
              // TODO: This will be unsafe when we support multiple select
              option => String(option.value) === selectedValue,
            );
            onChange(invariantValue(selectedOption));
          }}
          widgetSize={size}
          {...inputProps}
        >
          {options.map((option, index) => {
            let disabled: boolean;

            // Let option.disabled override isOptionDisabled(option)
            if (option.disabled != null) {
              disabled = option.disabled;
            } else {
              if (isOptionDisabled == null) {
                disabled = false;
              } else {
                disabled = isOptionDisabled(option);
              }
            }

            return <Option key={index} {...option} disabled={disabled} />;
          })}
        </SelectInput>
        {icon && <IconWrapper selectSize={size}>{icon}</IconWrapper>}
        <ArrowWrapper selectSize={size} selectDisabled={disabled || false}>
          <svg
            width={13}
            height={8}
            viewBox="0 0 13 8"
            fill="none"
            aria-hidden="true"
          >
            <path
              d="m1.5 1.5 5.0012 5.0012L11.5024 1.5"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </ArrowWrapper>
      </Wrapper>
      {messageEle}
    </>
  );
}
