import editDistance from 'damerau-levenshtein';

export function removeWhitespace(s: string) {
  return s.replace(/\s/g, '');
}

export function removeNonDigits(s: string) {
  return s.replace(/\D/g, '');
}

const emailProviders = [
  'gmail.com',
  'hotmail.com',
  'hotmail.no',
  'outlook.com',
  'live.com',
  'live.no',
  'online.no',
  'icloud.com',
  'yahoo.com',
  'yahoo.no',
  'fastmail.com',
  'protonmail.com',
];

export function findLikelyEmailTypo(
  email: string,
): { domain: string; email: string } | null {
  const [user, domain] = email.toLowerCase().split('@');
  if (domain) {
    const candidates = emailProviders
      .map(provider => {
        const distance = editDistance(domain, provider).steps;
        return { distance, domain: provider };
      })
      .filter(cand => cand.distance < 3)
      .sort((a, b) => a.distance - b.distance);
    const [topCandidate] = candidates;
    if (topCandidate && !topCandidate.domain.startsWith(domain)) {
      return {
        domain: topCandidate.domain,
        email: `${user}@${topCandidate.domain}`,
      };
    }
  }
  return null;
}

export function isObject(thing: unknown): thing is Record<string, unknown> {
  return typeof thing === 'object' && thing != null;
}

/**
 * Return the sum of all numbers in a list.
 */
export function listSum(nums: readonly number[]) {
  return nums.reduce((p, c) => p + c, 0);
}

export function waitReject<T>(value: T, timeout: number): Promise<T> {
  // eslint-disable-next-line promise/param-names
  return new Promise((_, reject) => setTimeout(reject, timeout, value));
}

export function waitResolve<T>(value: T, timeout: number): Promise<T> {
  return new Promise(resolve => setTimeout(resolve, timeout, value));
}

/**
 * Return a list of all the unique items from the `list` array. The identity
 * of each list item is determined by the `getter` callback.
 */
export function uniqueBy<T, G extends string | number>(
  getter: (item: T) => G,
  list: readonly T[],
): readonly T[] {
  const ret = new Set<T>();
  const seen = new Set<G>();
  for (const e of list) {
    const val = getter(e);
    if (!seen.has(val)) {
      ret.add(e);
      seen.add(val);
    }
  }
  return Array.from(ret);
}

export function flatten<T>(items: readonly (readonly T[])[]): readonly T[] {
  return items.reduce((p, c) => [...p, ...c], []);
}

export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

// https://github.com/Microsoft/TypeScript/issues/24509#issuecomment-471937030
export type Mutable<T> = {
  -readonly [P in keyof T]: T[P] extends readonly (infer U)[]
    ? Mutable<U>[]
    : Mutable<T[P]>;
};

export function groupByMap<T, U>(
  items: readonly T[],
  keyFun: (item: T) => U,
): Map<U, readonly [T, ...T[]]> {
  const acc = new Map<U, [T, ...T[]]>();
  items.forEach(item => {
    const key = keyFun(item);
    const previous = acc.get(key);
    if (previous) {
      previous.push(item);
    } else {
      acc.set(key, [item]);
    }
  });

  return acc;
}

/**
 * Creates an array of `[key, items[]]` tuples by calling `keyFun` for each item
 * in the input `items` array.
 */
export function groupBy<T, U>(
  items: readonly T[],
  keyFun: (item: T) => U,
): readonly [U, readonly [T, ...T[]]][] {
  return Array.from(groupByMap(items, keyFun));
}

export type Partition<Truthy, Falsy> = readonly [
  truthy: readonly Truthy[],
  falsy: readonly Falsy[],
];

/**
 * Split `items` into a tuple of two arrays. The first containing all items
 * for which `predicate` was true, the other containing the rest of the items.
 * The predicate can be typescript type guard function, which will make the
 * truthy part of the tuple be typed with the correct output type.
 */
export function partition<T, Y extends T>(
  items: readonly T[],
  predicate: (item: T) => item is Y,
): Partition<Y, Exclude<T, Y>>;
export function partition<T>(
  items: readonly T[],
  predicate: (item: T) => boolean,
): Partition<T, T>;
export function partition<T>(
  items: readonly T[],
  predicate: (item: T) => boolean,
): Partition<unknown, unknown> {
  const a = [];
  const b = [];
  for (const item of items) {
    if (predicate(item)) {
      a.push(item);
    } else {
      b.push(item);
    }
  }
  return [a, b];
}

export function pick<T, K extends keyof T>(items: T, keys: K[]): Pick<T, K> {
  const ret: any = {};
  for (const key of keys) {
    ret[key] = items[key];
  }
  return ret;
}

/**
 * Put the items of an array into an array of chunks, where each chunk is
 * an array of length `size`.
 * @example
 * chunk([1, 2, 3, 4, 5], 2);
 * // Returns [[1, 2], [3, 4], [5]]
 */
export function chunk<T>(items: readonly T[], size = 20): readonly T[][] {
  const ret: T[][] = [];
  for (let index = 0; index < items.length; index += size) {
    ret.push(items.slice(index, index + size));
  }
  return ret;
}
