import {
  Button,
  FileUploadArea,
  TextButton,
  VisuallyHidden,
} from 'folio-common-components';
import { isArrayOfAtLeastOne } from 'folio-common-utils';
import * as React from 'react';
import { useUploadFiles } from '../../hooks/use-upload-files';
import { PlusIcon } from '../../icons';

interface Props {
  eventFid: string | undefined;
  buttonType: 'primary' | 'secondary' | 'text';
  handleReceiptUploadStart: () => void;
  handleReceiptUploadError: () => void;
  handleReceiptUploadSuccess: (incomingReceiptFids: readonly string[]) => void;
}

export const allowedContentTypes = [
  'image/jpeg',
  'image/png',
  'application/pdf',
];

export const Uploader: React.FC<Props> = props => {
  const fileUploadRef = React.useRef<{ openFilePicker: () => void }>(null);
  const {
    eventFid,
    buttonType,
    handleReceiptUploadStart,
    handleReceiptUploadSuccess,
    handleReceiptUploadError,
  } = props;

  const uploadFiles = useUploadFiles(eventFid);

  const upload = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (files) {
        const filesToUpload = Array.from(files).filter(file =>
          allowedContentTypes.includes(file.type),
        );

        if (filesToUpload.length === 0) {
          return;
        }

        handleReceiptUploadStart();
        try {
          const uploadedFiles = await uploadFiles(filesToUpload);

          if (isArrayOfAtLeastOne(uploadedFiles)) {
            handleReceiptUploadSuccess(uploadedFiles);
          }

          // not all files were uploaded
          if (uploadedFiles.length < filesToUpload.length) {
            handleReceiptUploadError();
          }
        } catch {
          handleReceiptUploadError();
        }
      }
    },
    [
      handleReceiptUploadError,
      handleReceiptUploadStart,
      handleReceiptUploadSuccess,
      uploadFiles,
    ],
  );

  return (
    <>
      {buttonType === 'text' ? (
        <TextButton
          aria-label="Legg til flere kvitteringer"
          onClick={() => fileUploadRef.current?.openFilePicker()}
        >
          Legg til fler
        </TextButton>
      ) : (
        <Button
          aria-label="Legg til kvittering"
          onClick={() => fileUploadRef.current?.openFilePicker()}
          level={buttonType}
          icon={<PlusIcon />}
        >
          Legg til
        </Button>
      )}
      <VisuallyHidden>
        <form onSubmit={evt => evt.preventDefault()}>
          <FileUploadArea
            ref={fileUploadRef}
            tabIndex={-1}
            aria-hidden="true"
            name="receipt"
            accept={allowedContentTypes.join(',')}
            multiple={true}
            onChange={upload}
            title="Legg til kvittering eller faktura"
          >
            {() => <span />}
          </FileUploadArea>
        </form>
      </VisuallyHidden>
    </>
  );
};
