import { css } from '@emotion/react';
import { fonts } from 'folio-common-components';
import { colors } from 'folio-design-tokens';

export const stickyHeader = css`
  position: -webkit-sticky;
  position: sticky;
  top: -1px; /* In some browsers/devices, top: 0 sometimes leave a gap, so cover it with -1px */
  background: ${colors.background};
  z-index: 1;
  padding: 10px;
  margin: 0 -10px 6px; /* -10px hides the shadow of the transaction items when this is above them. 6px avoids clipping the top part of the shadow */
  ${fonts.font300medium}
`;
