import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PlainButton, fonts, mq, space } from 'folio-common-components';
import * as React from 'react';
import lazyWithPreload from 'react-lazy-with-preload';
import { useMediaLayout } from 'use-media';
import { useHasCapability } from '../../hooks/use-capability-check';
import { MenuLinesIcon } from '../../icons';
import { LockupHorisontallyLogo, LogoIconLogo } from '../../logos';
import { pages } from '../../pages';
import { touchFeedback } from '../../styles/touch-feedback';
import { HeaderBar as BaseHeaderBar } from '../MinimalHeaderBar/header-bar';
import { OverlayMenu, type RenderFun } from '../OverlayMenu';
import { OrgLink } from '../org-navigation';
import { SkipLink } from '../skip-link';
import type { Props } from './payments-badge';

const HeaderBar = styled(BaseHeaderBar)`
  ${space([16], 'padding')};
  ${space([16], 'gap')};
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 28px auto 28px;

  @media ${mq.medium} {
    grid-template-columns: 99px auto 99px;
  }
`;

const PaymentsBadgeComponent = lazyWithPreload(
  () => import('./payments-badge'),
);

const PaymentsBadge: React.FC<Props> = props => (
  <React.Suspense fallback={null}>
    <PaymentsBadgeComponent {...props} />
  </React.Suspense>
);

export const SmallHeaderBar: React.FC<{
  orgName?: string;
  menuContent: RenderFun;
}> = props => {
  const { orgName, menuContent } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const isWide = useMediaLayout(mq.medium);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const closeMenu = React.useCallback(() => setIsOpen(false), []);
  const canListPayments = useHasCapability('CanListPayments');

  return (
    <HeaderBar data-testid="small-header-bar">
      <SkipLink />
      <OrgLink
        to={pages.transactions.getUrl()}
        css={css`
          display: block;
          ${touchFeedback};
        `}
        aria-label="Folio"
      >
        {isWide ? (
          <LockupHorisontallyLogo
            css={css`
              display: block;
            `}
          />
        ) : (
          <LogoIconLogo
            css={css`
              display: block;
            `}
          />
        )}
      </OrgLink>
      <PlainButton
        onClick={() => setIsOpen(!isOpen)}
        css={css`
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          ${fonts.font200medium};
          ${touchFeedback};
        `}
      >
        {orgName}
      </PlainButton>
      <div
        css={css`
          display: flex;
        `}
      >
        <PlainButton
          data-testid="nav-menu-button"
          css={css`
            margin-left: auto;
            padding: 6px;
            position: relative;
            ${touchFeedback};
            touch-action: none; /* support tap and slide to menu items */
          `}
          onClick={event => {
            setIsOpen(!isOpen);
            event.stopPropagation();
          }}
          onPointerDown={event => {
            if (event.button !== 0) {
              return;
            }

            if (event.target instanceof Element) {
              event.target.releasePointerCapture(event.pointerId);
            }
            setIsOpen(!isOpen);
            event.stopPropagation();
          }}
          ref={buttonRef}
          aria-label="Meny"
          aria-expanded={isOpen}
        >
          <MenuLinesIcon
            css={css`
              display: block;
            `}
          />
          {canListPayments ? <PaymentsBadge menuOpen={isOpen} /> : null}
        </PlainButton>
      </div>
      <OverlayMenu
        position="right"
        data-testid="nav-menu-body"
        isOpen={isOpen}
        openButton={buttonRef}
        onClose={closeMenu}
        menuContent={menuContent}
        css={css`
          min-width: 250px;
          padding: 0;
          margin: 8px;
          user-select: none;

          @media ${mq.medium} {
            max-width: 425px; /* magic size from figma */
            left: auto;
            /* Align with the menu open button */
            right: 6px;
          }
        `}
      />
    </HeaderBar>
  );
};
