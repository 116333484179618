import { css } from '@emotion/react';

// :first-child is unsafe for SSR, so just target some common elements
export const noChildVerticalMargins = css`
  & > p:first-of-type,
  & > ul:last-of-type,
  & > ol:last-of-type,
  & > h1:first-of-type,
  & > h2:first-of-type,
  & > h3:first-of-type,
  & > h4:first-of-type,
  & > h5:first-of-type,
  & > h6:first-of-type {
    margin-top: 0;
  }

  & > p:last-of-type,
  & > ul:last-of-type,
  & > ol:last-of-type,
  & > h1:last-of-type,
  & > h2:last-of-type,
  & > h3:last-of-type,
  & > h4:last-of-type,
  & > h5:last-of-type,
  & > h6:last-of-type {
    margin-bottom: 0;
  }
`;
