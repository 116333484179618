import * as React from 'react';

type Props = {
  allowedContentTypes: string[];
  handleDragEnter: (event: React.DragEvent) => void;
  handleDragLeave: (event: React.DragEvent) => void;
  handleDrop: (
    event: React.DragEvent,
    dropAllowed: boolean,
  ) => void | undefined | Promise<void | undefined>;
};

export function useDropTarget({
  allowedContentTypes,
  handleDragEnter,
  handleDragLeave,
  handleDrop,
}: Props) {
  // Increments when dragging enters the target element or a child element.
  // Decrements when dragging leaves the target element or a child element.
  // When this is 1, the drag has entered the target element.
  // When this is 0, the drag has left the target element.
  // Method stolen from https://stackoverflow.com/a/10906204
  const stackLength = React.useRef(0);
  const dropIsAllowed = React.useRef(true);

  return React.useMemo(() => {
    function isDropAllowed(dataTransfer: DataTransfer) {
      if (!dataTransfer.items) {
        return true;
      }

      // dataTransfer.items is empty in Safari while dragging
      if (dataTransfer.items.length === 0) {
        return dataTransfer.types.includes('Files');
      }

      return Array.from(dataTransfer.items).some(item =>
        allowedContentTypes.includes(item.type),
      );
    }

    function onDragEnter(event: React.DragEvent) {
      event.preventDefault();

      stackLength.current++;
      dropIsAllowed.current = isDropAllowed(event.dataTransfer);
      if (!dropIsAllowed.current) {
        return;
      }

      if (stackLength.current === 1) {
        handleDragEnter(event);
      }
    }

    function onDragLeave(event: React.DragEvent) {
      event.preventDefault();

      stackLength.current--;
      if (stackLength.current === 0) {
        handleDragLeave(event);
      }
    }

    function onDragOver(event: React.DragEvent) {
      event.preventDefault();
      event.dataTransfer.dropEffect = dropIsAllowed.current ? 'copy' : 'none';
    }

    function onDrop(event: React.DragEvent) {
      event.preventDefault();

      stackLength.current = 0;

      // We need to run isDropAllowed() again here, since dataTransfer.items is
      // only populated on drop in Safari.
      dropIsAllowed.current = isDropAllowed(event.dataTransfer);

      return handleDrop(event, dropIsAllowed.current);
    }

    return { onDragEnter, onDragLeave, onDragOver, onDrop };
  }, [allowedContentTypes, handleDragEnter, handleDragLeave, handleDrop]);
}
