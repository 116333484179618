import { useFirstPaintData } from '../hooks/use-first-paint-data';
import {
  BroadcastBoxHeading,
  BroadcastBoxWrapper,
  BroadcastWrapper,
} from './Broadcast';
import { ChatButton } from './chat-button';

export const OverdueInvoiceReminder: React.FC = () => {
  const { data } = useFirstPaintData();

  const overdueInvoiceCount =
    data?.organization.subscriptionInformation?.overdueInvoiceCount;

  if (overdueInvoiceCount != null && overdueInvoiceCount > 0) {
    return (
      <BroadcastBoxWrapper>
        <BroadcastWrapper severity="Error">
          <BroadcastBoxHeading>
            Du har ikke betalt for Folio
          </BroadcastBoxHeading>
          Det var ikke dekning på drifts&shy;konto. Overfør penger, eller{' '}
          <ChatButton>spør oss</ChatButton> hvis noe ikke stemmer.
        </BroadcastWrapper>
      </BroadcastBoxWrapper>
    );
  }

  return null;
};
