import * as Sentry from '@sentry/react';
import * as React from 'react';
import { allowedContentTypes } from '../components/bookkeeping/uploader';
import { useDragAndDrop } from './drag-and-drop-context';
import { useDropTarget } from './use-drop-target';
import { useUploadFiles } from './use-upload-files';

type Props = {
  eventFid?: string;
  handleDrop?: (event: React.DragEvent) => void;
  handleSuccess?: (receiptFids: readonly string[]) => void;
  handleError?: (error: unknown) => void;
};

export function useReceiptDropTarget({
  eventFid,
  handleDrop,
  handleSuccess,
  handleError,
}: Props) {
  const uploadFiles = useUploadFiles(eventFid);
  const [isDraggedOver, setIsDraggedOver] = React.useState(false);
  const { setCurrentlyUploadingNumber } = useDragAndDrop();

  const eventListeners = useDropTarget(
    React.useMemo(
      () => ({
        allowedContentTypes,
        handleDragEnter() {
          setIsDraggedOver(true);
        },
        handleDragLeave() {
          setIsDraggedOver(false);
        },
        async handleDrop(event, dropAllowed) {
          setIsDraggedOver(false);

          if (dropAllowed) {
            handleDrop?.(event);
            const files = Array.from(event.dataTransfer.files).filter(file =>
              allowedContentTypes.includes(file.type),
            );

            if (files.length === 0) {
              return;
            }

            try {
              setCurrentlyUploadingNumber(files.length);
              const receiptFids = await uploadFiles(files);
              handleSuccess?.(receiptFids);
            } catch (error) {
              if (handleError) {
                handleError(error);
              } else {
                Sentry.addBreadcrumb({
                  message: 'Missing error handler when dropping image',
                });
                Sentry.captureException(error);
              }
            } finally {
              setCurrentlyUploadingNumber(0);
            }
          }
        },
      }),
      [
        handleDrop,
        handleError,
        handleSuccess,
        setCurrentlyUploadingNumber,
        uploadFiles,
      ],
    ),
  );

  return React.useMemo(
    () => ({ isDraggedOver, eventListeners }),
    [eventListeners, isDraggedOver],
  );
}
