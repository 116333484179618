import { differenceInYears } from 'date-fns';
import { invariant } from './invariant';
import { isValidPnum } from './norwegian-pnum';
import { removeWhitespace } from './utils';

export function format(str: string) {
  return str.replace(
    /(\d{0,6})(\s)?(\d{0,5})/,
    (_, birthDate: string, space = '', personalNumber: string) => {
      if (personalNumber !== '') {
        space = ' ';
      }

      return `${birthDate}${space}${personalNumber}`;
    },
  );
}

export function isAtLeast18(pNum: string) {
  try {
    const birthDate = pNumToIsoDate(pNum);
    return differenceInYears(new Date(), new Date(birthDate)) >= 18;
  } catch {
    return false;
  }
}

// https://www.skatteetaten.no/person/folkeregister/fodsel-og-navnevalg/barn-fodt-i-norge/fodselsnummer/
export function pNumToIsoDate(pNum: string) {
  invariant(isValidPnum(pNum), 'Personal number is not valid');

  const normalized = removeWhitespace(pNum);
  const individualNumber = Number(normalized.slice(6, 9));
  let year = Number(normalized.slice(4, 6));
  const month = normalized.slice(2, 4);
  let day = Number(normalized.slice(0, 2));

  // D-nummer support
  if (day > 40) {
    day -= 40;
  }

  // ported from https://github.com/folio-as/monorepo/blob/43f68f67d20928c0a5fb608fd1d50f8ef1b22be6/go/internal/signing/daemon/model/template.go#L112-L120
  if (individualNumber < 500 || (year > 39 && individualNumber >= 900)) {
    year += 1900;
  } else if (year > 54 && individualNumber < 750) {
    year += 1800;
  } else if (year < 40) {
    year += 2000;
  } else {
    throw new Error("Couldn't calculate year for pNum");
  }

  return `${year}-${month}-${String(day).padStart(2, '0')}`;
}
