import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { autofillConstants } from 'bank-common';
import * as React from 'react';
import { StopAutofillDocument } from '../../common-client-queries.generated';
import type { AutofillAccount } from '../../routes/profile/card-autofill-admin';

const { desiredAmountAsNumber } = autofillConstants;

export function useDisableAutofillIfChangeBelow(
  account: AutofillAccount,
  newAccountBalance: number,
  skipStoppingAutofill: boolean,
) {
  const { autofill, accountNumber } = account;
  const [stopAutofillMutation, { called: hasStoppedAutofill }] = useMutation(
    StopAutofillDocument,
    { variables: { accountNumber } },
  );
  const [hasCompleted, setHasCompleted] = React.useState(
    skipStoppingAutofill || !autofill,
  );

  const oldAutofillAmount = React.useRef(
    autofill ? Number(autofill.desiredAmount) : desiredAmountAsNumber,
  );

  const newBalanceBelowAutofillLimit =
    newAccountBalance < oldAutofillAmount.current;

  React.useEffect(() => {
    if (!autofill || hasStoppedAutofill || skipStoppingAutofill) {
      return;
    }
    if (newBalanceBelowAutofillLimit) {
      stopAutofillMutation()
        .then(() => {
          setHasCompleted(true);
        })
        .catch(() => {
          // ignore
        });
    }
  }, [
    autofill,
    hasStoppedAutofill,
    skipStoppingAutofill,
    newAccountBalance,
    newBalanceBelowAutofillLimit,
    stopAutofillMutation,
  ]);

  return { hasCompleted, newBalanceBelowAutofillLimit };
}

export const LoadingScreen = styled.div`
  position: absolute;
  background: #fff;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  align-content: center;
  justify-content: center;
`;
