type MessageType = string | (() => string);

class InvariantError extends Error {
  name = 'InvariantError';

  constructor(message = 'Expected value to be truthy') {
    super(message);
  }
}

export function invariant(
  condition: unknown,
  message?: MessageType,
): asserts condition {
  if (!condition) {
    const msg = typeof message === 'function' ? message() : message;

    throw new InvariantError(msg);
  }
}

/**
 * Useful for inline or chained assertion calls
 */
export function invariantValue<T>(
  value: T | null | undefined,
  message?: MessageType,
): T {
  invariant(value, message);

  return value;
}

// allows to define a Tuple of N length
// copied from https://stackoverflow.com/a/52490977/1850276
type Tuple<T, N extends number> = N extends N
  ? number extends N
    ? readonly T[]
    : _TupleOf<T, N, readonly []>
  : never;
type _TupleOf<
  T,
  N extends number,
  R extends readonly unknown[],
> = R['length'] extends N ? R : _TupleOf<T, N, readonly [T, ...R]>;

export function isArrayOfLength<T, Length extends number>(
  array: readonly T[],
  length: Length,
): array is Tuple<T, Length> {
  return array.length === length;
}

export function assertArrayOfLength<T, Length extends number>(
  array: readonly T[],
  length: Length,
  message: MessageType = `Expected array to be of length ${length}, but was ${array.length}`,
): asserts array is Tuple<T, Length> {
  invariant(isArrayOfLength(array, length), message);
}

export function isArrayOfAtLeastOne<T>(
  array: readonly T[],
): array is readonly [T, ...T[]] {
  return array.length >= 1;
}

export function assertArrayOfAtLeastOne<T>(
  array: readonly T[],
  message: MessageType = 'Expected array to have at least one entry, but it did not',
): asserts array is readonly [T, ...T[]] {
  invariant(isArrayOfAtLeastOne(array), message);
}

/**
 * Use this in a `default` block in a switch statement to assert that every
 * possible case in the switch has been handled. If they are not, there will be
 * a compile-time error:
 *
 * ```
 * switch (userType) {
 *   case "admin":
 *    return true;
 *   default:
 *    assertUnreachable(userType);
 * }
 * ```
 */
export function assertUnreachable(_x: never): never {
  throw new Error('This code should not be reachable');
}
