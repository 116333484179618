import type {
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
} from '@apollo/client';
import { useUnbatchedQuery } from 'bank-common-client';
import * as React from 'react';

function isDocumentVisible() {
  // handle being rendered in node
  if (typeof document === 'undefined') {
    return false;
  }

  return document.visibilityState === 'visible';
}

export function useDocumentIsVisible() {
  const [isVisible, setIsVisible] = React.useState(isDocumentVisible());

  React.useEffect(() => {
    // handle being rendered in node
    if (typeof window === 'undefined') {
      return;
    }

    function handleChange() {
      setIsVisible(isDocumentVisible());
    }

    window.addEventListener('visibilitychange', handleChange);

    return () => {
      window.removeEventListener('visibilitychange', handleChange);
    };
  }, []);

  return isVisible;
}

export function usePollQueryIfDocumentIsVisible<
  Query,
  Variables extends OperationVariables,
>(
  query: TypedDocumentNode<Query, Variables>,
  interval: number,
  options?: QueryHookOptions<Query, Variables>,
): QueryResult<Query, Variables> {
  const queryResult = useUnbatchedQuery(query, options);
  const { startPolling, stopPolling, refetch } = queryResult;
  const documentIsVisible = useDocumentIsVisible();
  const isFirstMount = React.useRef(true);

  React.useEffect(() => {
    if (!documentIsVisible) {
      return;
    }

    if (isFirstMount.current) {
      isFirstMount.current = false;
    } else {
      // refetch when document becomes visible, after first mount
      refetch();
    }

    startPolling(interval);

    return () => {
      stopPolling();
    };
  }, [documentIsVisible, interval, refetch, startPolling, stopPolling]);

  return queryResult;
}
