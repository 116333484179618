import { isValid, parse } from 'date-fns';
import { luhn } from './luhn';
import { mod11 } from './mod11';
import { removeWhitespace } from './utils';

export function isValidName(name: string) {
  return /.+\s.+/.test(name);
}

export function isValidAddress(address: string) {
  return address !== '';
}

export function isValidMobileNumber(mobileNumber: string) {
  const num = removeWhitespace(mobileNumber);

  // Blocked numbers
  // https://nkom.no/telefoni-og-telefonnummer/telefonnummer-og-den-norske-nummerplan/alle-nummerserier-for-norske-telefonnumre/den-norske-nummerplanen-for-telefoni-med-mer-e.164?s=Blokkert
  if (
    num.startsWith('42') ||
    num.startsWith('43') ||
    num.startsWith('44') ||
    num.startsWith('4536') ||
    num.startsWith('49')
  ) {
    return false;
  }

  return /^[49]\d{7}$/.test(num);
}

export function isValidPostalCode(postalCode: string) {
  return /^\d{4}$/.test(postalCode);
}

// https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export function isValidEmail(email: string) {
  email = email.trim();

  if (!emailRegex.test(email)) {
    return false;
  }

  // Check that there is a TLD and that it's more than one character
  // since there are no registered one-letter TLDs
  // http://data.iana.org/TLD/tlds-alpha-by-domain.txt
  const [_, domain] = email.split('@');
  return (
    domain.includes('.') && domain.slice(domain.lastIndexOf('.') + 1).length > 1
  );
}

/**
 * Validate a date in DDMMYY format
 */
export function isValidBirthDate(birthDate: string) {
  return isValid(parse(birthDate, 'ddMMyy', new Date()));
}

export { isValidAccountNumber } from './account-number';
export { isValidPnum } from './norwegian-pnum';
export { isValidDnum } from './norwegian-pnum';
export { isValidOrgNumber } from './org-number';
export { validateIban } from './iban';

const validKidRe = /^\d{3,25}|\d{2,24}-$/;

/**
 * https://www.nets.eu/no-nb/nyheter/Pages/KID-nummer-.aspx
 * Only checks if this matches the requirements:
 * - 3 to 25 characters (digits, optionally with an ending '-')
 * - last digit is mod10 or mod11 checksum
 * Thus, we don't know if this is a valid kid for a particular payment contract
 * established with bank / bbs
 */
export function isValidKidNumber(kidNumber: string): boolean {
  const cleaned = removeWhitespace(kidNumber);
  return !validKidRe.test(cleaned) ? false : luhn(cleaned) || mod11(cleaned);
}

interface KidOpts {
  algorithm: 'Mod10' | 'Mod11';
  length: number;
  kidNumber: string;
}

export type KidAlgorithm = KidOpts['algorithm'];

export function isMatchingKidNumber(opts: KidOpts): boolean {
  const { algorithm, kidNumber, length } = opts;
  const cleaned = removeWhitespace(kidNumber);
  if (!validKidRe.test(cleaned)) {
    return false;
  } else if (cleaned.length !== length) {
    return false;
  } else {
    return algorithm === 'Mod10' ? luhn(cleaned) : mod11(cleaned);
  }
}
