import { continueUrlParam } from 'bank-common';
import { DummyButton } from 'folio-common-components';
import * as React from 'react';
import { LonelyLightningIllustration } from '../illustrations';
import { ChatButton } from './chat-button';
import { InfoPage } from './info-page';

export const LoginForSpvClearance: React.FC = () => (
  <InfoPage
    illustration={<LonelyLightningIllustration />}
    heading="Ups, BankID snublet!"
    body={
      <div>
        <p>
          Du trenger nok bare prøve igjen.
          <br />
          Sitter du fast her? <ChatButton>Vi kan hjelpe</ChatButton>.
        </p>
        <LoginInAgainButton />
      </div>
    }
  />
);

export const LoginInAgainButton: React.FC = () => {
  const continueUrl = new URL('/logg-inn', window.location.href);
  continueUrl.searchParams.set(continueUrlParam, window.location.href);

  return (
    <a href={continueUrl.href}>
      <DummyButton>Logg inn på nytt</DummyButton>
    </a>
  );
};
