import { css } from '@emotion/react';
import { fonts, mq, space } from 'folio-common-components';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { useMediaLayout } from 'use-media';
import { FishyIllustration } from '../../illustrations';

const PauseFishParagraph = () => (
  <p>
    Det tar en liten stund før kontoen din er aktiv og Folio virker. Du hører
    fra oss når alt er klart! Om du liker at ting skjer fort, kan du{' '}
    <a
      css={css`
        color: inherit;
      `}
      href="https://folio.no/app"
    >
      laste ned appen vår
    </a>{' '}
    allerede nå.
  </p>
);

export const PauseFish: React.FC = () => {
  const isBig = useMediaLayout(mq.medium);

  return (
    <div
      css={css`
        background-color: ${colors.blueLight};
        border-radius: 8px;
        ${space([32, 32, 40], 'padding')};
        display: grid;
        grid-template-columns: 1fr auto;
      `}
    >
      {isBig ? (
        <>
          <div
            css={css`
              ${space([40, 64, 64], 'padding-right')};
            `}
          >
            <h1
              css={css`
                ${fonts.font500bold};
                margin: 0;
              `}
            >
              Her er en pausefisk!
            </h1>
            <PauseFishParagraph />
          </div>
          <div
            css={css`
              align-self: center;
            `}
          >
            <FishyIllustration />
          </div>
        </>
      ) : (
        <div>
          <div
            css={css`
              text-align: center;
            `}
          >
            <FishyIllustration />
          </div>
          <h1
            css={css`
              ${fonts.font500bold};
              margin: 0;
            `}
          >
            Her er en pausefisk!
          </h1>
          <PauseFishParagraph />
        </div>
      )}
    </div>
  );
};
