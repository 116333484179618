import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ProgressSpinner, space } from 'folio-common-components';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { cardStyle } from '../../../components/card-style';
import type { SigningState } from './types';

export const OnboardingStepsCard = styled.div`
  ${cardStyle};
  ${space([16, 48], 'padding')};
`;

// TODO: this is copied from org onboarding
export const InProgressView: React.FC = () => (
  <div
    css={css`
      display: flex;
      justify-content: center;
    `}
    data-testid="onboarding-step-container"
  >
    <div>
      <div
        css={css`
          width: 48px;
          margin: auto;
        `}
      >
        <ProgressSpinner size={48} />
      </div>
      <p>Takk for at du venter!</p>
    </div>
  </div>
);

const errorCardStyles = css`
  border-radius: 8px;
  ${space([16], 'padding')};
  ${space([16], 'margin-bottom')};
  background-color: ${colors.redLight};
`;

const ErrorCard: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div css={errorCardStyles} data-testid="onboarding-state-screen-error-card">
    {children}
  </div>
);

export const SigningError: React.FC<{
  signingState: SigningState | undefined;
}> = ({ signingState }) => {
  if (signingState === 'errored' || signingState === 'aborted') {
    return (
      <ErrorCard>
        Oisann! Du fikk ikke gjort ferdig signeringen. Trykk «Til signering» for
        å prøve igjen.
      </ErrorCard>
    );
  }

  return null;
};
