import { css } from '@emotion/react';
import { Button } from 'folio-common-components';
import * as React from 'react';

interface Props {
  hasNext: boolean;
  onLoadMoreClick: () => void;
}

export const PagingControls: React.FC<Props> = ({
  hasNext,
  onLoadMoreClick,
}) => (
  <div
    css={css`
      text-align: center;
    `}
  >
    {hasNext && <Button onClick={onLoadMoreClick}>Last alle</Button>}
  </div>
);
