import * as React from 'react';
import { useOrgPathAbsoluteUrl } from '../components/org-navigation';
import { useHasFeatureToggle } from './use-capability-check';

const browserSupportsEventSource = globalThis.EventSource != null;

export function useChangedEvents(callback: (event: readonly string[]) => void) {
  const urlWithOrgPath = useOrgPathAbsoluteUrl();
  const url = urlWithOrgPath('changed-events');
  const isEnabled = useHasFeatureToggle('changed-events-push');

  React.useEffect(() => {
    if (!isEnabled || !browserSupportsEventSource) {
      return;
    }

    const eventSource = new EventSource(url);

    function listener(event: MessageEvent) {
      try {
        const eventFids = JSON.parse(event.data);
        callback(eventFids);
      } catch {
        // ignore
      }
    }

    eventSource.addEventListener('message', listener);

    return () => {
      eventSource.removeEventListener('message', listener);
      eventSource.close();
    };
  }, [url, callback, isEnabled]);
}
