export function makeFikenDeepLink(
  path: string,
  orgNum?: string | null,
  isProd = true,
): string {
  if (path.startsWith('/')) {
    throw new Error('The URL must not start with "/"');
  }

  const url = new URL(
    `/til/${path}`,
    isProd ? 'https://fiken.no/' : 'https://ci.fiken.no',
  );
  if (orgNum) {
    url.searchParams.set('orgno', orgNum);
  }
  return url.href;
}
