import { ApolloError } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import {
  accountantInvitationPagesPrefix,
  accountingIntegrationErrorPages,
  backofficePrefix,
  bankPagesPrefix,
  continueUrlParam,
  foundingOnboardingPagesPrefix,
  loginPagesPrefix,
  onboardingPagesPrefix,
  updateInfoPrefix,
} from 'bank-common';
import {
  getFirstBackendError,
  setGlobalError,
  useGlobalError,
} from 'bank-common-client';
import { GlobalStyles, mq, noFocus } from 'folio-common-components';
import { colors, extraColors } from 'folio-design-tokens';
import * as React from 'react';
import { lazyWithPreload } from 'react-lazy-with-preload';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useHref,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useMediaLayout } from 'use-media';
import { ErrorBoundary } from './ErrorBoundary';
import { BroadcastBox } from './components/Broadcast';
import { NotificationBar } from './components/NotificationBar';
import { Page } from './components/Page';
import { TopLevelMenuLarge } from './components/TopLevelMenu';
import { GenericErrorPage } from './components/error-pages';
import { HeaderBar } from './components/header-bar';
import { InfoPage } from './components/info-page';
import { Intercom } from './components/intercom';
import {
  useOrgPathAbsoluteUrl,
  useRestoreFocus,
} from './components/org-navigation';
import { OverdueInvoiceReminder } from './components/overdue-reminder';
import {
  PromotionDisplay,
  useJustSignedPromotion,
} from './components/promotion';
import { skipLinkId } from './components/skip-link';
import { EventFiltersProvider } from './event-filter-context';
import {
  useFirstPaintData,
  useFirstPaintNoOrgData,
} from './hooks/use-first-paint-data';
import { useMenuItems } from './hooks/use-menu-items';
import { useScrollToTop } from './hooks/use-scroll-to-top';
import { useTitle } from './hooks/use-title';
import { ForbiddenIllustration } from './illustrations';
import { IntercomControlsProvider } from './intercom-context';
import { SuspendedPage, pages } from './pages';
import NotFound from './routes/not-found';
import MainPage from './routes/transactions';
import { useThemeColor } from './utils/use-theme-color';

type HideNav = { hideNav: boolean };

const Wrapper = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

const hideNavStyles = ({ hideNav }: HideNav) => {
  if (hideNav) {
    return css`
      grid-template-columns: 1fr;
    `;
  }

  return null;
};

const Content = styled(Page)`
  display: grid;
  grid-template-columns: 240px minmax(500px, 1fr);
  grid-gap: 40px;
  gap: 40px;

  /* Create stacking context */
  position: relative;
  z-index: 0;

  @media (min-width: 1150px) {
    grid-gap: 60px;
    gap: 60px;
  }

  ${hideNavStyles};
`;

const Footer = styled.footer`
  padding-top: 100px;
`;

const MainGrid = styled.main`
  ${noFocus};
`;

const LoginRoot: React.FC = () => {
  useThemeColor(colors.background);

  return (
    <ErrorBoundary>
      <div>
        <NotificationBar />
        <MainGrid>
          <Routes>
            <Route
              path={pages.bankId.mountPoint}
              element={<SuspendedPage page={pages.bankId} />}
            />
            <Route
              path={pages.pickOrg.mountPoint}
              element={<SuspendedPage page={pages.pickOrg} />}
            />
            {/* TODO: Remove at some point */}
            <Route
              path={pages.accountantLogin.mountPoint}
              element={<SuspendedPage page={pages.accountantLogin} />}
            />
            <Route path="*" element={<SuspendedPage page={pages.notFound} />} />
          </Routes>
        </MainGrid>
      </div>
      <Footer>
        <Intercom />
      </Footer>
    </ErrorBoundary>
  );
};

const OrgRoot: React.FC = () => {
  useTitle('');

  const firstPaintData = useFirstPaintData();
  const firstPaintNoOrgData = useFirstPaintNoOrgData();

  const isWide = useMediaLayout(mq.extraLarge);
  const { any: promotionSigned } = useJustSignedPromotion();

  // Org switching works by navigating, so this is retriggered
  const fid = firstPaintData.data?.me.fid;
  React.useEffect(() => {
    if (fid) {
      Sentry.setUser({ id: fid });
    }
  }, [fid]);

  const menuItems = useMenuItems();

  if (firstPaintData.loading || firstPaintNoOrgData.loading) {
    return null;
  }

  if (!firstPaintData.data || !firstPaintNoOrgData.data) {
    if (firstPaintData.error || firstPaintNoOrgData.error) {
      if (
        firstPaintData.error instanceof ApolloError &&
        getFirstBackendError(firstPaintData.error)?.code === 'AccessDenied'
      ) {
        setGlobalError('NotAuthenticated');
        return null;
      }
      return <GenericErrorPage />;
    }

    // throwing an error will hit the error boundary and log it, but still
    // display the error screen
    throw new Error('Missing first paint data, but no error');
  }

  let hideNavFromUser = false;

  if (!firstPaintData.error) {
    const { state, activeHints } = firstPaintData.data.me;
    const { state: orgState } = firstPaintData.data.organization;

    const hasAcceptedSplash = activeHints.every(
      hint => hint.kind !== 'afterFoundingActivation',
    );

    hideNavFromUser =
      promotionSigned ||
      state !== 'Active' ||
      orgState !== 'Active' ||
      !hasAcceptedSplash;
  }

  const hideNavWide = hideNavFromUser || !isWide;
  const showProfileAndSettings =
    firstPaintData.data.organization.state === 'Active';

  return (
    <>
      <HeaderBar
        menuItems={menuItems}
        agent={firstPaintData.data.me}
        organization={firstPaintData.data.organization}
        orgs={firstPaintNoOrgData.data.organizations.availableOrgs}
        showProfileAndSettings={showProfileAndSettings}
      />
      <div>
        <NotificationBar />
        <Content hideNav={hideNavWide}>
          {hideNavWide ? null : (
            <TopLevelMenuLarge
              menuItems={menuItems}
              showProfileAndSettings={showProfileAndSettings}
            />
          )}
          <MainGrid tabIndex={-1} id={skipLinkId}>
            <BroadcastBox scopes={['Generic']} />
            <OverdueInvoiceReminder />
            {firstPaintData.data.me.state === 'Active' || promotionSigned ? (
              <PromotionDisplay
                promotion={firstPaintData.data.me.latestPromotion}
                orgName={firstPaintData.data.organization.name}
              />
            ) : null}
            {promotionSigned ? null : (
              <Routes>
                <Route
                  path={pages.transactions.mountPoint}
                  element={<MainPage />}
                />
                <Route
                  path="bevegelser"
                  element={<Navigate to="../" replace={true} />}
                />
                <Route
                  path={pages.employees.mountPoint}
                  element={<SuspendedPage page={pages.employees} />}
                />
                <Route
                  path={pages.addEmployee.mountPoint}
                  element={<SuspendedPage page={pages.addEmployee} />}
                />
                <Route
                  path={pages.newEmployee.mountPoint}
                  element={<SuspendedPage page={pages.newEmployee} />}
                />
                <Route
                  path={pages.addCoFounder.mountPoint}
                  element={<SuspendedPage page={pages.addCoFounder} />}
                />
                <Route
                  path={pages.employeeCreated.mountPoint}
                  element={<SuspendedPage page={pages.employeeCreated} />}
                />
                <Route
                  path={pages.addAccountant.mountPoint}
                  element={<SuspendedPage page={pages.addAccountant} />}
                />
                <Route
                  path={pages.confirmInvitation.mountPoint}
                  element={<SuspendedPage page={pages.confirmInvitation} />}
                />
                <Route
                  path="kolleger/godkjenn/:fid"
                  element={<ApproveAccountantRedirect />}
                />
                <Route
                  path={pages.invitationDone.mountPoint}
                  element={<SuspendedPage page={pages.invitationDone} />}
                />
                <Route
                  path={pages.invitationRejected.mountPoint}
                  element={<SuspendedPage page={pages.invitationRejected} />}
                />
                <Route
                  path={pages.singleEmployee.mountPoint}
                  element={<SuspendedPage page={pages.singleEmployee} />}
                />
                <Route
                  path={pages.singleEmployeeModified.mountPoint}
                  element={
                    <SuspendedPage page={pages.singleEmployeeModified} />
                  }
                />
                <Route
                  path={pages.payments.mountPoint}
                  element={<SuspendedPage page={pages.payments} />}
                />
                <Route
                  path={pages.approvedPaymentsList.mountPoint}
                  element={<SuspendedPage page={pages.approvedPaymentsList} />}
                />
                <Route
                  path={pages.newPayment.mountPoint}
                  element={<SuspendedPage page={pages.newPayment} />}
                />
                <Route
                  path={pages.paymentDetails.mountPoint}
                  element={<SuspendedPage page={pages.paymentDetails} />}
                />
                <Route
                  path={pages.salaryPaymentDetails.mountPoint}
                  element={<SuspendedPage page={pages.salaryPaymentDetails} />}
                />
                <Route
                  path={pages.editPayment.mountPoint}
                  element={<SuspendedPage page={pages.editPayment} />}
                />
                <Route
                  path={pages.transferToTaxAccount.mountPoint}
                  element={<SuspendedPage page={pages.transferToTaxAccount} />}
                />
                <Route
                  path={pages.chooseCreditor.mountPoint}
                  element={<SuspendedPage page={pages.chooseCreditor} />}
                />
                <Route
                  path={pages.internationalPayments.mountPoint}
                  element={<SuspendedPage page={pages.internationalPayments} />}
                />
                <Route
                  path={pages.salary.mountPoint}
                  element={<SuspendedPage page={pages.salary} />}
                />
                <Route
                  path={pages.accounting.mountPoint}
                  element={<SuspendedPage page={pages.accounting} />}
                />
                <Route
                  path={pages.accountingArchive.mountPoint}
                  element={<SuspendedPage page={pages.accountingArchive} />}
                />
                <Route
                  path={pages.accountingSettings.mountPoint}
                  element={<SuspendedPage page={pages.accountingSettings} />}
                />
                <Route
                  path={pages.accountingFinalizePeriod.mountPoint}
                  element={
                    <SuspendedPage page={pages.accountingFinalizePeriod} />
                  }
                />
                <Route
                  path={pages.tripletex.mountPoint}
                  element={<SuspendedPage page={pages.tripletex} />}
                />
                <Route
                  path={pages.tripletexSetup.mountPoint}
                  element={<SuspendedPage page={pages.tripletexSetup} />}
                />
                <Route
                  path={pages.tripletexManual.mountPoint}
                  element={<SuspendedPage page={pages.tripletexManual} />}
                />
                <Route
                  path={pages.fiken.mountPoint}
                  element={<SuspendedPage page={pages.fiken} />}
                />
                <Route
                  path={pages.fikenSetup.mountPoint}
                  element={<SuspendedPage page={pages.fikenSetup} />}
                />
                <Route
                  path={pages.fikenManual.mountPoint}
                  element={<SuspendedPage page={pages.fikenManual} />}
                />
                <Route
                  path={pages.fikenDirect.mountPoint}
                  element={<SuspendedPage page={pages.fikenDirect} />}
                />
                <Route
                  path={pages.fikenDirectDisconnect.mountPoint}
                  element={<SuspendedPage page={pages.fikenDirectDisconnect} />}
                />
                <Route
                  path={pages.powerOfficeGo.mountPoint}
                  element={<SuspendedPage page={pages.powerOfficeGo} />}
                />
                <Route
                  path={pages.powerOfficeGoSetup.mountPoint}
                  element={<SuspendedPage page={pages.powerOfficeGoSetup} />}
                />
                <Route
                  path={pages.powerOfficeGoManual.mountPoint}
                  element={<SuspendedPage page={pages.powerOfficeGoManual} />}
                />
                <Route
                  path={pages.unimicro.mountPoint}
                  element={<SuspendedPage page={pages.unimicro} />}
                />
                <Route
                  path={pages.unimicroSetup.mountPoint}
                  element={<SuspendedPage page={pages.unimicroSetup} />}
                />
                <Route
                  path={pages.unimicroManual.mountPoint}
                  element={<SuspendedPage page={pages.unimicroManual} />}
                />
                <Route
                  path={pages.vismaEAccounting.mountPoint}
                  element={<SuspendedPage page={pages.vismaEAccounting} />}
                />
                <Route
                  path={pages.vismaEAccountingSetup.mountPoint}
                  element={<SuspendedPage page={pages.vismaEAccountingSetup} />}
                />
                <Route
                  path={pages.vismaEAccountingManual.mountPoint}
                  element={
                    <SuspendedPage page={pages.vismaEAccountingManual} />
                  }
                />
                <Route
                  path={pages.tfso.mountPoint}
                  element={<SuspendedPage page={pages.tfso} />}
                />
                <Route
                  path={pages.tfsoSetup.mountPoint}
                  element={<SuspendedPage page={pages.tfsoSetup} />}
                />
                <Route
                  path={pages.tfsoManual.mountPoint}
                  element={<SuspendedPage page={pages.tfsoManual} />}
                />
                <Route
                  path={pages.accountingSystemSelector.mountPoint}
                  element={
                    <SuspendedPage page={pages.accountingSystemSelector} />
                  }
                />
                <Route
                  path={pages.singleTransaction.mountPoint}
                  element={<SuspendedPage page={pages.singleTransaction} />}
                />
                <Route
                  path={pages.profile.mountPoint}
                  element={<SuspendedPage page={pages.profile} />}
                />
                <Route
                  path={pages.orgSettings.mountPoint}
                  element={<SuspendedPage page={pages.orgSettings} />}
                />
                <Route
                  path={pages.orgSubscriptionInfo.mountPoint}
                  element={<SuspendedPage page={pages.orgSubscriptionInfo} />}
                />
                <Route
                  path={pages.orgSubscriptionInvoice.mountPoint}
                  element={
                    <SuspendedPage page={pages.orgSubscriptionInvoice} />
                  }
                />
                <Route
                  path={pages.orgSubscriptionPreliminaryInvoice.mountPoint}
                  element={
                    <SuspendedPage
                      page={pages.orgSubscriptionPreliminaryInvoice}
                    />
                  }
                />
                <Route
                  path={pages.orderTaxAccount.mountPoint}
                  element={<SuspendedPage page={pages.orderTaxAccount} />}
                />
                <Route
                  path={pages.taxAccountReady.mountPoint}
                  element={<SuspendedPage page={pages.taxAccountReady} />}
                />
                <Route
                  path={pages.orderSavingsAccount.mountPoint}
                  element={<SuspendedPage page={pages.orderSavingsAccount} />}
                />
                <Route
                  path={pages.savingsAccountReady.mountPoint}
                  element={<SuspendedPage page={pages.savingsAccountReady} />}
                />
                <Route
                  path={pages.customerService.mountPoint}
                  element={<SuspendedPage page={pages.customerService} />}
                />
                <Route
                  path={pages.moveMoneyFromSavingsAccount.mountPoint}
                  element={
                    <SuspendedPage page={pages.moveMoneyFromSavingsAccount} />
                  }
                />
                <Route
                  path={pages.moveMoneyToSavingsAccount.mountPoint}
                  element={
                    <SuspendedPage page={pages.moveMoneyToSavingsAccount} />
                  }
                />
                <Route
                  path={pages.earmarks.mountPoint}
                  element={<SuspendedPage page={pages.earmarks} />}
                />
                <Route
                  path={pages.transferFromEarmark.mountPoint}
                  element={<SuspendedPage page={pages.transferFromEarmark} />}
                />
                <Route
                  path={pages.transferToEarmark.mountPoint}
                  element={<SuspendedPage page={pages.transferToEarmark} />}
                />
                <Route
                  path="*"
                  element={<SuspendedPage page={pages.notFound} />}
                />
              </Routes>
            )}
          </MainGrid>
        </Content>
      </div>
      <Footer>
        <Intercom />
      </Footer>
    </>
  );
};

const OnboardingRoot = lazyWithPreload(() => import('./onboarding'));
const KycRoot = lazyWithPreload(() => import('./onboarding/kyc'));

const FoundingOnboardingRoot = lazyWithPreload(
  () => import('./founding-onboarding'),
);

const AccountingIntegrationError = lazyWithPreload(
  () => import('./routes/accounting/integration-error'),
);

const AccountantInvitationRoot = lazyWithPreload(
  () => import('./routes/accountant-invitation'),
);

const BackofficeRoot = lazyWithPreload(() => import('./routes/backoffice'));

const SuspenseNoFallback: React.FC<React.PropsWithChildren> = ({
  children,
}) => <React.Suspense fallback={null}>{children}</React.Suspense>;

export const App: React.FC = () => {
  const globalError = useGlobalError();

  React.useEffect(() => {
    if (globalError === null) {
      return;
    }

    function resetGlobalError() {
      setGlobalError(null);
    }

    window.addEventListener('popstate', resetGlobalError);

    return () => window.removeEventListener('popstate', resetGlobalError);
  }, [globalError]);

  useRestoreFocus();

  return (
    <>
      <GlobalStyles
        styles={css`
          body {
            background-color: var(
              --body-background-color,
              ${colors.background}
            );
            transition: background-color 1s;

            --muted-color: ${colors.wcagGray};
            @media (prefers-contrast: more) {
              --muted-color: ${colors.black};
            }
          }

          ::selection {
            background: ${extraColors.blue20Opaque};
            color: ${colors.black};
          }

          a {
            color: ${colors.blue};
            border-radius: 4px; /* To look better when focused */
            box-decoration-break: clone;

            @media (hover) {
              &:hover {
                text-decoration: none;
              }
            }
          }
        `}
      />
      {/* Error boundary is inside `GlobalStyles` so fonts etc. are loaded */}
      <ErrorBoundary>
        <IntercomControlsProvider>
          <BrowserRouter>
            <Wrapper>
              {globalError === 'NotAuthenticated' ? (
                <>
                  <Routes>
                    <Route path="*" element={<Forbidden />} />
                  </Routes>
                  <Intercom />
                </>
              ) : (
                <ScrollToTop>
                  <Routes>
                    <Route
                      path={`${loginPagesPrefix}/*`}
                      element={<LoginRoot />}
                    />
                    <Route
                      path={`${bankPagesPrefix}/*`}
                      element={
                        <EventFiltersProvider>
                          <OrgRoot />
                        </EventFiltersProvider>
                      }
                    />
                    <Route
                      path={`${onboardingPagesPrefix}/*`}
                      element={
                        <SuspenseNoFallback>
                          <OnboardingRoot />
                        </SuspenseNoFallback>
                      }
                    />
                    <Route
                      path={`${foundingOnboardingPagesPrefix}/*`}
                      element={
                        <SuspenseNoFallback>
                          <FoundingOnboardingRoot />
                        </SuspenseNoFallback>
                      }
                    />
                    <Route
                      path={`${updateInfoPrefix}/*`}
                      element={
                        <SuspenseNoFallback>
                          <KycRoot />
                        </SuspenseNoFallback>
                      }
                    />
                    <Route
                      path={accountingIntegrationErrorPages.start}
                      element={
                        <SuspenseNoFallback>
                          <AccountingIntegrationError />
                        </SuspenseNoFallback>
                      }
                    />
                    <Route
                      path={`${accountantInvitationPagesPrefix}/*`}
                      element={
                        <SuspenseNoFallback>
                          <AccountantInvitationRoot />
                        </SuspenseNoFallback>
                      }
                    />
                    <Route path="/" element={<Navigate to="/logg-inn" />} />
                    <Route
                      path={pages.oauthConsent.mountPoint}
                      element={<SuspendedPage page={pages.oauthConsent} />}
                    />
                    <Route
                      path={pages.oauthError.mountPoint}
                      element={<SuspendedPage page={pages.oauthError} />}
                    />
                    <Route
                      path={`${backofficePrefix}/*`}
                      element={
                        <SuspenseNoFallback>
                          <BackofficeRoot />
                        </SuspenseNoFallback>
                      }
                    />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </ScrollToTop>
              )}
            </Wrapper>
          </BrowserRouter>
        </IntercomControlsProvider>
      </ErrorBoundary>
    </>
  );
};

const Forbidden: React.FC = () => {
  const { pathname, search } = useLocation();
  const continueUrl = useHref({ pathname, search });
  const absoluteContinueUrl = new URL(continueUrl, window.location.href).href;
  const loginUrl = useHref({
    pathname: '/logg-inn',
    search: new URLSearchParams({
      [continueUrlParam]: absoluteContinueUrl,
    }).toString(),
  });
  return (
    <InfoPage
      illustration={<ForbiddenIllustration />}
      heading="Du har ikke tilgang!"
      body={
        <p>
          Hvis dette ikke stemmer så kan du prøve å{' '}
          <a href={loginUrl}>logge inn på nytt</a>.
        </p>
      }
    />
  );
};

const ScrollToTop: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();
  useScrollToTop(pathname);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

const ApproveAccountantRedirect: React.FC = () => {
  const { fid } = useParams();
  const urlWithOrgPath = useOrgPathAbsoluteUrl();

  if (!fid) {
    return <NotFound />;
  }

  return (
    <Navigate
      to={urlWithOrgPath(
        pages.confirmInvitation.mountPoint.replace(':fid', fid),
      )}
      replace={true}
    />
  );
};
