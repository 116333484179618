import * as React from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Example usage:
 *
 * ```
 * const scrollToId = useFragmentId();
 *
 * return <div id="test" ref={scrollToId} />
 * ```
 */
export function useFragmentId() {
  const { hash } = useLocation();
  const [hasScrolled, setHasScrolled] = React.useState(false);

  return React.useCallback(
    (ele: HTMLElement | null) => {
      if (!hash || !ele || hasScrolled) {
        return;
      }

      try {
        if (ele.id === decodeURIComponent(hash.slice(1))) {
          window.requestAnimationFrame(() =>
            ele.scrollIntoView({ behavior: 'smooth' }),
          );

          if (ele.tagName === 'SUMMARY') {
            (ele.parentElement as HTMLDetailsElement).open = true;
          }

          setHasScrolled(true);
        }
      } catch {
        // ignore
      }
    },
    [hasScrolled, hash],
  );
}
