import { css } from '@emotion/react';
import * as React from 'react';
import { ColoredLabel } from '../colored-label';

export type CompleteLabelStatus = 'complete' | 'overridden' | 'incomplete';

export const bookkeepingLabels: Record<CompleteLabelStatus, string> = {
  complete: 'Ferdig til regnskapet',
  overridden: 'Merket som ferdig',
  incomplete: 'Mangler noe til regnskapet',
};

export function getCompletionStatus(flags: {
  allRequirementsMet: boolean;
  overridden: boolean;
}): CompleteLabelStatus {
  const { allRequirementsMet, overridden } = flags;

  const completionStatus: CompleteLabelStatus = allRequirementsMet
    ? 'complete'
    : overridden
    ? 'overridden'
    : 'incomplete';

  return completionStatus;
}

export const CompleteLabel: React.FC<{
  status: CompleteLabelStatus;
}> = ({ status }) => {
  const done = status !== 'incomplete';
  return (
    <ColoredLabel
      aria-hidden={done ? undefined : true}
      css={css`
        transition: opacity 0.4s;
        opacity: ${done ? 1 : 0};
        user-select: ${done ? null : 'none'};
      `}
      color="green"
    >
      {bookkeepingLabels[status]}
    </ColoredLabel>
  );
};
