import { css } from '@emotion/react';
import { ProgressSpinner } from 'folio-common-components';
import * as React from 'react';

export const InProgressView: React.FC = () => (
  <div
    css={css`
      display: flex;
      justify-content: center;
    `}
  >
    <div>
      <div
        css={css`
          width: 48px;
          margin: auto;
        `}
      >
        <ProgressSpinner size={48} />
      </div>
      <p>Takk for at du venter!</p>
    </div>
  </div>
);
