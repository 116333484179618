import { css } from '@emotion/react';
import * as React from 'react';
import { ProgressSpinner } from './Spinner';
import { space } from './spacing';

export const LazyRouteFallback: React.FC = () => {
  const [showSpinner, setShowSpinner] = React.useState(false);

  React.useEffect(() => {
    const timeout = window.setTimeout(() => setShowSpinner(true), 500);
    return () => window.clearTimeout(timeout);
  }, []);

  if (showSpinner) {
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
        `}
      >
        <ProgressSpinner size={48} />
      </div>
    );
  }

  return null;
};

// For use on routes without a header
export const LazyRouteFallbackWithTopMargin: React.FC = () => {
  return (
    <div
      css={css`
        ${space([32], 'margin-top')};
      `}
    >
      <LazyRouteFallback />
    </div>
  );
};
