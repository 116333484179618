import * as Sentry from '@sentry/react';
import * as React from 'react';
import type { LedgerCategoryIcon } from '../gqltypes';
import * as icons from '../icons';

type IconProps = React.SVGProps<SVGSVGElement>;

const iconsById: Record<LedgerCategoryIcon, React.ReactElement<IconProps>> = {
  Accomodations: <icons.NightIcon />,
  Books: <icons.BookIcon />,
  Camera: <icons.CameraIcon />,
  Card: <icons.CardIcon />,
  Cash: <icons.BanknoteIcon />,
  CashAlt: <icons.BanknoteAltIcon />,
  Change: <icons.LeversIcon />,
  ChangeAlt: <icons.LeversAltIcon />,
  CleaningRelated: <icons.DropIcon />,
  Clock: <icons.ClockIcon />,
  Coffee: <icons.CoffeeIcon />,
  Company: <icons.BuildingIcon />,
  Complete: <icons.CompleteIcon />,
  ComputerEquipment: <icons.ComputerIcon />,
  Consultant: <icons.ChatIcon />,
  Contractors: <icons.PaperIcon />,
  Document: <icons.PaperScrollIcon />,
  Domain: <icons.GlobeIcon />,
  Email: <icons.EnvelopeLetterMailIcon />,
  EmployeeExpense: <icons.PersonDollarIcon />,
  EmployeeRelated: <icons.PersonIcon />,
  EmployeeTax: <icons.PersonPercentIcon />,
  Filter: <icons.FilterIcon />,
  Finances: <icons.GraphUp2Icon />,
  Flight: <icons.AirplaneUpIcon />,
  FoodBeverage: <icons.FoodCutleryIcon />,
  FoodSubscription: <icons.CalendarFoodIcon />,
  FoodTravelling: <icons.FastFoodIcon />,
  Government: <icons.GovernmentIcon />,
  Groceries: <icons.CartIcon />,
  Heart: <icons.HeartIcon />,
  Info: <icons.InfoIcon />,
  Insurance: <icons.UmbrellaIcon />,
  Interior: <icons.FurnitureIcon />,
  Internal: <icons.ArrowsRightLeftIcon />,
  InvestmentOverTime: <icons.CalendarGraphIcon />,
  Law: <icons.ScalesIcon />,
  LawAlt: <icons.LegalParagraphIcon />,
  Loan: <icons.PaperTearPercentIcon />,
  Location: <icons.LocationPinIcon />,
  Lock: <icons.LockIcon />,
  Lunch: <icons.BreadIcon />,
  Lunch2: <icons.BreadIcon />,
  Luxury: <icons.DiamondIcon />,
  Map: <icons.CompassIcon />,
  MapAlt: <icons.FoldingPaperIcon />,
  MapAlt2: <icons.TruckIcon />,
  Market: <icons.BananastandIcon />,
  Marketing: <icons.MegaphoneIcon />,
  Meeting: <icons.BriefcaseIcon />,
  Membership: <icons.ClipboardTagIcon />,
  Nerdy: <icons.GlassesIcon />,
  Newspaper: <icons.PaperFoldedIcon />,
  NewView: <icons.NewViewIcon />,
  Note: <icons.PencilLinesIcon />,
  OfficeEquipment: <icons.StationaryIcon />,
  OfficeRent: <icons.BuildingLocationIcon />,
  Other: <icons.DotsMoreIcon />,
  Other2: <icons.DotsMiscIcon />,
  OtherTransport: <icons.CarIcon />,
  OvertimeFood: <icons.ForkArrowIcon />,
  Parking: <icons.ParkingIcon />,
  Party: <icons.ChampagneIcon />,
  Perks: <icons.GiftBoxIcon />,
  Phone: <icons.PhoneIcon />,
  PhysicalObject: <icons.ScreenIcon />,
  Props: <icons.MaskIcon />,
  PublicTransport: <icons.BusIcon />,
  Robot: <icons.RobotIcon />,
  Salary: <icons.MoneyBagDollarIcon />,
  SalaryAlt: <icons.MoneyBagCheckIcon />,
  Savings: <icons.PiggybankIcon />,
  Settings: <icons.CogwheelGearIcon />,
  Software: <icons.SoftwareIcon />,
  SoftwareOneTime: <icons.SoftwareCheckIcon />,
  StatePenalty: <icons.PaperScrollPercentIcon />,
  Subcontractor: <icons.DocumentArrowDownIcon />,
  Subscription: <icons.CloudIcon />,
  Tax: <icons.PercentIcon />,
  Taxi: <icons.TaxiIcon />,
  Tools: <icons.MetalNutIcon />,
  Train: <icons.TrainIcon />,
  TransportTravel: <icons.SuitcaseIcon />,
  TransportTravelAlt: <icons.Airplane2Icon />,
  Unknown: <icons.QuestionIcon />,
  Update: <icons.ArrowsAroundIcon />,
  Vat: <icons.GraphUpIcon />,
  Warning: <icons.WarningIcon />,
};

export function getLedgerIcon(iconId: LedgerCategoryIcon) {
  const icon = iconsById[iconId];
  if (!icon) {
    Sentry.captureMessage(`Tried getting unknown icon: ${iconId}`);
  }
  return icon || iconsById.Unknown;
}
