import * as Sentry from '@sentry/react';
import promiseSettledAggregate from 'promise-settled-aggregate';

// exported for use in integration test
export async function compressImageFile(
  imageFile: File,
  options: {
    /** maxDimension is the maximum of height and width */
    maxDimension?: number;
  } = {},
): Promise<File> {
  const image = await convertFileToImage(imageFile);
  let width = image.width;
  let height = image.height;

  if (options.maxDimension) {
    const ratio = width / height;
    let shrinkRatio = 1;
    if (ratio >= 1) {
      width = Math.min(image.width, options.maxDimension);
      shrinkRatio = width / image.width;
      height = image.height * shrinkRatio;
    } else {
      height = Math.min(image.height, options.maxDimension);
      shrinkRatio = height / image.height;
      width = image.width * shrinkRatio;
    }
  }

  return compressImage(image, { width, height, fileName: imageFile.name });
}

function compressImage(
  image: HTMLImageElement,
  options: {
    width: number;
    height: number;
    fileName: string;
  } = {
    width: image.width,
    height: image.height,
    fileName: 'image.jpg',
  },
): Promise<File> {
  return new Promise((resolve, reject) => {
    // TODO: offscreen canvas?
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (ctx === null) {
      reject(new Error('Compression needs to be run in a browser'));
      return;
    }
    canvas.width = options.width;
    canvas.height = options.height;
    // Note that this discards any EXIF data about orientation
    ctx.drawImage(image, 0, 0, options.width, options.height);
    canvas.toBlob(
      blob => {
        if (blob !== null) {
          const file = new File([blob], options.fileName, {
            type: 'image/jpeg',
          });
          resolve(file);
        } else {
          reject(new Error('Failed to compress image'));
        }
      },
      'image/jpeg',
      0.65,
    );
  });
}

function convertFileToImage(file: File): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(file);
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = () => {
      URL.revokeObjectURL(url);
      resolve(image);
    };
    image.onerror = () => {
      URL.revokeObjectURL(url);
      reject(new Error('Could not load file as image'));
    };
    image.src = url;
  });
}

async function compressFile(file: File): Promise<File> {
  if (file.type.startsWith('image/')) {
    try {
      return await compressImageFile(file, { maxDimension: 2000 });
    } catch (error) {
      Sentry.addBreadcrumb({ message: 'Failed to compress image' });
      Sentry.captureException(error);
    }
  }

  return file;
}

export function compressFiles(
  files: readonly File[],
): Promise<readonly File[]> {
  return promiseSettledAggregate(files.map(compressFile));
}
