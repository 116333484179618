import { css } from '@emotion/react';
import * as Sentry from '@sentry/react';
import * as React from 'react';
import * as avatars from '../avatars';
import type { AvatarId } from '../gqltypes';

type AvatarProps = React.SVGProps<SVGSVGElement>;

const avatarComponents: Record<AvatarId, React.ComponentType> = {
  Balloon1Avatar: avatars.Balloon1Avatar,
  Balloon2Avatar: avatars.Balloon2Avatar,
  Balloon3Avatar: avatars.Balloon3Avatar,
  Balloon4Avatar: avatars.Balloon4Avatar,

  Barry1Avatar: avatars.Barry1Avatar,
  Barry2Avatar: avatars.Barry2Avatar,
  Barry3Avatar: avatars.Barry3Avatar,
  Barry4Avatar: avatars.Barry4Avatar,

  Bloomberg1Avatar: avatars.Bloomberg1Avatar,
  Bloomberg2Avatar: avatars.Bloomberg2Avatar,
  Bloomberg3Avatar: avatars.Bloomberg3Avatar,
  Bloomberg4Avatar: avatars.Bloomberg4Avatar,

  Bulby1Avatar: avatars.Bulby1Avatar,
  Bulby2Avatar: avatars.Bulby2Avatar,
  Bulby3Avatar: avatars.Bulby3Avatar,
  Bulby4Avatar: avatars.Bulby4Avatar,

  Fruity1Avatar: avatars.Fruity1Avatar,
  Fruity2Avatar: avatars.Fruity2Avatar,
  Fruity3Avatar: avatars.Fruity3Avatar,
  Fruity4Avatar: avatars.Fruity4Avatar,

  Puss1Avatar: avatars.Puss1Avatar,
  Puss2Avatar: avatars.Puss2Avatar,
  Puss3Avatar: avatars.Puss3Avatar,
  Puss4Avatar: avatars.Puss4Avatar,

  Simpleton1Avatar: avatars.Simpleton1Avatar,
  Simpleton2Avatar: avatars.Simpleton2Avatar,
  Simpleton3Avatar: avatars.Simpleton3Avatar,
  Simpleton4Avatar: avatars.Simpleton4Avatar,

  Whiz1Avatar: avatars.Whiz1Avatar,
  Whiz2Avatar: avatars.Whiz2Avatar,
  Whiz3Avatar: avatars.Whiz3Avatar,
  Whiz4Avatar: avatars.Whiz4Avatar,
};

export function getAvatarComponent(avatarId: AvatarId): React.FC<AvatarProps> {
  let component = avatarComponents[avatarId];
  if (!component) {
    Sentry.captureMessage(`Tried getting unknown avatar: ${avatarId}`);
    component = avatarComponents.Barry2Avatar;
  }

  const ret: React.FC<AvatarProps> = props =>
    React.createElement(component, props);
  ret.displayName = avatarId;
  return ret;
}

export const EmployeeAvatar: React.FC<{ avatarId: AvatarId }> = ({
  avatarId,
}) => {
  const Avatar = getAvatarComponent(avatarId);

  return (
    <Avatar
      css={css`
        border-radius: 50%;
        display: block;
        margin: auto;
      `}
      width={50}
      height={50}
    />
  );
};
