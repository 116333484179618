// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../node_modules/@types/webpack-env/index.d.ts" />
/// <reference types="@emotion/react/types/css-prop" />

import { ApolloProvider } from '@apollo/client';
import { OverlayProvider } from '@react-aria/overlays';
import * as Sentry from '@sentry/react';
import { setupSentry } from 'folio-sentry-web';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
// eslint-disable-next-line import/no-unassigned-import
import 'focus-visible';
import { App } from './App';
import { createClient } from './apollo-client';
import { NotificationsProvider } from './hooks/notification-context';
import { getReactRoot } from './utils/react-root';

setupSentry('https://78a941eaede947cd9ec7d7f2d89ee619@sentry.folio.no/2');

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line @typescript-eslint/no-require-imports
  require('@welldone-software/why-did-you-render')(React, {
    trackAllPureComponents: false,
  });
}

if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/sw.js').catch((error: unknown) => {
    Sentry.captureException(error);
  });
}

const reactRoot = createRoot(getReactRoot());

function render() {
  reactRoot.render(
    <ApolloProvider client={createClient()}>
      <NotificationsProvider>
        <OverlayProvider>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </OverlayProvider>
      </NotificationsProvider>
    </ApolloProvider>,
  );
}

render();

if (module.hot) {
  module.hot.accept('./App', () => {
    render();
  });
}
