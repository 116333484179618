import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { NumberValueInput, ProgressSpinner } from 'folio-common-components';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { ArrowRightIcon } from '../../../icons';

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

const Spinner = styled.div`
  background-color: ${colors.blue};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 4px;
  top: 4px;
  bottom: 4px;
  padding: 10px;
`;

const Input = styled(NumberValueInput)`
  width: 130px;
  padding-right: 56px;
`;

function isValidForUpdate(val: string) {
  return val === '' || /^\d{1,3}$/.test(val);
}

function isValidCvv(val: string) {
  return /^\d{3}$/.test(val);
}

interface Props {
  onChange: (value: string) => void;
  locked: boolean;
  errored: boolean;
}

export const CvvInput: React.FC<Props> = props => {
  const { locked, onChange, errored } = props;
  const [cvv, setCvv] = React.useState('');
  const [hasErrored, setHasErrored] = React.useState(false);

  React.useEffect(() => {
    setHasErrored(errored);
  }, [errored]);

  const onChangeCallback = React.useCallback(
    (val: string) => {
      if (isValidForUpdate(val)) {
        setHasErrored(false);
        setCvv(val);
      }
      if (isValidCvv(val)) {
        onChange(val);
      }
    },
    [setCvv, onChange],
  );

  return (
    <>
      <Container>
        <Input
          value={cvv}
          aria-label="Sikkerhetskode"
          onChange={onChangeCallback}
          placeholder="000"
          disabled={locked}
          maxLength={3}
          autoComplete="cc-csc"
          css={css`
            text-align: left;
          `}
        />
        <Spinner>
          {!locked ? (
            <ArrowRightIcon color="white" />
          ) : (
            <ProgressSpinner color="white" size={22} />
          )}
        </Spinner>
      </Container>
      {hasErrored && (
        <p
          css={css`
            color: ${colors.wcagRed};
          `}
        >
          Feil kode. Prøv igjen!
        </p>
      )}
    </>
  );
};
