import { useLazyQuery, useMutation } from '@apollo/client';
import { isArrayOfLength, maxUploadSize, notEmpty } from 'folio-common-utils';
import promiseSettledAggregate from 'promise-settled-aggregate';
import * as React from 'react';
import { SingleEventDataDocument } from '../components/bookkeeping/queries.generated';
import { compressFiles } from '../utils/compressImage';
import { UploadFileDocument, UploadFilesDocument } from './queries.generated';

export function useUploadFiles(eventFid: string | undefined) {
  const [uploadFileMutation] = useMutation(UploadFileDocument, {
    context: { isUpload: true },
  });
  const [uploadFilesMutation] = useMutation(UploadFilesDocument, {
    context: { isUpload: true },
  });
  const [getEvent] = useLazyQuery(SingleEventDataDocument, {
    fetchPolicy: 'network-only',
  });

  return React.useCallback(
    async (files: readonly File[]) => {
      const compressedFiles = await compressFiles(files);

      for (const compressedFile of compressedFiles) {
        if (compressedFile.size > maxUploadSize) {
          throw new Error(
            `Image ${compressedFile.name} is too large to upload`,
          );
        }
      }

      if (isArrayOfLength(compressedFiles, 1)) {
        const { data } = await uploadFileMutation({
          variables: { file: compressedFiles[0], eventFid },
        });

        if (data) {
          return [data.uploadReceipt.receiptFid];
        }

        return [];
      }

      try {
        const { data } = await uploadFilesMutation({
          variables: { files: compressedFiles, eventFid },
        });

        return (
          data?.uploadReceipts.map(uploadResult => uploadResult.receiptFid) ??
          []
        );
      } catch {
        try {
          const result = await promiseSettledAggregate(
            compressedFiles.map(file =>
              uploadFileMutation({ variables: { file, eventFid } }),
            ),
          );

          return result
            .flatMap(result => result.data?.uploadReceipt.receiptFid)
            .filter(notEmpty);
        } finally {
          // we wanna refetch the event data to get the new receipts in case multiple uploads failed
          if (eventFid) {
            await getEvent({ variables: { fid: eventFid } });
          }
        }
      }
    },
    [eventFid, getEvent, uploadFileMutation, uploadFilesMutation],
  );
}
