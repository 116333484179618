export type TransactionsPageState = {
  after: string | null;
  before: string | null;
  pageSize: number;
};

export function defaultState(): TransactionsPageState {
  return {
    after: null,
    before: null,
    pageSize: 200,
  };
}
