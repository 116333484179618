import { useLocation, useMatch } from 'react-router-dom';
import { useOrgPathAbsoluteUrl } from '../components/org-navigation';

// An org absolute URL is everything that comes after
// /org/123456789, e.g. in "/org/123456789/betaling/ny",
// the org absolute URL is "betaling/ny".
export function useLocationMatch(orgAbsoluteUrl = '') {
  const urlWithOrgPath = useOrgPathAbsoluteUrl();
  const { pathname } = useLocation();
  const orgAbsolutePath = getOrgAbsolutePath(orgAbsoluteUrl);
  const isRoot = orgAbsolutePath === '';
  const isPartialMatch = useMatch(
    isRoot
      ? urlWithOrgPath(orgAbsolutePath)
      : urlWithOrgPath(`${orgAbsolutePath}/*`),
  );
  return {
    isMatch:
      pathname === urlWithOrgPath(orgAbsolutePath) ||
      pathname === `${urlWithOrgPath(orgAbsolutePath)}/`,
    isPartialMatch: isPartialMatch != null,
  };
}

// Turns e.g. "betaling/ny?mottaker=..." into "betaling/ny"
function getOrgAbsolutePath(orgAbsoluteUrl: string) {
  const { pathname } = new URL(orgAbsoluteUrl, 'https://folio.no');
  return pathname.slice(1); // Remove slash
}
