import type { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useUnbatchedQuery } from 'bank-common-client';
import { parseOrgNum } from 'folio-common-utils';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  FirstPaintDocument,
  FirstPaintNoOrgDocument,
  type FirstPaintNoOrgQuery,
  FirstPaintNoSessionDocument,
  type FirstPaintNoSessionQuery,
  type FirstPaintQuery,
} from '../common-queries.generated';
import { useOrgPathAbsoluteUrl } from '../components/org-navigation';
import { pages } from '../pages';
import { usePollQueryIfDocumentIsVisible } from './document-visible';
import { PollAgentDataDocument } from './queries.generated';

// have this be constant to limit rerenders
const loadingReturn = { error: false, loading: true, data: null } as const;

function useSetSentryUser(id?: string) {
  React.useEffect(() => {
    if (id) {
      Sentry.setUser({ id });
    }
  }, [id]);
}

function useLogErrorTrace(error?: ApolloError | null) {
  const errorTrace = React.useMemo(() => {
    if (!error) {
      return null;
    }

    const { message, stack } = error;

    if (!stack) {
      return message;
    }

    if (stack.indexOf(message) === 0) {
      return stack;
    }

    return `${message}\n${stack}`;
  }, [error]);

  React.useEffect(() => {
    if (errorTrace) {
      Sentry.addBreadcrumb({ message: 'Unable to load firstPaintData' });
      Sentry.captureException(errorTrace);
    }
  }, [errorTrace]);
}

export function useFirstPaintData():
  | typeof loadingReturn
  | { error: ApolloError; loading: false; data: FirstPaintQuery | undefined }
  | { error: undefined; loading: false; data: FirstPaintQuery } {
  const { data, error, refetch } = useUnbatchedQuery(FirstPaintDocument);

  // this polls agent and org state - change to either will trigger a full
  // refetch of first paint data
  usePollQueryIfDocumentIsVisible(PollAgentDataDocument, 60_000);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const orgPathAbsoluteUrl = useOrgPathAbsoluteUrl();

  useSetSentryUser(data?.me.fid);

  useLogErrorTrace(error);

  const userState = data?.me.state;
  const userType = data?.me.type;
  const orgNum = data?.organization.orgNum;
  const orgState = data?.organization.state;
  const orgName = data?.organization.name;
  const lastUserState = React.useRef(userState);
  const lastUserType = React.useRef(userType);
  const lastOrgState = React.useRef(orgState);

  // refetch the whole first paint data whenever the user's state changes
  React.useEffect(() => {
    // the ref is just to avoid a refetch on initial use of the hook, or if we're still fetching the initial one
    if (lastUserState.current) {
      if (userState !== lastUserState.current) {
        refetch();
      }
    }

    lastUserState.current = userState;
  }, [refetch, userState]);

  // refetch the whole first paint data whenever the user's type changes
  React.useEffect(() => {
    // the ref is just to avoid a refetch on initial use of the hook, or if we're still fetching the initial one
    if (lastUserType.current) {
      if (userType !== lastUserType.current) {
        refetch();
      }
    }

    lastUserType.current = userType;
  }, [refetch, userType]);

  // refetch the whole first paint data whenever the orgs's state changes
  React.useEffect(() => {
    // the ref is just to avoid a refetch on initial use of the hook, or if we're still fetching the initial one
    if (lastOrgState.current) {
      if (orgState !== lastOrgState.current) {
        refetch();
      }
    }

    lastOrgState.current = orgState;
  }, [orgState, refetch]);

  React.useLayoutEffect(() => {
    if (!orgNum) {
      return;
    }

    const orgNumFromPath = parseOrgNum(pathname);

    if (!orgNumFromPath || orgNumFromPath === orgNum) {
      return;
    }

    navigate(pathname.replace(orgNumFromPath, orgNum), { replace: true });
  }, [navigate, orgNum, pathname]);

  React.useLayoutEffect(() => {
    if (orgState === 'Rejected') {
      navigate('/logg-inn/velg-organisasjon', {
        replace: true,
        state: { rejectedOrgName: orgName },
      });
    }
  }, [navigate, orgName, orgState]);

  React.useLayoutEffect(() => {
    if (orgState === 'Archived') {
      const exportUrl = orgPathAbsoluteUrl(pages.accounting.getUrl());

      if (exportUrl !== pathname) {
        navigate(exportUrl, { replace: true });
      }
    }
  }, [navigate, orgPathAbsoluteUrl, orgState, pathname]);

  if (error) {
    return { data, loading: false, error };
  }

  if (data) {
    return { data, loading: false, error: undefined };
  }

  return loadingReturn;
}

export function useFirstPaintNoOrgData():
  | typeof loadingReturn
  | {
      error: ApolloError;
      loading: false;
      data: FirstPaintNoOrgQuery | undefined;
    }
  | { error: undefined; loading: false; data: FirstPaintNoOrgQuery } {
  const { data, error } = useUnbatchedQuery(FirstPaintNoOrgDocument);

  useSetSentryUser(data?.agent.free.fid);
  useLogErrorTrace(error);

  if (error) {
    return { data, loading: false, error };
  }

  if (data) {
    return { data, loading: false, error: undefined };
  }

  return loadingReturn;
}

export function useFirstPaintNoSessionData():
  | typeof loadingReturn
  | {
      error: ApolloError;
      loading: false;
      data: FirstPaintNoSessionQuery | undefined;
    }
  | { error: undefined; loading: false; data: FirstPaintNoSessionQuery } {
  // poll since this contains broadcast
  const { data, error } = usePollQueryIfDocumentIsVisible(
    FirstPaintNoSessionDocument,
    60_000,
  );
  const newestVersion = data?.versions.webVersion;
  const currentVersion =
    // @ts-expect-error: COMMIT_SHA is injected by webpack
    typeof COMMIT_SHA === 'undefined' ? 'NO_COMMIT' : COMMIT_SHA;
  const hasNewVersion =
    newestVersion != null && newestVersion !== currentVersion;

  React.useEffect(() => {
    function preventLinkClickInterception(event: MouseEvent) {
      // Prevent the click from reaching the router, instead performing
      // a normal navigation when a link is clicked.
      if (event.target instanceof HTMLElement && event.target.closest('a')) {
        event.stopPropagation();
      }
    }

    if (hasNewVersion) {
      document.addEventListener('click', preventLinkClickInterception, true);
    }

    return () =>
      document.removeEventListener('click', preventLinkClickInterception, true);
  }, [hasNewVersion]);

  useLogErrorTrace(error);

  if (error) {
    return { data, loading: false, error };
  }

  if (data) {
    return { data, loading: false, error: undefined };
  }

  return loadingReturn;
}
