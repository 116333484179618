// Not sure where this format comes from. It seems to be popular in IBAN libraries,
// but it's not the official format from ISO 13616-1.
export const countryFormats: [
  countryCode: string,
  length: number,
  bbanStructure: string,
][] = [
  ['AD', 24, 'F04F04A12'],
  ['AE', 23, 'F03F16'],
  ['AL', 28, 'F08A16'],
  ['AT', 20, 'F05F11'],
  ['AZ', 28, 'U04A20'],
  ['BA', 20, 'F03F03F08F02'],
  ['BE', 16, 'F03F07F02'],
  ['BG', 22, 'U04F04F02A08'],
  ['BH', 22, 'U04A14'],
  ['BR', 29, 'F08F05F10U01A01'],
  ['BY', 28, 'A04F04A16'],
  ['CH', 21, 'F05A12'],
  ['CR', 22, 'F04F14'],
  ['CY', 28, 'F03F05A16'],
  ['CZ', 24, 'F04F06F10'],
  ['DE', 22, 'F08F10'],
  ['DK', 18, 'F04F09F01'],
  ['DO', 28, 'U04F20'],
  ['EE', 20, 'F02F02F11F01'],
  ['EG', 29, 'F04F04F17'],
  ['ES', 24, 'F04F04F01F01F10'],
  ['FI', 18, 'F06F07F01'],
  ['FO', 18, 'F04F09F01'],
  ['FR', 27, 'F05F05A11F02'],
  ['GB', 22, 'U04F06F08'],
  ['GE', 22, 'U02F16'],
  ['GI', 23, 'U04A15'],
  ['GL', 18, 'F04F09F01'],
  ['GR', 27, 'F03F04A16'],
  ['GT', 28, 'A04A20'],
  ['HR', 21, 'F07F10'],
  ['HU', 28, 'F03F04F01F15F01'],
  ['IE', 22, 'U04F06F08'],
  ['IL', 23, 'F03F03F13'],
  ['IS', 26, 'F04F02F06F10'],
  ['IT', 27, 'U01F05F05A12'],
  ['IQ', 23, 'U04F03A12'],
  ['JO', 30, 'A04F22'],
  ['KW', 30, 'U04A22'],
  ['KZ', 20, 'F03A13'],
  ['LB', 28, 'F04A20'],
  ['LC', 32, 'U04F24'],
  ['LI', 21, 'F05A12'],
  ['LT', 20, 'F05F11'],
  ['LU', 20, 'F03A13'],
  ['LV', 21, 'U04A13'],
  ['MC', 27, 'F05F05A11F02'],
  ['MD', 24, 'U02A18'],
  ['ME', 22, 'F03F13F02'],
  ['MK', 19, 'F03A10F02'],
  ['MR', 27, 'F05F05F11F02'],
  ['MT', 31, 'U04F05A18'],
  ['MU', 30, 'U04F02F02F12F03U03'],
  ['NL', 18, 'U04F10'],
  ['NO', 15, 'F04F06F01'],
  ['PK', 24, 'U04A16'],
  ['PL', 28, 'F08F16'],
  ['PS', 29, 'U04A21'],
  ['PT', 25, 'F04F04F11F02'],
  ['QA', 29, 'U04A21'],
  ['RO', 24, 'U04A16'],
  ['RS', 22, 'F03F13F02'],
  ['SA', 24, 'F02A18'],
  ['SC', 31, 'U04F04F16U03'],
  ['SE', 24, 'F03F16F01'],
  ['SI', 19, 'F05F08F02'],
  ['SK', 24, 'F04F06F10'],
  ['SM', 27, 'U01F05F05A12'],
  ['ST', 25, 'F08F11F02'],
  ['SV', 28, 'U04F20'],
  ['TL', 23, 'F03F14F02'],
  ['TN', 24, 'F02F03F13F02'],
  ['TR', 26, 'F05F01A16'],
  ['UA', 29, 'F25'],
  ['VA', 22, 'F18'],
  ['VG', 24, 'U04F16'],
  ['XK', 20, 'F04F10F02'],
];
