import styled from '@emotion/styled';
import { Button, fonts } from 'folio-common-components';
import { formatters } from 'folio-common-utils';
import * as React from 'react';
import { useDisableAutofillIfChangeBelow } from './shared';
import type { AccountType } from './types';

const Container = styled.div`
  text-align: center;
`;

export const AutofillInfoScreen: React.FC<{
  onClose: () => void;
  account: AccountType;
  newAccountBalance: number;
  moneyIncreased: boolean;
}> = ({ account, newAccountBalance, moneyIncreased, onClose }) => {
  const { newBalanceBelowAutofillLimit } = useDisableAutofillIfChangeBelow(
    account,
    newAccountBalance,
    moneyIncreased,
  );

  if (moneyIncreased || !newBalanceBelowAutofillLimit) {
    return (
      <Container>
        <div css={fonts.font400bold}>
          {formatters.formatAmount(newAccountBalance)} på kortet
        </div>
        <p>Automatisk påfyll er fortsatt på.</p>
        <Button onClick={onClose}>OK</Button>
      </Container>
    );
  }

  return (
    <Container>
      <div css={fonts.font400bold}>
        {newAccountBalance === 0
          ? 'Kortet er tømt'
          : `Satt ned saldo til ${formatters.formatAmount(newAccountBalance, {
              currency: 'kr',
            })}`}
      </div>
      <p>Stoppet automatisk påfyll.</p>
      <div>
        <Button onClick={onClose}>Greit</Button>
      </div>
    </Container>
  );
};
