import { css } from '@emotion/react';
import {
  ProgressSpinner,
  TextButton,
  fonts,
  space,
} from 'folio-common-components';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { pages } from '../../pages';
import {
  type AutofillAccount,
  CardAutofillSwitch,
} from '../../routes/profile/card-autofill-admin';
import { OrgLink } from '../org-navigation';
import { LoadingScreen, useDisableAutofillIfChangeBelow } from './shared';

export const AutofillStateScreen: React.FC<{
  account: AutofillAccount;
  startManualTransfer: () => void;
  autofillHandled: () => void;
  newAccountBalance: number;
  mustVerifyAutofillState: boolean;
  moneyIncreased: boolean;
}> = ({
  account,
  startManualTransfer,
  autofillHandled,
  newAccountBalance,
  mustVerifyAutofillState,
  moneyIncreased,
}) => {
  React.useEffect(() => {
    pages.profile.loadableComponent.preload();
  }, []);

  const { hasCompleted, newBalanceBelowAutofillLimit } =
    useDisableAutofillIfChangeBelow(
      account,
      newAccountBalance,
      moneyIncreased || !mustVerifyAutofillState,
    );

  const autofillId = React.useId();

  React.useEffect(() => {
    if (hasCompleted || !newBalanceBelowAutofillLimit) {
      autofillHandled();
    }
  }, [autofillHandled, hasCompleted, newBalanceBelowAutofillLimit]);

  if (!hasCompleted) {
    return (
      <LoadingScreen>
        <ProgressSpinner size={48} />
      </LoadingScreen>
    );
  }

  return (
    <>
      <div
        css={css`
          display: grid;
          grid-template-areas:
            'heading switch'
            'link switch';
          grid-template-columns: 1fr auto;
          ${space([16], 'padding')};
          background-color: ${colors.grayAiry};
          border-radius: 4px;
        `}
      >
        <div
          id={autofillId}
          css={css`
            grid-area: heading;
            ${fonts.font200medium};
          `}
        >
          Automatisk påfyll
        </div>
        <div
          css={css`
            grid-area: switch;
            position: relative;
            top: -5px;
            right: -5px;
          `}
        >
          <CardAutofillSwitch account={account} aria-labelledby={autofillId} />
        </div>
        <div>
          <OrgLink
            css={css`
              grid-area: link;
              color: inherit;
            `}
            to={`${pages.profile.getUrl()}#automatisk-påfyll`}
          >
            Detaljer
          </OrgLink>
        </div>
      </div>
      <div
        css={css`
          text-align: center;
          ${space([16], 'margin-top')};
        `}
      >
        <TextButton
          onClick={event => {
            event.stopPropagation();
            startManualTransfer();
          }}
        >
          Endre saldo
        </TextButton>
      </div>
    </>
  );
};
