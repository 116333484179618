import {
  getLocalStorageItemSafely,
  setLocalStorageItemSafely,
} from './safe-local-storage';

type BankIdMethod = 'BankId' | 'BankIdMobil';
const key = 'bankIdMethod';

export function setBankIdMethod(type: BankIdMethod) {
  setLocalStorageItemSafely(key, type);
}

export function getBankIdMethod(): BankIdMethod {
  const value = getLocalStorageItemSafely(key);
  return value === 'BankIdMobil' ? 'BankIdMobil' : 'BankId';
}
