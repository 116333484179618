import { formatTelephoneNumber } from './formatters';

export * as fnum from './fnum';
export * as formatters from './formatters';
export { luhn } from './luhn';
export { mod11 } from './mod11';
export { normalizeAccountNumber } from './normalizers';
export * as numbers from './numbers';
export { orgNumberRegexp, parseOrgNum, replaceOrgInPath } from './org-number';
export * as validators from './validators';
export {
  betaFoundingRegex,
  fikenDseFoundingRegex,
  fikenFoundingRegex,
  isBetaFounding,
  isFikenFounding,
  isTripletexFounding,
  isDseFounding,
  tripletexFoundingRegex,
} from './foundings';
export * from './invariant';
export * from './utils';
export { makeFikenDeepLink } from './fiken-deeplink';
export { makeFragmentId } from './make-fragment-id';
export { isAccountNumberLike, isIbanLike } from './account-number';
export { onboardingOrderStatusQueryValues } from './onboarding';

export const maxUploadSizeInMB = 50;
export const maxUploadSize = maxUploadSizeInMB * 1024 * 1024;

export const folioPhoneNumber = '22120015';
export const folioPhoneNumberUrl = `tel:+47${folioPhoneNumber}`;
export const folioPhoneNumberFormatted =
  formatTelephoneNumber(folioPhoneNumber);
export const folioEmailAddress = 'hei@folio.no';
export const folioEmailAddressUrl = `mailto:${folioEmailAddress}`;

export const traceIdHeader = 'folio-trace-id';

export const folioReleaseHeader = 'folio-release';

export const onboardingSourceDivider = '/';
