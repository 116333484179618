import styled from '@emotion/styled';
import * as React from 'react';

// https://www.webaxe.org/strikethrough-html-accessibility/
const StrikethroughElement = styled.s`
  &:before {
    content: ' [gjennomstrøket] ';
  }

  &:after {
    content: ' [gjennomstrøket slutt] ';
  }

  &:before,
  &:after {
    clip-path: inset(100%);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
  }
`;

export const Strikethrough: React.FC<
  React.HTMLAttributes<HTMLHRElement>
> = props => <StrikethroughElement {...props} />;
