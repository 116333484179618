import { css } from '@emotion/react';
import * as React from 'react';
import { useIntercomControls } from '../../intercom-context';
import { LinkButton, underlineOnHoverStyle } from '../LinkButton';

export const ChatButton: React.FC<
  React.PropsWithChildren<{ color?: string; inverseUnderline?: boolean }>
> = ({ color = 'inherit', inverseUnderline, children }) => {
  const intercomControls = useIntercomControls();

  if (intercomControls.isReady) {
    return (
      <LinkButton
        css={css`
          color: ${color};
          ${inverseUnderline ? underlineOnHoverStyle : undefined}
        `}
        onClick={intercomControls.show}
      >
        {children}
      </LinkButton>
    );
  } else {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
};
