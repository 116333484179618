import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from 'folio-design-tokens';

const fade = keyframes`
  50% {
    opacity: .3;
  }
`;

export const skeletonBackground = css`
  background: ${colors.grayLight};
`;

export const skeletonAnimation = css`
  animation: ${fade} 1.5s linear infinite;

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

export const SkeletonLine = styled.span<{ width: string; maxWidth: string }>`
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  display: inline-block;
  border-radius: 5px;
  height: 1.1em;
  vertical-align: middle;
  ${skeletonBackground};
  ${skeletonAnimation};
`;
