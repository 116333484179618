import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  PlainButton,
  fonts,
  noChildVerticalMargins,
  space,
} from 'folio-common-components';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { XForCloseIcon } from '../../icons';

interface Props {
  onDismiss?: () => void;
}

const Container = styled.div`
  background-color: ${colors.greenLight};
  border-radius: 8px;
  position: relative;
  ${space([16, 32, 40], 'padding')};
`;

const DismissButton: React.FC<{ onDismiss: () => void }> = ({ onDismiss }) => (
  <PlainButton
    type="button"
    aria-label="Lukk"
    css={css`
      position: absolute;
      top: 4px;
      right: 4px;
      padding: 8px;
    `}
    onClick={onDismiss}
  >
    <XForCloseIcon
      css={css`
        display: block;
      `}
    />
  </PlainButton>
);

export const HintBox: React.FC<React.PropsWithChildren<Props>> = props => {
  const { onDismiss, children, ...rest } = props;
  return (
    <Container {...rest}>
      {onDismiss ? <DismissButton onDismiss={onDismiss} /> : null}

      <div
        css={css`
          ${noChildVerticalMargins}
        `}
      >
        {children}
      </div>
    </Container>
  );
};

export const HintBoxTitle = styled.div`
  ${fonts.font500bold};
  ${space([16], 'margin-bottom')};
`;
