// Ported from https://github.com/folio-as/checksum/blob/master/luhn.go

function luhnCheck(values: number[]): boolean {
  return luhnSum(values) % 10 === 0;
}

function luhnSum(values: number[]): number {
  let checksum = 0;
  const end = values.length - 1;
  for (const i of values.keys()) {
    let value = values[end - i];
    if (i % 2 === 1) {
      value *= 2;
      if (value >= 10) {
        value -= 9;
      }
    }
    checksum += value;
  }
  return checksum;
}

export function luhn(value: string): boolean {
  const values = Array.from(value, Number);
  return luhnCheck(values);
}
