import * as Sentry from '@sentry/react';
import { folioEmailAddress, folioEmailAddressUrl } from 'folio-common-utils';
import * as React from 'react';
import {
  ForbiddenIllustration,
  NoBrainNoPainIllustration,
  OhNoIllustration,
} from '../illustrations';
import { InfoPage } from './info-page';

export const ForbiddenPage: React.FC<{ errorMessage?: string }> = ({
  errorMessage,
}) => {
  React.useEffect(() => {
    if (errorMessage) {
      Sentry.captureMessage(
        `Rendered ForbiddenPage with message: "${errorMessage}"`,
      );
    } else {
      Sentry.captureMessage('Rendered ForbiddenPage');
    }
  }, [errorMessage]);

  return (
    <InfoPage
      illustration={<ForbiddenIllustration />}
      heading="Du har ikke tilgang!"
      body={
        <p>
          Nysgjerrig? Finn ut av hvem som administrerer Folio og skru på
          sjarmen.
        </p>
      }
    />
  );
};

export const GenericErrorPage: React.FC<{ errorMessage?: string }> = ({
  errorMessage,
}) => {
  React.useEffect(() => {
    if (errorMessage) {
      Sentry.captureMessage(
        `Rendered GenericErrorPage with message: "${errorMessage}"`,
      );
    } else {
      Sentry.captureMessage('Rendered GenericErrorPage');
    }
  }, [errorMessage]);

  return (
    <InfoPage
      illustration={<OhNoIllustration />}
      heading="Argh! Teknologien sviktet"
      body={
        <p>
          Vi prøver så godt vi kan å fikse det! Prøv igjen litt senere. Takk for
          tålmodigheten.
        </p>
      }
    />
  );
};

const NotFoundPage: React.FC = () => (
  <InfoPage
    illustration={<NoBrainNoPainIllustration />}
    heading="Oi, nå er vi helt lost her…"
    body={
      <p>
        Dobbeltsjekk at du skrev inn riktig adresse. Eller send en beskjed til{' '}
        <a href={folioEmailAddressUrl}>{folioEmailAddress}</a> og fortell hvor
        du ville, så skal vi hjelpe deg!
      </p>
    }
  />
);

export const NotFoundErrorPage: React.FC = () => {
  React.useEffect(() => {
    Sentry.captureMessage('Rendered NotFoundErrorPage');
  }, []);

  return <NotFoundPage />;
};

export const BehindFeatureTogglePage: React.FC = () => {
  React.useEffect(() => {
    Sentry.withScope(scope => {
      scope.setLevel('warning');
      Sentry.captureMessage('Rendered BehindFeatureTogglePage');
    });
  }, []);

  return <NotFoundPage />;
};
