import * as React from 'react';
import type { AgentCapability } from '../gqltypes';
import {
  useFirstPaintData,
  useFirstPaintNoSessionData,
} from './use-first-paint-data';

export function useHasCapability(capability: AgentCapability): boolean | null {
  const firstPaintData = useFirstPaintData();

  const capabilities = firstPaintData.data?.me.capabilities;

  return React.useMemo(
    () => capabilities?.includes(capability) ?? null,
    [capabilities, capability],
  );
}

export function useHasFeatureToggle(featureFlag: string): boolean | null {
  const firstPaintData = useFirstPaintNoSessionData();

  const enabledFeatureToggles = firstPaintData.data?.enabledFeatureToggles;

  return React.useMemo(
    () => enabledFeatureToggles?.includes(featureFlag) ?? null,
    [enabledFeatureToggles, featureFlag],
  );
}
