import styled from '@emotion/styled';
import { removeWhitespace } from 'folio-common-utils';
import * as React from 'react';
import { TextInput, type Props as TextInputProps } from '../TextInput';
import {
  type HtmlTextInputElement,
  type State,
  setCursorPosition,
} from '../formatting-inputs';

export interface Props extends TextInputProps {
  allowFractions?: boolean;
}

const RightAlignedTextInput = styled(TextInput)`
  text-align: right;
`;

// Use this instead of formatter.formatNumber() since we must handle
// initial zeros if e.g. the "1" in "1 000" is removed.
function format(num: string) {
  // http://stackoverflow.com/a/2901298
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export class NumberValueInput extends React.PureComponent<Props, State> {
  private inputRef: React.RefObject<HtmlTextInputElement> = React.createRef();

  constructor(props: Props) {
    super(props);
    this.state = {
      cursorPos: 0,
    };
  }

  setCursorPosition(pos: number) {
    const input = this.inputRef.current;
    if (!input) {
      return;
    }

    setCursorPosition(input, pos);
  }

  handleChange = () => {
    const input = this.inputRef.current;
    if (!input) {
      return;
    }

    const value = removeWhitespace(input.value);
    if (this.props.allowFractions) {
      if (
        // Abort if we get anything other than digits, dots or commas.
        // Also abort if there are more than one dot or comma.
        !/^[\d,.]*$/.test(value) ||
        value.replace(/[^,.]/g, '').length > 1
      ) {
        this.setState({
          cursorPos: input.selectionEnd - 1,
        });
        this.forceUpdate();
        return;
      }
    } else {
      // Abort if we get anything other than digits
      if (!/^\d*$/.test(value)) {
        this.setState({
          cursorPos: input.selectionEnd - 1,
        });
        this.forceUpdate();
        return;
      }
    }

    const formattedValue = format(value);
    this.props.onChange(value);

    // Compensate for any spaces inserted or deleted
    const compensation = formattedValue.length - input.value.length;

    // If the value is e.g. "1 234" with the cursor just before "2", pressing backspace
    // will result in `selectionStart + compensation` being -1. Make sure it's minimum 0.
    const cursorPos = Math.max(0, input.selectionStart + compensation);
    this.setState({ cursorPos });
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HtmlTextInputElement;
    const { value, selectionStart, selectionEnd } = target;

    // If the cursor is just after a space, and Backspace is pressed, or if the
    // cursor is just before a space and Delete is pressed, the cursor must be
    // manually positioned before or after the space to delete the right
    // character. Also, if exactly one space is selected, and Backspace or
    // Delete is pressed, the cursor is manually positioned before or after the
    // space.
    if (event.key === 'Backspace') {
      if (
        value[selectionStart - 1] === ' ' &&
        selectionStart === selectionEnd
      ) {
        // If the cursor is just after a space when pressing Backspace
        // "123 |456" -> "123| 456"
        this.setCursorPosition(selectionStart - 1);
      } else if (value.slice(selectionStart, selectionEnd) === ' ') {
        // If exactly one space is selected, reposition the cursor
        this.setCursorPosition(selectionStart);
        event.preventDefault();
      }
    } else if (event.key === 'Delete') {
      if (value[selectionStart] === ' ' && selectionStart === selectionEnd) {
        // If the cursor is just before a space when pressing Delete
        // "123| 456" -> "123 |456"
        this.setCursorPosition(selectionStart + 1);
      } else if (value.slice(selectionStart, selectionEnd) === ' ') {
        // If exactly one space is selected, reposition the cursor
        this.setCursorPosition(selectionStart + 1);
        event.preventDefault();
      }
    }
  };

  componentDidUpdate() {
    this.setCursorPosition(this.state.cursorPos);
  }

  render() {
    const value = format(this.props.value);
    const { allowFractions } = this.props;
    return (
      <RightAlignedTextInput
        maxLength={15} // Simple way of making sure we don't get numbers we can't handle
        {...this.props}
        value={value}
        inputMode={allowFractions ? 'decimal' : 'numeric'}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        inputRef={this.inputRef}
      />
    );
  }
}
