import { type PureQueryOptions, useApolloClient } from '@apollo/client';
import * as React from 'react';
import { FirstPaintDocument } from '../common-queries.generated';
import { GetPaymentsDocument } from '../routes/payment/queries.generated';
import { TransactionPageDataDocument } from '../routes/transactions/queries.generated';

/**
 * This hook should not exist (any more).
 * It has som DRY-ish code, meant to simplify refetching things after
 * mutating data.
 *
 * The way it does this is to look at the stringified versions of a few queries
 * that are known to need refetching and automatically rerunning them.
 *
 * It extracts the arguments by parsing the stringified queries and extracting
 * the arguments. For the payments queries, which do more than one underlying
 * query, this means assuming that the order of queries is stable, and treating
 * the queries as pairs of arguments.
 *
 * This is error prone and confusing and we should remove it in favor of
 * writing the refetch config manually where needed, or doing something
 * smarter and better documented than what is going on here.
 */

function extractPaymentByStatusQueryVariables(keys: readonly string[]) {
  const queryName = 'paymentsByStatus';
  return keys
    .filter(key => key.startsWith(`${queryName}(`))
    .map(key => key.slice(queryName.length + 1, -1))
    .map(key => JSON.parse(key));
}

function extractSalaryPaymentsQueryVariables(keys: readonly string[]) {
  const queryName = 'salaryPayments';
  return keys
    .filter(key => key.startsWith(`${queryName}(`))
    .map(key => key.slice(queryName.length + 1, -1))
    .map(key => JSON.parse(key));
}

function extractEventsQueryVariables(keys: readonly string[]) {
  const queryName = 'events';
  return keys
    .filter(key => key.startsWith(`${queryName}(`))
    .map(key => key.slice(queryName.length + 1, -1))
    .map(key => JSON.parse(key));
}

/**
 * Look at pairs of arguments passed to the `paymentsByStatus` and
 * `salaryPayments` queries. Recreate the arguments that were passed to the
 * `GetPayments` query, by looking at the arguments to a pair of payment/salary
 * queries.
 *
 * This assumes that the cache keeps those queries in the same order as they
 * were executed, which we can't be sure of. This passes the tests, but see
 * the comment at the top of the file about why we should retire this.
 */
function extractPaymentQueryVariables(keys: readonly string[]) {
  const payments = extractPaymentByStatusQueryVariables(keys);
  const salaryPayments = extractSalaryPaymentsQueryVariables(keys);
  if (payments.length === salaryPayments.length) {
    const ret = [];
    for (let index = 0; index < payments.length; index++) {
      const payment = payments[index];
      const salaryPayment = salaryPayments[index];
      if (payment && salaryPayment) {
        ret.push({
          poll: payment?.poll ?? false,
          statusList: payment?.statusList ?? [],
          bulkStatusList: salaryPayment?.statuses ?? [],
        });
      }
    }
    return ret;
  } else {
    return [];
  }
}

export function useTransactionAndBalanceRefetchConfig(): {
  refetchQueries: PureQueryOptions[];
} {
  const client = useApolloClient();
  const cacheContent = client.extract();
  // @ts-expect-error: `cacheContent` is not typed
  const rootQuery = cacheContent.ROOT_QUERY ?? {};
  const queryKeys = Object.keys(rootQuery);

  const paymentsVariables = extractPaymentQueryVariables(queryKeys);

  return React.useMemo(() => {
    const eventsVariables = extractEventsQueryVariables(queryKeys);

    return {
      refetchQueries: [
        ...eventsVariables.map(variables => ({
          query: TransactionPageDataDocument,
          variables,
          context: { noBatch: true },
        })),
        ...paymentsVariables.map(variables => ({
          query: GetPaymentsDocument,
          variables,
        })),
        { query: FirstPaintDocument },
      ],
    };
  }, [paymentsVariables, queryKeys]);
}
